import { Component, OnInit,EventEmitter,OnDestroy,Input,Output } from '@angular/core';
import { Location } from '@angular/common';
declare var $: any;
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { API_CONFIG } from '../../../../../../config/API_CONFIG';
import { _getURL } from '../../../../../../config/API_CONFIG';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EvenementService } from 'src/app/services/evenement/evenement.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { sortTable } from '../../search/sortTable';
import { FileUploader } from 'ng2-file-upload';
import { uploadFolderRootEvenement } from '../../../../../../config/API_CONFIG';
import 'tinymce';
import 'tinymce/themes/modern';

import 'tinymce/plugins/code';
import 'tinymce/plugins/lists';
declare var tinymce: any;
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css','../../medecins/medecins/medecins.component.css']
})
export class EventsComponent implements OnInit {

  showMyContainer = 1;

  test: Date = new Date();
  private listTitles: any[];
  location: Location;
  public menuItems: any[];
  patient: any;
  evenements: any[] = [];
  evenementsMedecins: any[] = [];
  notifications: any[] = [];
  notificationsPatient: any[] = [];
  public search: any = '';
  searchMedecins: any = '';
  form: any;
  evenement: any;
  admin: any;
  notification: any;
  RolesAdmin: any[] = [];
  interval: any;
  updatedNom: any;
  updatedPrenom: any;
  showMedecinsEvents: boolean = false;
  showPatientsEvents: boolean = true;
  idEvent: any;
  theme: any;
  description: any;
  date: any;
  lieu: any;
  lienSite: any;
  fraisInscription: any;
  errorDate = false;
  image: any;
  public uploader: FileUploader = new FileUploader({ url: _getURL(API_CONFIG.image) + '/adminEventPatient/' + this.idEvent });
  sizeLimitOne = 5000000; //5MB
  @Output() onEditorContentChange = new EventEmitter();
  errorDescription = false
  editor;
  p:any;
  dd:any;
  constructor(private notificationService: NotificationService, private evenementService: EvenementService, private http: HttpClient, private authService: AuthService, location: Location, private activatedRoute: ActivatedRoute,
    private router: Router, private formBuilder: FormBuilder) {

    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if (!this.admin.roles.gestionEvenements) {
        this.router.navigate(['/notFound']);
        return false;
      }
    },
      err => {
        return false;
      });

  }
  ngOnInit() {

    let el = document.getElementById('scrolltoverify');
    el.scrollIntoView({ behavior: "smooth" });
    this.uploader.onBeforeUploadItem = (item) => {
        item.withCredentials = false;
      }
    this.image = true;
    this.theme = true;
    this.date = true;
    var adminString = localStorage.getItem('admin');
    var adminId = JSON.parse(adminString).id;
    var adminNom = JSON.parse(adminString).nom;
    var adminPrenom = JSON.parse(adminString).prenom;
    this.updatedNom = adminNom.charAt(0).toUpperCase() + adminNom.slice(1);
    this.updatedPrenom = adminPrenom.charAt(0).toUpperCase() + adminPrenom.slice(1);
    this.getPatientEvenement()
    this.form = this.formBuilder.group({
      title: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(5000)]],
      theme: ['', Validators.required],
      description: ['', [Validators.required,Validators.minLength(3),Validators.maxLength(10000)]],
      date: ['', Validators.required],
      dateFin: ['', Validators.required],
      lieu: ['', [Validators.required,Validators.minLength(3), Validators.maxLength(5000)]],
      lienSite: [''],
      fraisInscription: ['']
    });
    tinymce.init({
      selector: '#myTextArea',
      skin_url: 'assets/skins/lightgray',
      height: 350,
      width: 1095,
      resize: false,
      theme: 'modern',
      plugins: ['code', 'lists'],

      mobile: {
        theme: 'mobile',
        plugins: ['autosave', 'lists'],
        toolbar: ['undo', 'bold', 'italic', 'styleselect', 'checklist']
      },
      content_css: [
        '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
        '//www.tinymce.com/css/codepen.min.css'],
      paste_data_images: true,
      setup: editor => {
        this.editor = editor;
        editor.on('keyup change', () => {
          const content = editor.getContent();
          this.onEditorContentChange.emit(content);
        });
      }
    });
  }
  ngOnDestroy() {
  tinymce.remove(this.editor);
  }
  getPatientEvenement() {
    var today = new Date().toISOString().slice(0, 10)
    this.evenementService.getAllEvenementsPatient().subscribe((evenements:any[]) => {
      this.evenements = evenements;
      for (var j = 0; j < this.evenements.length; j++) {
        this.evenements[j].image = this.evenements[j].image.replace("https://www.file.medivisto.com",_getURL('').replace("api","")+ 'data/files')
              console.log(this.evenements)
        if (this.evenements[j].date >= today) {
          this.arraymove(this.evenements, j, 0)
        }
      }
    },
      err => {
        console.log(err);
        return false;
      });

      this.evenementService.getAllEvenementsMedecin().subscribe((evenements:any[]) => {
        this.evenementsMedecins = evenements;
        for (var j = 0; j < this.evenementsMedecins.length; j++) {
          this.evenementsMedecins[j].image = this.evenementsMedecins[j].image.replace("https://www.file.medivisto.com",_getURL('').replace("api","")+ 'data/files')
          if (this.evenementsMedecins[j].date >= today) {
            this.arraymove(this.evenementsMedecins, j, 0)
          }
        }
      },
        err => {
          console.log(err);
          return false;
        });
  }

  removeEvenement(evenement) {
    if (window.confirm("Voulez vous vraiment supprimer cet événement?")) {
      const headers = new HttpHeaders({
        'Authorization': localStorage.getItem('id_token_adminMedivisto'),
        'Content-Type': 'application/json'
      });
      this.http.delete(_getURL(API_CONFIG.evenement) + '/' + evenement._id, {headers: headers}).subscribe((res: any) => {
        this.evenements.splice(this.evenements.indexOf(evenement), 1);
        Swal.fire({
          width: 500,
          background: '#e1e1e1',
          timer: 3000,
          html: '<div class="row">' +
            '<div class="col-3">' +
            '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
            '</div>' +
            '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Événement a été supprimé avec succès</h4></div>' +
            '</div>',
          showConfirmButton: false
        }
        );
      })
    }
  }
  updateEvenement(evenement) {
    this.errorDate = false;
    if(evenement.date >= evenement.dateFin ){
      this.errorDate = true;
      return false;
    }
    this.errorDate = false;
    const file = this.uploader.queue[0]
   if (file) {
      if (file._file.type != 'image/png' && file._file.type != 'image/jpg' && file._file.type != 'image/jpeg') {
        this.uploader.queue =  [];
        alert("Impossible de télécharger ce fichier.")
        return false;
      }
      if (file._file.size > this.sizeLimitOne) {
        this.uploader.queue =  [];
        alert("Ce fichier sélectionné est trop volumineux.La taille maximale est 5 Mo.")
        return false;
      }
    }
    this.evenementService.editEvenement(evenement).subscribe(evenement => {
      this.evenement = evenement;
      this.uploader.setOptions({ url: _getURL(API_CONFIG.image) + '/adminEventPatient/' + this.evenement._id });
      this.uploader.uploadAll();
    });
  }
  accepterEvenement(evenement) {
    var newEvenement = {
      _id: evenement._id,
      accepted: true,
      show: true
    }
    var notification = {
      texte: 'Votre évènement a été accepté.',
      lien: 'evenements',
      patientId: evenement.patientId
    }
    if (window.confirm("Voulez vous vraiment accepter cet événement?")) {
      this.evenementService.editEvenement(newEvenement).subscribe(evenement => {
        this.evenement = evenement;
        Swal.fire({
          width: 500,
          background: '#e1e1e1',
          timer: 3000,
          html: '<div class="row">' +
            '<div class="col-3">' +
            '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
            '</div>' +
            '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Événement a été accepté avec succès</h4></div>' +
            '</div>',
          showConfirmButton: false
        }
        );
      });
    }
    // this.notificationService.addNotification(notification).subscribe(notification => {
    //   this.notificationsPatient.push(notification);
    // });
  }
  refuserEvenement(evenement) {
    var newEvenement = {
      _id: evenement._id,
      show: true,
      refused: true
    }
    var notification = {
      texte: 'Votre évènement est malheuresement refusé.',
      lien: 'evenements',
      patientId: evenement.patientId
    }
    if (window.confirm("Voulez vous vraiment refuser cet événement?")) {
      this.evenementService.editEvenement(newEvenement).subscribe(evenement => {
        this.evenement = evenement;
        Swal.fire({
          width: 500,
          background: '#e1e1e1',
          timer: 3000,
          html: '<div class="row">' +
            '<div class="col-3">' +
            '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
            '</div>' +
            '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Événement a été refusé avec succès</h4></div>' +
            '</div>',
          showConfirmButton: false
        }
        );
      });
    }
    // this.notificationService.addNotification(notification).subscribe(notification => {
    //   this.notificationsPatient.push(notification);
    // });
  }

  sortTable(n) {
    sortTable(n)
  }
  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}
  modifPart1() {
    if (this.showMyContainer !== 1)
    {
      this.showMyContainer = 1;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart2(){
    if (this.showMyContainer !== 2)
    {
      this.showMyContainer = 2;
    }
    else {
      this.showMyContainer = 0;
    }  }
  modifPart3(){
    if (this.showMyContainer !== 3)
    {
      this.showMyContainer = 3;
    }
    else {
      this.showMyContainer = 0;
    }  }
  showPart() {
    console.log()
    return this.showMyContainer;
  }
  afficherEvent(){
    Swal.fire({
      width: 800,
      text: 'Modal with a custom image.',
      imageUrl: 'assets/young-handsome-physician-in-me_dj.webp',
      imageWidth: 400,
      imageHeight: 200,
      confirmButtonColor: '#E5B92C',
      imageAlt: 'Custom image',
      html: '<div ><span style="font-weight: bolder; color: #E5B92C;">Sujet  :  </span><br><span>L\'art d’être heureux</span><br><br>'
        + '<span style="font-weight: bolder; color: #E5B92C">Théme  :  </span><br><span>Santé et Bien être</span><br><br>'
        + '<span style="font-weight: bolder; color: #E5B92C">Description   :  </span><br><span>Le Assurances DVV Trophée 2018/2019 est la 7ème édition de cette épreuve. La compétition a eu lieu du 1er novembre 2018</span><br><br>'
        + '<span style="font-weight: bolder; color: #E5B92C">Lieu   :  </span><br><span>Tunis</span><br><br>'
        + '<span style="font-weight: bolder; color: #E5B92C">Date   :  </span><br><span>30/06/2020</span><br><br>'
        + '<span style="font-weight: bolder; color: #E5B92C">Lien d\'inscription :\n   :  </span><br><span>30/06/2020</span><br><br>'
        + '<span style="font-weight: bolder; color: #E5B92C">Frais d\'inscription :   :  </span><br><span>50£</span><br></div>'
    });
  }
}

