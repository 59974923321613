<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/fullcalendar/3.6.1/fullcalendar.min.css">


<br><br><br>
<div class="wrapper" id="scrolltoverify">

  <div class="main-panel">
    <div class="main-content">
      <ng-fullcalendar id="calendar" [options]="calendarOptions" style="color:black" (eventClick)="eventClick($event.detail.event)" (dayClick)="dayClick($event.detail)"></ng-fullcalendar>
      <div id="addEventModal" class="modal fade" data-backdrop="false">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" style="position: relative;left: 240px;" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span> <span class="sr-only">close</span></button>
              <h4 style="color:black;position: relative;left: -120px;">Ajouter un rendez vous <br>personnel</h4>
            </div>
            <form [formGroup]="form" (ngSubmit)="saveEventPerso()">

              <div class="form-group col-md-12" style="margin-top: 10px;">
                <label for="">Titre*</label>
                <input type="text" class="form-control" name="title" formControlName="title" id="title">
                <control-messages [control]="form.controls.title"></control-messages>
              </div>
              <div class="form-group col-md-12">
                <label for="">Heure*</label>
                <input class="form-control" type="time" formControlName="heure" name="heure" required="required" />
                <control-messages [control]="form.controls.heure"></control-messages>
              </div>

              <div class="form-group col-md-12">
                <label for="">Lieu*</label>
                <input class="form-control" name="lieu" formControlName="lieu" type="text" required="required" />
                <control-messages [control]="form.controls.lieu"></control-messages>
              </div>

              <div class="modal-footer">
                <button type="submit" [disabled]="!form.valid" class="btn btn-valider">Ajouter</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div id="detailsEventModal" class="modal fade" data-backdrop="false">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" style="position: relative;left: 60px;" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span> <span class="sr-only">close</span></button>
              <h4 style="color:black;position: relative;left: -210px;">Détails</h4>
            </div>
            <div id="modalBody" class="modal-body" style="height: 166px;">
              <div class="form-group col-md-12">
                <label for="">Titre</label>
                <input class="form-control" name="titleMod" id="titleMod" type="text" required="required" />
              </div>
              <div class="form-group col-md-12">
                <label for="">Date</label>
                <input class="form-control" name="start" id="start" type="datetime-local" required="required" />
              </div>
              <div class="form-group col-md-12">
                <label for="">Lieu</label>
                <input class="form-control" name="lieu" id="lieu" type="text" required="required" />
              </div>
            </div>
            <br />
            <br /><br><br><br>
            <div class="modal-footer">
              <button type="submit" class="btn btn-valider" id="updateButton"  (click)="modifierEvent()">Modifier</button>
              <button type="submit" class="btn btn-annuler" id="deleteButton" data-toggle="modal" data-target="#myModal6" >Suprrimer</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="myModal6" tabindex="-1" role="dialog" aria-labelledby="myModal6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-scan">
        <div class="modal-header d-flex justify-content-center">
          <h3 class="titre-modal-statut text-uppercase">
            SUPPRIMER RENDEZ VOUS
          </h3>
        </div>
        <div class="modal-body">
          <div class="row" style="margin-left: 6%;">
            <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop"></div>
            <div class="col-10">
              <h4 class="textContenu text-uppercase">VOULEZ-VOUS SUPPRIMER CE RENDEZ VOUS?</h4>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center buttonsPopup">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td class="tdButt1 ">
                      <button class="btn-valider" data-dismiss="modal" data-toggle="modal" (click)="deleteEvent()" ><img src="assets/delete2.png" class="iconBoutton">Supprimer </button>
                    </td>
                    <td class="tdButt2 ">
                      <button class="btn-annuler" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton">Annuler </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
