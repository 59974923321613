<!-- formulaire d'inscription d'un médecin  -->
<section id="services-section" id="scrolltoverify">
    <div class="content-section" id="services">
      <div class="container">
        <div class="row">
          <div class="heading-section col-md-12 text-center wow animated fadeInDown" style="padding: 1rem 1rem;border-bottom: 1px solid #dee2e6;border-top-left-radius: calc(0.3rem - 1px);border-top-right-radius: calc(0.3rem - 1px);">
            <h3 style="text-align: center;color: #E5B92C;text-transform: uppercase!important;font-size: 1.75rem;">Ajouter un médecin</h3>
            <h3 style="color:rgb(15, 70, 103);display: inline-block;font-size: 25px;"></h3>
          </div>
          <!-- /.heading-section -->
        </div>
        <!-- /.row -->
        <div id="sevices-item" class="row">
          <form [formGroup]="form" class="form-horizontal" role="form"  style="width: 100%;" *ngIf="!basedDoctor">
            <br>
            <fieldset>
              <!-- Form Name -->
              <div class="form-group row col-sm-6">
                <legend>Informations</legend>
                <p style="color:green" *ngIf="successRegister">Le médecin est ajouté avec succès</p>
              </div>
              <!-- Text input-->
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="nom">Nom* </label>
                    <input type="text" class="form-control input-sm" id="nom" formControlName="nom" name="nom" placeholder="Nom" >
                  </div>
                  <control-messages [control]="form.controls.nom"></control-messages>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="prenom">Prénom*</label>
                    <input type="text" formControlName="prenom" name="prenom" class="form-control input-sm" id="prenom" placeholder="Prénom">
                  </div>
                  <control-messages [control]="form.controls.prenom"></control-messages>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="tel">Email*</label>
                    <input type="text" formControlName="email" name="email" class="form-control input-sm" id="email" placeholder="Email">
                  </div>
                  <control-messages [control]="form.controls.email"></control-messages>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="numInscription">N° d'inscription au conseil de l'ordre*</label>
                    <input type="text" formControlName="numInscription" name="numInscription" class="form-control input-sm" id="numInscription" placeholder="N°d'inscription au conseil de l'ordre">
                  </div>
                  <control-messages [control]="form.controls.numInscription"></control-messages>
                  <p style="color:red;font-size: 14px;" *ngIf="errorNumeroInscription">Le numéro d'inscription est déjà utilisé</p>
                </div>
              </div>
  
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="telMobile">Contact 1*</label>
                    <international-phone-number formControlName="tel" name="tel" placeholder="Contact 1*" [maxlength]="13" [defaultCountry]="'tn'" [required]="true"></international-phone-number>
                  </div>
                  <control-messages [control]="form.controls.tel"></control-messages>
  
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="telFixe">Contact 2</label>
                    <international-phone-number formControlName="telFixe" name="telFixe" placeholder="Contact 2" [maxlength]="13" [defaultCountry]="'tn'" [required]="false"></international-phone-number>
                  </div>
                  <control-messages [control]="form.controls.telFixe"></control-messages>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="telPerso">Contact 3</label>
                    <international-phone-number formControlName="telPerso" name="telPerso" placeholder="Contact 3" [maxlength]="13" [defaultCountry]="'tn'" [required]="false"></international-phone-number>
                  </div>
                  <control-messages [control]="form.controls.telPerso"></control-messages>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="diplome">Diplôme*</label>
                    <input type="text" formControlName="diplome" name="diplome" class="form-control input-sm" id="diplome" placeholder="Diplôme">
                  </div>
                  <control-messages [control]="form.controls.diplome"></control-messages>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="specialite">Spécialité*</label>
                    <select class="form-control input-sm" style="border:none" id="specialite" formControlName="specialite" name="specialite">
                      <option>Acupuncture</option>
                      <option>Allergologue</option>
                      <option>Anatomo-Cyto-Pathologiste</option>
                      <option>Anesthésiste-Réanimateur</option>
                      <option>Angiologue</option>
                      <option>Audioprothésiste</option>
                      <option>Audioprothésiste</option>
                      <option>Biochimiste</option>
                      <option>Biochimiste Clinique</option>
                      <option>Biologiste Medicale</option>
                      <option>Biophysique</option>
                      <option>Cancérologue</option>
                      <option>Cardiologue</option>
                      <option>Chirurgien</option>
                      <option>Chirurgien Cancérologue</option>
                      <option>Chirurgien Cardio-Vasculaire</option>
                      <option>Chirurgien Cardio-Vasculaire Thoracique</option>
                      <option>Chirurgien Esthétique</option>
                      <option>Chirurgien Généraliste</option>
                      <option>Chirurgien Maxillo Facial Stomatologue</option>
                      <option>Chirurgien Orthopédiste</option>
                      <option>Chirurgien Orthopédiste Traumatologue</option>
                      <option>Chirurgien Pédiatrique</option>
                      <option>Chirurgien Plasticien</option>
                      <option>Chirurgien Thoracique</option>
                      <option>Chirurgien Urologue</option>
                      <option>Dentiste</option>
                      <option>Dermatologue</option>
                      <option>Diabétologue</option>
                      <option>Diététicien</option>
                      <option>Embryologiste</option>
                      <option>Endocrinologue</option>
                      <option>Endocrinologue Diabétologue</option>
                      <option>Gastro-entérologue</option>
                      <option>Gériatre</option>
                      <option>Généraliste</option>
                      <option>Gynécologue</option>
                      <option>Gynécologue Obstétricien</option>
                      <option>Hématologue</option>
                      <option>Hématologue Clinique</option>
                      <option>Hématopathologiste</option>
                      <option>Hépatologue</option>
                      <option>Hypnothérapeute</option>
                      <option>Imagerie Médicale</option>
                      <option>Immunologiste</option>
                      <option>Immunopathologiste</option>
                      <option>Interniste</option>
                      <option>Interniste Maladies Infectieuses</option>
                      <option>Interniste Réanimation Médicale</option>
                      <option>Kinésithérapeute</option>
                      <option>Maladies Infectieuses</option>
                      <option>Médecin Biologiste</option>
                      <option>Médecin du Travail</option>
                      <option>Médecin Esthétique</option>
                      <option>Médecin Légiste</option>
                      <option>Médecin Nucléaire</option>
                      <option>Médecin Physique</option>
                      <option>Médecin Physique Réadaptateur</option>
                      <option>Médecine Préventive</option>
                      <option>Microbiologiste</option>
                      <option>Néonatologiste</option>
                      <option>Néphrologue</option>
                      <option>Neurochirurgien</option>
                      <option>Neurologue</option>
                      <option>Nutritionniste</option>
                      <option>Oncologue-Radiothérapeute</option>
                      <option>Ophtalmologiste</option>
                      <option>Orthodontiste</option>
                      <option>Orthopédiste</option>
                      <option>Orthopédiste Traumatologue</option>
                      <option>Orthophoniste</option>
                      <option>Orthoptiste</option>
                      <option>Ostéopathe</option>
                      <option>Parasitologiste</option>
                      <option>Pédiatre</option>
                      <option>Pédodontiste</option>
                      <option>Pédopsychiatre</option>
                      <option>Pharmacien Biologiste</option>
                      <option>Phlébologue</option>
                      <option>Physiologiste</option>
                      <option>Physiothérapeute</option>
                      <option>Pneumologue</option>
                      <option>Podologue</option>
                      <option>Proctologue</option>
                      <option>Proctologue</option>
                      <option>Prothésiste dentaire</option>
                      <option>Psychanalyste</option>
                      <option>Psychiatre</option>
                      <option>Psychologue</option>
                      <option>Psychomotricien</option>
                      <option>Psychothérapeute</option>
                      <option>Radiologue</option>
                      <option>Radiothérapeute</option>
                      <option>Réanimateur Médical</option>
                      <option>Rhumatologue</option>
                      <option>Sexologue</option>
                      <option>Stomatologue</option>
                      <option>Urologue</option>
                    </select>
                  </div>
                  <control-messages [control]="form.controls.specialite"></control-messages>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="telMobile">Date de naissance</label>
                    <input placeholder="Date de naissance" [max]="getToday()" formControlName="dateAnniversaire" name="dateAnniversaire" type="date" id="date-picker-example" class="form-control input-sm">
                  </div>
                  <control-messages [control]="form.controls.dateAnniversaire"></control-messages>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="tel">Sexe</label>
                    <select class="form-control input-sm" id="sexe" style="border:none" formControlName="sexe" name="sexe">
                      <option>Masculin</option>
                      <option>Féminin</option>
                    </select>
                  </div>
                  <control-messages [control]="form.controls.sexe"></control-messages>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="telMobile">Date d'inscription</label>
                    <input placeholder="Date de inscription"  formControlName="dateInscription" name="dateInscription" type="date" id="date-picker-example" class="form-control input-sm">
                  </div>
                  <control-messages [control]="form.controls.dateInscription"></control-messages>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="tel">Notes</label> <br>
                    <textarea name="notes" style="width: 100%;min-height: 76px;" formControlName="notes" maxlength="1000"></textarea>
                  </div>
                  <control-messages [control]="form.controls.notes"></control-messages>
                </div>
              </div>
              <!-- Address Section -->
              <!-- Form Name -->
              <div formGroupName="adresseCabinet">
                <div class="form-group row col-sm-6">
                  <br>
                  <legend style="position: relative;top: 35px;">Adresse cabinet</legend>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6">
                    <div class="title textContenu">
                      <label for="pays">Pays*</label>
                      <select class="form-control input-sm" style="border:none" id="pays" [(ngModel)]="selectedpays" (ngModelChange)="onSelectpays(selectedpays)" formControlName="pays" name="pays">
                        <option value="" selected>Tunisie</option>
                        <option *ngFor="let py of pays">{{py.name}}</option>
                      </select>
                    </div>
                    <control-messages [control]="form.controls.adresseCabinet.controls.pays"></control-messages>
  
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <div class="title textContenu">
                      <label for="ville">Ville*</label>
                      <input type="text" formControlName="ville" name="ville" class="form-control input-sm" id="ville" placeholder="Ville" *ngIf="!isTunisie">
                      <select class="form-control input-sm" style="border:none" id="specialite" formControlName="ville" name="ville" [(ngModel)]="selectedville" (ngModelChange)="onSelectville(selectedville)" *ngIf="isTunisie">
                        <option></option>
                        <option *ngFor="let ville of listefiltreville">{{ville}}</option>
                      </select>
                    </div>
                    <control-messages [control]="form.controls.adresseCabinet.controls.ville"></control-messages>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6">
                    <div class="title textContenu">
                      <label for="codePostal">Code postal*</label>
                      <input type="text" formControlName="codePostal" name="codePostal" class="form-control input-sm" id="codePostal" placeholder="Code postal" *ngIf="!isTunisie">
                      <select class="form-control input-sm" style="border:none" formControlName="codePostal" name="codePostal" [(ngModel)]="selectedpostal" *ngIf="isTunisie">
                        <option></option>
                        <option *ngFor="let postal of listefiltrecode">{{postal}}</option>
                      </select>
                    </div>
                    <control-messages [control]="form.controls.adresseCabinet.controls.codePostal"></control-messages>
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <div class="title textContenu">
                      <label for="numero">Adresse*</label>
                      <input type="text" formControlName="adresse" name="adresse" class="form-control input-sm" id="adresse" placeholder="Adresse">
                    </div>
                    <control-messages [control]="form.controls.adresseCabinet.controls.adresse"></control-messages>
                  </div>
                </div>
              </div>
              <div class="form-group row col-sm-6">
                <br>
                <legend style="position: relative;top: 35px;">Autres informations</legend>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="codeCNAM">Code CNAM</label>
                    <input type="text" formControlName="codeCNAM" name="codeCNAM" class="form-control input-sm" id="codeCNAM" placeholder="Code CNAM">
                  </div>
                  <control-messages [control]="form.controls.codeCNAM"></control-messages>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="centreCNAM">Centre CNAM</label>
                    <input type="text" formControlName="centreCNAM" name="centreCNAM" class="form-control input-sm" id="centreCNAM" placeholder="Centre CNAM">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="dateDebutFonction">Date debut fonction</label>
                    <input type="date" formControlName="dateDebutFonction" name="dateDebutFonction" class="form-control input-sm" id="dateDebutFonction">
                  </div>
                </div>
  
              </div>
              <!-- <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label>Langues parlées</label>
                    <angular4-multiselect [data]="dropdownList" style="border: none !important;box-shadow: none;" [(ngModel)]="selectedItems" name="languesParlees" formControlName="languesParlees" [settings]="dropdownSettings"
                      (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)"></angular4-multiselect>
                  </div>
                </div>
              </div> -->
              <div class="row" style="position: relative;top: 35px;margin-left: 0px;color: red;margin-bottom: 20px;">
                {{showerrorligne}}
              </div>
              <div class="row buts" style="position: relative;left: 11%;top: 25px;">
                <div class="col-1 col-xl-2 col-lg-2 col-md-1 col-sm-1"></div>
                <div class="col-12 col-xl-6 col-lg-8 col-md-12 col-sm-12">
                  <div class="row ">
                    <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm 12">
                      <button class="btn-valider"  (click)="onRegisterMedecin()"  ><img src="assets/valider.png" class="iconBoutton">Ajouter </button>
                    </div>
                    <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm 12 ">
                      <button class="btn-annule" [routerLink]="['/medecins']"><img src="assets/annuler.png" class="iconBoutton">Annuler </button>
                    </div>
                  </div>
                  <br><br><br>
                </div>
                <div class="col-1 col-xl-2 col-lg-2 col-md-1 col-sm-1"></div>
              </div>
            </fieldset>
          </form>
          <form [formGroup]="form" class="form-horizontal" role="form"  style="width: 100%;" *ngIf="basedDoctor">
            <br>
            <fieldset>
              <!-- Form Name -->
              <div class="form-group row col-sm-6">
                <legend>Informations</legend>
                <p style="color:green" *ngIf="successRegister">Le médecin est ajouté avec succès</p>
              </div>
              <!-- Text input-->
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="nom">Nom* </label>
                    <input type="text" class="form-control input-sm" id="nom" formControlName="nom" name="nom" placeholder="Nom" [(ngModel)]="basedDoctor.nom">
                  </div>
                  <control-messages [control]="form.controls.nom"></control-messages>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="prenom">Prénom*</label>
                    <input type="text" formControlName="prenom" name="prenom" class="form-control input-sm" id="prenom" placeholder="Prénom" [(ngModel)]="basedDoctor.prenom">
                  </div>
                  <control-messages [control]="form.controls.prenom"></control-messages>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="tel">Email*</label>
                    <input type="text" formControlName="email" name="email" class="form-control input-sm" id="email" placeholder="Email" [(ngModel)]="basedDoctor.email">
                  </div>
                  <control-messages [control]="form.controls.email"></control-messages>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="numInscription">N° d'inscription au conseil de l'ordre*</label>
                    <input type="text" formControlName="numInscription" [(ngModel)]="basedDoctor.numInscription" name="numInscription" class="form-control input-sm" id="numInscription" placeholder="N°d'inscription au conseil de l'ordre">
                  </div>
                  <control-messages [control]="form.controls.numInscription"></control-messages>
                  <p style="color:red;font-size: 14px;" *ngIf="errorNumeroInscription">Le numéro d'inscription est déjà utilisé</p>
                </div>
              </div>
  
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="telMobile">Contact 1*</label>
                    <international-phone-number formControlName="tel" [(ngModel)]="basedDoctor.tel" name="tel" placeholder="Contact 1*" [maxlength]="13" [defaultCountry]="'tn'" [required]="true"></international-phone-number>
                  </div>
                  <control-messages [control]="form.controls.tel"></control-messages>
  
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="telFixe">Contact 2</label>
                    <international-phone-number formControlName="telFixe" [(ngModel)]="basedDoctor.telFixe" name="telFixe" placeholder="Contact 2" [maxlength]="13" [defaultCountry]="'tn'" [required]="false"></international-phone-number>
                  </div>
                  <control-messages [control]="form.controls.telFixe"></control-messages>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="telPerso">Contact 3</label>
                    <international-phone-number formControlName="telPerso" name="telPerso" [(ngModel)]="basedDoctor.telPerso" placeholder="Contact 3" [maxlength]="13" [defaultCountry]="'tn'" [required]="false"></international-phone-number>
                  </div>
                  <control-messages [control]="form.controls.telPerso"></control-messages>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="diplome">Diplôme*</label>
                    <input type="text" formControlName="diplome" name="diplome" [(ngModel)]="basedDoctor.diplome" class="form-control input-sm" id="diplome" placeholder="Diplôme">
                  </div>
                  <control-messages [control]="form.controls.diplome"></control-messages>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="specialite">Spécialité*</label>
                    <select class="form-control input-sm" style="border:none" id="specialite" [(ngModel)]="basedDoctor.specialite" formControlName="specialite" name="specialite">
                      <option>Acupuncture</option>
                      <option>Allergologue</option>
                      <option>Anatomo-Cyto-Pathologiste</option>
                      <option>Anesthésiste-Réanimateur</option>
                      <option>Angiologue</option>
                      <option>Audioprothésiste</option>
                      <option>Audioprothésiste</option>
                      <option>Biochimiste</option>
                      <option>Biochimiste Clinique</option>
                      <option>Biologiste Medicale</option>
                      <option>Biophysique</option>
                      <option>Cancérologue</option>
                      <option>Cardiologue</option>
                      <option>Chirurgien</option>
                      <option>Chirurgien Cancérologue</option>
                      <option>Chirurgien Cardio-Vasculaire</option>
                      <option>Chirurgien Cardio-Vasculaire Thoracique</option>
                      <option>Chirurgien Esthétique</option>
                      <option>Chirurgien Généraliste</option>
                      <option>Chirurgien Maxillo Facial Stomatologue</option>
                      <option>Chirurgien Orthopédiste</option>
                      <option>Chirurgien Orthopédiste Traumatologue</option>
                      <option>Chirurgien Pédiatrique</option>
                      <option>Chirurgien Plasticien</option>
                      <option>Chirurgien Thoracique</option>
                      <option>Chirurgien Urologue</option>
                      <option>Dentiste</option>
                      <option>Dermatologue</option>
                      <option>Diabétologue</option>
                      <option>Diététicien</option>
                      <option>Embryologiste</option>
                      <option>Endocrinologue</option>
                      <option>Endocrinologue Diabétologue</option>
                      <option>Gastro-entérologue</option>
                      <option>Gériatre</option>
                      <option>Généraliste</option>
                      <option>Gynécologue</option>
                      <option>Gynécologue Obstétricien</option>
                      <option>Hématologue</option>
                      <option>Hématologue Clinique</option>
                      <option>Hématopathologiste</option>
                      <option>Hépatologue</option>
                      <option>Hypnothérapeute</option>
                      <option>Imagerie Médicale</option>
                      <option>Immunologiste</option>
                      <option>Immunopathologiste</option>
                      <option>Interniste</option>
                      <option>Interniste Maladies Infectieuses</option>
                      <option>Interniste Réanimation Médicale</option>
                      <option>Kinésithérapeute</option>
                      <option>Maladies Infectieuses</option>
                      <option>Médecin Biologiste</option>
                      <option>Médecin du Travail</option>
                      <option>Médecin Esthétique</option>
                      <option>Médecin Légiste</option>
                      <option>Médecin Nucléaire</option>
                      <option>Médecin Physique</option>
                      <option>Médecin Physique Réadaptateur</option>
                      <option>Médecine Préventive</option>
                      <option>Microbiologiste</option>
                      <option>Néonatologiste</option>
                      <option>Néphrologue</option>
                      <option>Neurochirurgien</option>
                      <option>Neurologue</option>
                      <option>Nutritionniste</option>
                      <option>Oncologue-Radiothérapeute</option>
                      <option>Ophtalmologiste</option>
                      <option>Orthodontiste</option>
                      <option>Orthopédiste</option>
                      <option>Orthopédiste Traumatologue</option>
                      <option>Orthophoniste</option>
                      <option>Orthoptiste</option>
                      <option>Ostéopathe</option>
                      <option>Parasitologiste</option>
                      <option>Pédiatre</option>
                      <option>Pédodontiste</option>
                      <option>Pédopsychiatre</option>
                      <option>Pharmacien Biologiste</option>
                      <option>Phlébologue</option>
                      <option>Physiologiste</option>
                      <option>Physiothérapeute</option>
                      <option>Pneumologue</option>
                      <option>Podologue</option>
                      <option>Proctologue</option>
                      <option>Proctologue</option>
                      <option>Prothésiste dentaire</option>
                      <option>Psychanalyste</option>
                      <option>Psychiatre</option>
                      <option>Psychologue</option>
                      <option>Psychomotricien</option>
                      <option>Psychothérapeute</option>
                      <option>Radiologue</option>
                      <option>Radiothérapeute</option>
                      <option>Réanimateur Médical</option>
                      <option>Rhumatologue</option>
                      <option>Sexologue</option>
                      <option>Stomatologue</option>
                      <option>Urologue</option>
                    </select>
                  </div>
                  <control-messages [control]="form.controls.specialite"></control-messages>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="telMobile">Date de naissance</label>
                    <input placeholder="Date de naissance" [(ngModel)]="basedDoctor.dateAnniversaire" [max]="getToday()" formControlName="dateAnniversaire" name="dateAnniversaire" type="date" id="date-picker-example" class="form-control input-sm">
                  </div>
                  <control-messages [control]="form.controls.dateAnniversaire"></control-messages>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="tel">Sexe</label>
                    <select class="form-control input-sm" id="sexe" [(ngModel)]="basedDoctor.sexe" style="border:none" formControlName="sexe" name="sexe">
                      <option>Masculin</option>
                      <option>Féminin</option>
                    </select>
                  </div>
                  <control-messages [control]="form.controls.sexe"></control-messages>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="telMobile">Date d'inscription</label>
                    <input placeholder="Date de inscription" [(ngModel)]="basedDoctor.dateInscription" formControlName="dateInscription" name="dateInscription" type="date" id="date-picker-example" class="form-control input-sm">
                  </div>
                  <control-messages [control]="form.controls.dateInscription"></control-messages>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="tel">Notes</label> <br>
                    <textarea name="notes" style="width: 100%;min-height: 76px;" [(ngModel)]="basedDoctor.notes" formControlName="notes" maxlength="1000"></textarea>
                  </div>
                  <control-messages [control]="form.controls.notes"></control-messages>
                </div>
              </div>
              <!-- Address Section -->
              <!-- Form Name -->
              <div formGroupName="adresseCabinet">
                <div class="form-group row col-sm-6">
                  <br>
                  <legend style="position: relative;top: 35px;">Adresse cabinet</legend>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6">
                    <div class="title textContenu">
                      <label for="pays">Pays*</label>
                      <select class="form-control input-sm" style="border:none" id="pays" [(ngModel)]="selectedpays" (ngModelChange)="onSelectpays(selectedpays)" formControlName="pays" name="pays">
                        <option value="" selected>Tunisie</option>
                        <option *ngFor="let py of pays">{{py.name}}</option>
                      </select>
                    </div>
                    <control-messages [control]="form.controls.adresseCabinet.controls.pays"></control-messages>
  
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <div class="title textContenu">
                      <label for="ville">Ville*</label>
                      <input type="text" formControlName="ville" name="ville" class="form-control input-sm" id="ville" placeholder="Ville" *ngIf="!isTunisie">
                      <select class="form-control input-sm" style="border:none" id="specialite" formControlName="ville" name="ville" [(ngModel)]="basedDoctor.adresseCabinet.ville" (ngModelChange)="onSelectville(basedDoctor.adresseCabinet.ville)" *ngIf="isTunisie">
                        <option></option>
                        <option *ngFor="let ville of listefiltreville" [ngValue]="ville">{{ville}}</option>
                      </select>
                    </div>
                    <control-messages [control]="form.controls.adresseCabinet.controls.ville"></control-messages>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6">
                    <div class="title textContenu">
                      <label for="codePostal">Code postal*</label>
                      <input type="text" formControlName="codePostal" name="codePostal" class="form-control input-sm" id="codePostal" placeholder="Code postal" *ngIf="!isTunisie">
                      <select class="form-control input-sm" style="border:none" formControlName="codePostal" name="codePostal" [(ngModel)]="basedDoctor.adresseCabinet.codePostal" *ngIf="isTunisie">
                        <option></option>
                        <option *ngFor="let postal of listefiltrecode" [ngValue]="postal">{{postal}}</option>
                      </select>
                    </div>
                    <control-messages [control]="form.controls.adresseCabinet.controls.codePostal"></control-messages>
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <div class="title textContenu">
                      <label for="numero">Adresse*</label>
                      <input type="text" formControlName="adresse" [(ngModel)]="basedDoctor.adresseCabinet.adresse" name="adresse" class="form-control input-sm" id="adresse" placeholder="Adresse">
                    </div>
                    <control-messages [control]="form.controls.adresseCabinet.controls.adresse"></control-messages>
                  </div>
                </div>
              </div>
              <div class="form-group row col-sm-6">
                <br>
                <legend style="position: relative;top: 35px;">Autres informations</legend>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="codeCNAM">Code CNAM</label>
                    <input type="text" formControlName="codeCNAM" name="codeCNAM" [(ngModel)]="basedDoctor.codeCNAM" class="form-control input-sm" id="codeCNAM" placeholder="Code CNAM">
                  </div>
                  <control-messages [control]="form.controls.codeCNAM"></control-messages>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="centreCNAM">Centre CNAM</label>
                    <input type="text" formControlName="centreCNAM" name="centreCNAM" [(ngModel)]="basedDoctor.centreCNAM" class="form-control input-sm" id="centreCNAM" placeholder="Centre CNAM">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="dateDebutFonction">Date debut fonction</label>
                    <input type="date" formControlName="dateDebutFonction" name="dateDebutFonction" [(ngModel)]="basedDoctor.dateDebutFonction" class="form-control input-sm" id="dateDebutFonction">
                  </div>
                </div>
  
              </div>
              <!-- <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label>Langues parlées</label>
                    <angular4-multiselect [data]="dropdownList" style="border: none !important;box-shadow: none;" [(ngModel)]="selectedItems" name="languesParlees" formControlName="languesParlees" [settings]="dropdownSettings"
                      (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)"></angular4-multiselect>
                  </div>
                </div>
              </div> -->
              <div class="row" style="position: relative;top: 35px;margin-left: 0px;color: red;margin-bottom: 20px;">
                {{showerrorligne}}
              </div>
              <div class="row buts" style="position: relative;left: 11%;top: 25px;">
                <div class="col-1 col-xl-2 col-lg-2 col-md-1 col-sm-1"></div>
                <div class="col-12 col-xl-6 col-lg-8 col-md-12 col-sm-12">
                  <div class="row ">
                    <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm 12">
                      <button class="btn-valider"  (click)="onRegisterMedecin()"  ><img src="assets/valider.png" class="iconBoutton">Ajouter </button>
                    </div>
                    <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm 12 ">
                      <button class="btn-annule" [routerLink]="['/medecins']"><img src="assets/annuler.png" class="iconBoutton">Annuler </button>
                    </div>
                  </div>
                  <br><br><br>
                </div>
                <div class="col-1 col-xl-2 col-lg-2 col-md-1 col-sm-1"></div>
              </div>
            </fieldset>
          </form>
        </div>
        <!-- /.row -->
  
  
      </div>
      <!-- /.container -->
    </div>
  
  
    <!-- /#services -->
  </section>
  
  
  
  <!-- end formulaire d'inscription d'un médecin  -->
  