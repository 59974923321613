<!------------------ Liste des secretaires inscrits ----------------------->
<div id="scrolltoverify">

</div>
<div class="informationPat3">
  <div class="row bar">
    <div class="col-10 col-xl-12 col-lg-12 col-md-10 col-sm-10 bar-section"><a class="linkPart2"><b>Liste des secrétaires <b *ngIf="medecin">de DR. {{medecin.nom}} {{medecin.prenom}}</b> </b></a></div>
  </div>
  <div class=" search4">
    <div class="row cover-search-row2">
      <div class="col-lg-5 col-md-8 col-sm-10 col-xs-10" style="margin-right: 4%">
        <div class="row rowSearch2">
          <!-- <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 name-col-10">
            <input type="text" [(ngModel)]="search" name="search" (keyup)="valuechange($event)" class="inputRecherche" placeholder="Rechercher">
          </div> -->
          <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 name-col-1" style="">
            <img id="name_img" src="" alt="" style="height: 25px;   margin-top: 19px; margin-left: -8px">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row rightButts">
    <div class="col-11 ">
      <div class=" buttoncadre0">
      </div>
    </div>
    <div class="col-1">
      <div class=" buttoncadre3"><a title="Ajouter des secrétaires d'un médecin" [routerLink]="['/ajoutSecretaire',idMedecin]">
          <img class="iconButt2 " src="assets/plus-4.png"></a>
      </div>
    </div>
  </div>
  <div class="div-tab2">
    <div class="row" style="overflow-x:auto;">
      <table class="table  ">
        <thead>
          <th id="firstThTable" class=" gauche text-uppercase"><b>nom </b></th>
          <th class=" gauche text-uppercase"><b>prénom</b></th>
          <th class=" gauche text-uppercase"><b>email</b></th>
          <th class="  gauche text-uppercase" style="width: 20%;margin-right: 26px"><b>PARAMÈTRES</b></th>
        </thead>
        <tbody>
          <tr class=" gauche" *ngFor="let secretaire of secretaires | paginate: { itemsPerPage: 25, currentPage: p }">
            <td class=" gauche" style="text-transform: capitalize;">{{secretaire.nom}} </td>
            <td class=" gauche" style="text-transform: capitalize;">{{secretaire.prenom}}</td>
            <td class=" gauche">{{secretaire.email}} </td>
            <td class="row gauche" style="border-bottom-style: none;">
              <div class="col-1" style="margin-right: 20px !important;">
                <a [routerLink]="['/secretaire/modifier',secretaire._id]" title="Modifier">
                  <img src="assets/pencil-7.png" class="iconParametres"></a>
              </div>
              <div class="col-1">
                <a (click)="setVaribaleTodatele(secretaire)" title="Supprimer" data-toggle="modal" data-target="#myModal5"><img src="assets/delete-7.png" class="iconParametres"></a>
              </div>
            </td>
            <!---------------------------- Pop up supprimer secretaire ---------->
            <div class="modal fade" id="myModal5" tabindex="-1" role="dialog" aria-labelledby="myModal6" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content modal-content-scan">
                  <div class="modal-header d-flex justify-content-center">
                    <h3 class="titre-modal-statut text-uppercase">
                      SUPPRIMER secrétaire
                    </h3>
                  </div>
                  <div class="modal-body">
                    <div class="row" style="margin-left: 6%;">
                      <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop"></div>
                      <div class="col-10">
                        <h4 class="textContenu text-uppercase">VOULEZ-VOUS SUPPRIMER CE secrétaire?</h4>
                      </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-center">
                      <div class=" col d-flex justify-content-center buttonsPopup">
                        <div class="col-auto nbr-dossier ">
                          <table>
                            <tr>
                              <td class="tdButt1 ">
                                <button class="btn-valider" data-dismiss="modal" data-toggle="modal" data-target="#deleted" (click)="removeSecretaire(secretaire)"><img src="assets/delete2.png" class="iconBoutton">Supprimer </button>
                              </td>
                              <td class="tdButt2 ">
                                <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton">Annuler </button>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </tr>
        </tbody>
      </table>
      <div class="row" *ngIf="secretaires && secretaires.length > 25">
        <div class="col col-xs-6">
          <pagination-controls (pageChange)="p = $event" #api></pagination-controls>
        </div>
      </div>
      <br><br>    </div>
  </div>

</div>
