import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
 name: 'searchSujetEnAttente'
})
export class SearchSujetEnAttentePipe implements PipeTransform {
  transform(value, args: string[]){
   if (!value) return value;
   if (value.filter((item) => item.sujet.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.sujet.toLowerCase().includes(args));}
     if (value.filter((item) => item.categorie.toLowerCase().includes(args)) != '') {
       return value.filter((item) => item.categorie.toLowerCase().includes(args));}
     if (value.filter((item) => item.date.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.date.toLowerCase().includes(args));}
 }}
