import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
 name: 'searchEvenement'
})
export class SearchEvenementPipe implements PipeTransform {
  transform(value, args: string[]){
   if (!value) return value;
   if (value.filter((item) => item.title.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.title.toLowerCase().includes(args));}
     if (value.filter((item) => item.theme.toLowerCase().includes(args)) != '') {
       return value.filter((item) => item.theme.toLowerCase().includes(args));}
     if (value.filter((item) => item.lieu.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.lieu.toLowerCase().includes(args));}
     if (value.filter((item) => item.date.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.date.toLowerCase().includes(args));}
     if (value.filter((item) => item.fraisInscription.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.fraisInscription.toLowerCase().includes(args));}


 }}
