<div id="scrolltoverify">

</div>
<!------------------ Liste des patients du med ----------------------->
<div class="informationPat3" >
  <div class="row bar">
    <div class="col-10 col-xl-6 col-lg-6 col-md-10 col-sm-10 bar-section"><a class="linkPart2"><b >Liste des patients du médecin <span style="text-transform: capitalize;" *ngIf="medecin">{{medecin.prenom}} {{medecin.nom}}</span></b></a></div>
  </div>
  <div class=" search4">
    <div class="row cover-search-row2">
      <div class="col-lg-5 col-md-8 col-sm-10 col-xs-10" style="margin-right: 4%">
        <div class="row rowSearch2" >
          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 name-col-10">
            <input type="text" [(ngModel)]="search" name="search" (keyup)="valuechange($event)" class="inputRecherche"
                   placeholder="Rechercher">
          </div>
          <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 name-col-1" style="">
            <img id="name_img" src="" alt="" style="height: 25px;   margin-top: 19px; margin-left: -8px">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="div-tab2">
    <div class="row" style="overflow-x:auto;" >
      <table class="table  "  >
        <thead >
        <th id="firstThTable" class=" gauche text-uppercase" ><b>ID MEDIV </b></th>
        <th  class=" gauche text-uppercase" ><b> Numéro de fiche</b></th>
        <th  class=" gauche text-uppercase" ><b>nom</b></th>
        <th  class=" gauche text-uppercase" ><b>prénom</b></th>
        <th class="  gauche text-uppercase" style="width: 20%;margin-right: 26px" ><b>PARAMÈTRES</b></th>
        </thead>
        <tbody>
        <tr class=" gauche" *ngFor="let patient of patients | paginate: { itemsPerPage: 10, currentPage: p }">
          <td  class=" gauche" >{{patient.identifiantMedivisto}} </td>
          <td  class=" gauche" >{{patient.numFiche}} </td>
          <td  class=" gauche"  style="text-transform: capitalize;">{{patient.nom}} </td>
          <td  class=" gauche" style="text-transform: capitalize;">{{patient.prenom}}</td>
          <td class="row gauche" style="border-bottom-style: none;">
            <div class="col-1" style="margin-left: 30px !important;">
              <a (click)="setVariableToDelete(patient)" title="Supprimer" data-toggle="modal" data-target="#myModal5"><img src="assets/delete-7.png" class="iconParametres" ></a>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="pagination2" *ngIf="patients && patients.length > 25">
    <div class="row">
    <pagination-controls (pageChange)="p = $event" #api></pagination-controls>
    </div>
  </div>
  <br><br><br><br><br><br>
  <!-- end  liste des utilisateurs -->

  <div class="search-results" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"></div>
</div>
<!---------------------------- Pop up supprimer patient ---------->
<div class="modal fade" id="myModal5" tabindex="-1" role="dialog"
     aria-labelledby="myModal6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan" >
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut text-uppercase">
          SUPPRIMER patient
        </h3>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-left: 6%;">
          <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop" ></div>
          <div class="col-10"><h4 class="textContenu text-uppercase" >VOULEZ-VOUS SUPPRIMER CE patient?</h4></div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <div class=" col d-flex justify-content-center buttonsPopup" >
            <div class="col-auto nbr-dossier " >
              <table>
                <tr>
                  <td class="tdButt1 ">
                    <button class="btn-valider" (click)="removePatient()" data-dismiss="modal" data-toggle="modal" data-target="#deleted" ><img src="assets/delete2.png" class="iconBoutton" >Supprimer </button>
                  </td>
                  <td  class="tdButt2 " >
                    <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton" >Annuler  </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>      </div>
      </div>
    </div>  </div>
</div>
