import { Component, OnInit } from '@angular/core';
import {AuthService} from 'src/app/services/auth/auth.service';
import {Router} from '@angular/router';
@Component({
  selector: 'logout',
  template: '',
  providers:[AuthService]
})
export class LogoutComponent implements OnInit {

  constructor(private _authService: AuthService, private router: Router) {}

  ngOnInit() {
    this._authService.logout();
    this.router.navigate(['/login']);
  }
}
