import { Component, OnInit,OnDestroy,ChangeDetectorRef } from '@angular/core';
import {AuthService} from 'src/app/services/auth/auth.service';
import {Router,ActivatedRoute} from '@angular/router';
import {ValidateService} from 'src/app/services/validate/validate.service';
import {AdminService} from 'src/app/services/admin/admin.service';
@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['../login/login.component.css']
})
export class UpdatePasswordComponent implements OnInit {

  email:any;
  password:any;
  admin:any;
  confirmPassword:any;
  isValid = true;
  errorMsg:any
  sucessMessage:any;
  token:any;
    constructor(
      private router:Router,
      private validateService:ValidateService,
      private adminService:AdminService,
      private activatedRoute:ActivatedRoute,
    private authService:AuthService      ) {  }
    ngOnInit() {
    }
    updatePassword(){
      let params: any = this.activatedRoute.snapshot.params;
     this.token = params['token'];
      if(!this.validateService.validatePassword(this.password) || !this.validateService.validatePassword(this.confirmPassword) ){
        this.isValid = false
        this.errorMsg = 'Ajoutez votre mot de passe';
        setTimeout(() => {
              this.isValid = true
            },
              5000);
        return false;
      }
  
      if(!this.validateService.validateLengthPassword(this.password) || !this.validateService.validateLengthPassword(this.confirmPassword)){
        this.isValid = false
        this.errorMsg =  'Vérifiez votre mot de passe(minimum 6 caractéres)';
        setTimeout(() => {
              this.isValid = true
            },
              5000);
        return false;
      }
      if(this.password != this.confirmPassword)
      {
        this.isValid = false
        this.errorMsg =  'les deux mot de passes ne sont pas identiques';
       setTimeout(() => {
              this.isValid = true
            },
              5000);
       return false;
      }
      else{
        var admin = {
          password : this.password
        }
        this.adminService.verifytoken(admin,this.token).subscribe((data:any) => {
          if(data.success){
              this.sucessMessage = "votre mot de passe est  changé."
          } else {
            this.isValid = false
            this.errorMsg =   "délai de recupération de mot de passe est expiré.";
                  setTimeout(() => {
              this.isValid = true
            },
              5000);
          }
        });
    this.password ='';
    this.confirmPassword = '';
      }
    }
  
  
  }
  