<!-- formulaire d'inscription d'un médecin  -->
<section id="services-section" id="scrolltoverify">
    <div class="content-section" id="services">
        <div class="container">
            <div class="row">
                <div class="heading-section col-md-12 text-center wow animated fadeInDown">
                    <h3
                        style="text-align: center;color: #E5B92C;text-transform: uppercase!important;font-size: 1.75rem;">
                        Ajouter un nouvel
                        article</h3>
                    <h3 style="color:rgb(15, 70, 103);display: inline-block;font-size: 25px;"></h3>
                </div>
                <!-- /.heading-section -->
            </div>
            <ng-template #customLoadingTemplate></ng-template>
            <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
            </ngx-loading>
            <!-- /.row -->
            <div id="sevices-item" class="row">
                <form [formGroup]="form" class="form-horizontal" (keydown.enter)="onEnterPressed($event)" role="form" (ngSubmit)="addBlog()">
                    <fieldset>
                        <!-- Form Name -->
                        <div class="form-group col-md-12 col-sm-6">
                            <legend>Informations</legend>
                            <p style="color:green" *ngIf="successRegister">L'article a été ajouté avec succès</p>
                        </div>
                        <!-- Text input-->
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <div class="title textContenu">
                                    <label for="nom">Titre* </label>
                                    <input type="text" class="form-control input-sm" id="nom" formControlName="titre"
                                        name="titre" placeholder="Titre">
                                </div>
                                <control-messages [control]="form.controls.titre"></control-messages>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="title textContenu">
                                    <label for="specialite">Catégorie*</label>
                                    <select class="form-control input-sm" style="border:none" id="specialite"
                                        formControlName="categorie" name="categorie">
                                        <option *ngFor="let categ of categorieList" [value]="categ">{{categ}}</option>
                                    </select>
                                </div>
                                <control-messages [control]="form.controls.categorie"></control-messages>
                            </div>
                        </div>
                        <br><br>
                        <div class="form-group col-md-12">
                            <label for="">Description*</label>
                            <textarea id="myTextArea" class="mceEditor" rows="3"
                                style="margin: 0px; height: 163px;width: 1096px;" formControlName="description"
                                name="desciprion" placeholder="Description"></textarea>
                            <control-messages [control]="form.controls.description"></control-messages>
                            <p *ngIf="errorDescription" style="color:red">Vérifier la description (10 caratères minimum
                                et 2000 caractères maximum) !</p>
                        </div>

                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <div class="title textContenu">
                                    <label for="nom">Slogan* </label>
                                    <input type="text" class="form-control input-sm" id="nom" formControlName="slogan"
                                        name="slogan" placeholder="Slogan">
                                </div>
                                <control-messages [control]="form.controls.slogan"></control-messages>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="title textContenu">
                                    <label for="nom">Date de publication* </label>
                                    <input type="date" class="form-control input-sm" id="nom"
                                        formControlName="date" name="date" placeholder="date">
                                </div>
                                <control-messages [control]="form.controls.date"></control-messages>
                                <p style="color:red;font-size: 15px;" *ngIf="errorDate"> Merci de vérifier les dates !
                                </p>
                            </div>
                        </div>
                        <div class="row">


                        </div>
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <div class="title textContenu">
                                    <label for="nom">Mot Clés* </label>
                                    <!-- <input type="text" class="form-control input-sm" id="nom" formControlName="motCles"
                                        name="motCles" placeholder="Mot Clés"> -->
                                        
                                    <input type="text" class="form-control input-sm"
                                     placeholder="Ajouter les mots clés "
                                     formControlName="motCles" name="motCles" (keyup.enter)="addTag()"
                                    [ngClass]="{ 'is-invalid':form.get('motCles')?.touched && tags.length===0 || tags.length===0 && submitted}" >
                                    
                                </div>
                                <p *ngIf="tags.length===0 && form.get('motCles')?.touched || tags.length===0 && submitted" class="alertText" [ngStyle]="{'color': style1, 'font-weight': style2, 'font-size': style3}">Les mots clès sont
                                    obligatoires! </p>
                                   <p *ngIf="errormessageMotCles" class="alertText" [ngStyle]="{'color': style1, 'font-weight': style2, 'font-size': style3}">Ce mot clès est déja existant ! </p>
                        
                                <control-messages [control]="form.controls.motCles"></control-messages>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="title textContenu">
                                    <label for="nom">Affiché sur le site* </label>
                                    <select class="form-control input-sm" style="border:none" id="visibilite"
                                        formControlName="visibilite" name="visibilite">
                                        <option value="true">Oui</option>
                                        <option value="false">Non</option>
                                    
                                    </select>
                                </div>
                                <control-messages [control]="form.controls.visibilite"></control-messages>
                            </div>

                        </div>
                        <div class="row" style="margin-bottom: -24px;margin-left: 0px;">
                                <div class="tag" *ngFor="let tag of tags">{{ tag }}
                                    <button class="remove-button" (click)="removeTag(tag)">X</button>
                            </div>
                        </div>
                        <!--images-->
                        <div class="form-group mt-3 row ">
                            <div class="col-lg-6 col-sm-12">
                                <div class="title textContenu" style="border-bottom: none !important;">
                                <label for="exampleInputEmail1">Image de couverture*</label>
                            </div>

                                <label class="uploader mt-3" ondragover="return false;" [class.loaded]="loaded"
                                    [style.outlineColor]="dragging ? activeColor : baseColor"
                                    (dragenter)="handleDragEnter()" (dragleave)="handleDragLeave()"
                                    (drop)="handleDropCouv($event)" (dragover)="handleDragOverCouv($event)">

                                    <i class="icon icon-upload glyphicon glyphicon-upload" [style.color]="dragging 
                ? ((imageSrcCouverture.length > 0) ? overlayColor : activeColor)
                : ((imageSrcCouverture.length > 0) ? overlayColor : baseColor)"></i>

                                    <img [src]="imageSrcCouverture" (load)="handleImageLoad()"
                                        [class.loaded]="imageLoaded" />

                                    <div class="d-flex flex-column" *ngIf="imageSrcCouverture===''">
                                        <span class="upload-message">Déposer un fichier ici ou cliquer pour
                                            téléverser</span>
                                        <span class="upload-message-muted">Type de l'image: png-gif-jpg-jpeg</span>
                                        <span class="upload-message-muted">Taille de téléchargement maximum : 2MB</span>
                                    </div>

                                    <input type="file" accept="image/*" (change)="handleInputChangeCouv($event)"
                                        name="file" formControlName="fileCover">
                                </label>

                                <div class="alertText mt-2" *ngIf="form.get('fileCover')?.errors?.pattern">
                                    <p [ngStyle]="{'color': style1, 'font-weight': style2, 'font-size': style3}">Le
                                        format de l'image doit etre png/gif/jpg/jpeg!
                                    </p>
                                </div>
                                <div class="alertText mt-2" *ngIf="showRequiredErrorCouv">
                                    <p [ngStyle]="{'color': style1, 'font-weight': style2, 'font-size': style3}">L'image
                                        de la couvertures est obligatoire !
                                    </p>
                                </div>
                                <div class="alertText mt-2" *ngIf="showSizeErrorCouv">
                                    <p [ngStyle]="{'color': style1, 'font-weight': style2, 'font-size': style3}">La
                                        taille de l'image dépasse la limite autorisée.
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-12">
                                <div class="title textContenu" style="border-bottom: none !important;">
                                <label for="exampleInputEmail1">Image de la description*</label>
                                </div>

                                <label class="uploader mt-3" ondragover="return false;" [class.loaded]="loaded"
                                    [style.outlineColor]="dragging ? activeColor : baseColor"
                                    (dragenter)="handleDragEnter()" (dragleave)="handleDragLeave()"
                                    (drop)="handleDropDesc($event)" (dragover)="handleDragOverDesc($event)">

                                    <i class="icon icon-upload glyphicon glyphicon-upload" [style.color]="dragging 
                                         ? ((imageSrcDescription.length > 0) ? overlayColor : activeColor)
                                         : ((imageSrcDescription.length > 0) ? overlayColor : baseColor)"></i>

                                    <img [src]="imageSrcDescription" (load)="handleImageLoad()"
                                        [class.loaded]="imageLoaded" />
                                    <div class="d-flex flex-column" *ngIf="imageSrcDescription===''">
                                        <span class="upload-message">Déposer un fichier ici ou cliquer pour
                                            téléverser</span>
                                        <span class="upload-message-muted">Type de l'image: png-gif-jpg-jpeg</span>
                                        <span class="upload-message-muted">Taille de téléchargement maximum : 2MB</span>
                                    </div>
                                    <input type="file" accept="image/*" (change)="handleInputChangeDesc($event)"
                                        name="file" formControlName="filedescription">

                                </label>

                                <div class="alertText mt-2" *ngIf="form.get('filedescription')?.errors?.pattern">
                                    <p [ngStyle]="{'color': style1, 'font-weight': style2, 'font-size': style3}">Le
                                        format de l'image doit etre png/gif/jpg/jpeg!
                                    </p>
                                </div>
                                <div class="alertText mt-2" *ngIf="showRequiredErrorDesc">
                                    <p [ngStyle]="{'color': style1, 'font-weight': style2, 'font-size': style3}">L'image
                                        de la description est obligatoire !
                                    </p>
                                </div>
                                <div class="alertText mt-2" *ngIf="showSizeErrorDesc">
                                    <p [ngStyle]="{'color': style1, 'font-weight': style2, 'font-size': style3}">La
                                        taille de l'image dépasse la limite autorisée.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row buts" style="position: relative;left: 11%;top: 25px;">
                            <div class="col-1 col-xl-2 col-lg-2 col-md-1 col-sm-1"></div>
                            <div class="col-12 col-xl-6 col-lg-8 col-md-12 col-sm-12">
                                <div class="row ">
                                    <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm 12">
                                        <button class="btn-valider" [disabled]="!form.valid" type="submit"><img
                                                src="assets/valider.png" class="iconBoutton">Ajouter </button>
                                    </div>
                                    <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm 12 ">
                                        <button class="btn-annule" [routerLink]="['/articles']"><img
                                                src="assets/annuler.png" class="iconBoutton">Annuler </button>
                                    </div>
                                </div>
                                <br><br><br>
                            </div>
                            <div class="col-1 col-xl-2 col-lg-2 col-md-1 col-sm-1"></div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <!-- /.row -->


        </div>
        <!-- /.container -->
    </div>


    <!-- /#services -->
</section>



<!-- end formulaire d'inscription d'un médecin  -->