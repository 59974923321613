<!------------------ Liste des admins     ----------------------->
<div class="informationPat3">
    <div class="row bar">
      <div class="col-10 col-xl-4 col-lg-4 col-md-10 col-sm-10 bar-section"><a class="linkPart2"><b>Liste des admins </b></a></div>
    </div>
    <div class=" search4">
      <div class="row cover-search-row2">
        <div class="col-lg-5 col-md-8 col-sm-10 col-xs-10" style="margin-right: 4%">
          <div class="row rowSearch2">
            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 name-col-10">
              <input type="text" [(ngModel)]="search" name="search" class="inputRecherche" placeholder="Rechercher">
            </div>
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 name-col-1" style="">
              <img id="name_img" src="" alt="" style="height: 25px;   margin-top: 19px; margin-left: -8px">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row rightButts">
      <div class="col-11 ">
        <div class=" buttoncadre0">
        </div>
      </div>
      <div class="col-1">
        <div class=" buttoncadre3"><a href="#" title="Nouvel Admin" data-toggle="modal" data-target="#myModal4">
            <img class="iconButt2 " src="assets/plus-4.png"></a>
        </div>
      </div>
    </div>
    <div class="div-tab2">
      <div class="row" style="overflow-x:auto;">
        <table class="table  ">
          <thead>
            <th id="firstThTable" class=" gauche text-uppercase"><b>nom </b></th>
            <th class=" gauche text-uppercase"><b>prénom</b></th>
            <th class=" gauche text-uppercase"><b>email</b></th>
            <th class="  gauche text-uppercase" style="width: 20%;margin-right: 26px"><b>PARAMÈTRES</b></th>
          </thead>
          <tbody>
            <tr class=" gauche" *ngFor="let admin of admins| paginate: { itemsPerPage: 25, currentPage: p }|searchAdmin : search.toString().toLowerCase()">
              <td [routerLink]="['/gestionRolesAdmin',admin._id]" style="text-transform: capitalize;" class=" gauche">{{admin.nom}} </td>
              <td [routerLink]="['/gestionRolesAdmin',admin._id]" style="text-transform: capitalize;" class=" gauche">{{admin.prenom}}</td>
              <td [routerLink]="['/gestionRolesAdmin',admin._id]" class=" gauche">{{admin.email}}</td>
              <td class="row gauche" style="border-bottom-style: none;">
                <div class="col-1" style="margin-right: 20px !important;">
                  <a title="Roles" [routerLink]="['/gestionRolesAdmin',admin._id]" title="Gestion des rôles">
                    <img src="assets/settings-7.png" class="iconParametres"></a>
                </div>
                <div class="col-1">
                  <a (click)="setAdminTodelete(admin)" title="Supprimer" data-toggle="modal" data-target="#myModal3"><img src="assets/delete-7.png" class="iconParametres"></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row" *ngIf="admins && admins.length > 25">
          <div class="col col-xs-6">
            <pagination-controls (pageChange)="p = $event" #api></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!---------------------------- Pop up supprimer admin ---------->
  <div class="modal fade" id="myModal3" tabindex="-1" role="dialog" aria-labelledby="myModal6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-scan">
        <div class="modal-header d-flex justify-content-center">
          <h3 class="titre-modal-statut text-uppercase">
            SUPPRIMER ADMIN
          </h3>
        </div>
        <div class="modal-body">
          <div class="row" style="margin-left: 6%;">
            <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop"></div>
            <div class="col-10">
              <h4 class="textContenu text-uppercase">VOULEZ-VOUS SUPPRIMER CET ADMIN?</h4>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center buttonsPopup">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td class="tdButt1 ">
                      <button class="btn-valider" data-dismiss="modal" data-toggle="modal" data-target="#deleted" (click)="deleteAdmin(admin)"><img src="assets/delete2.png" class="iconBoutton">Supprimer </button>
                    </td>
                    <td class="tdButt2 ">
                      <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton">Annuler </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---------------------------- Pop up Ajouter Admin ---------->
  <div class="modal fade" id="myModal4" tabindex="-1" role="dialog" aria-labelledby="myModal4" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-scan">
        <div class="modal-header d-flex justify-content-center">
          <h3 class="titre-modal-statut">
            NOUVEL ADMIN
          </h3>
        </div>
        <div class="modal-body">
          <form [formGroup]="form" (submit)="saveAdmin()">
            <div class="row" style="margin-left: 6%;margin-top: -30px;">
              <div class="col-10">
                <div class="title textContenu">
                  <input class="font-weight-bold" placeholder="Nom*" formControlName="nom" name="nom">
  
                </div><br>
                <div class="col-12">
                  <control-messages [control]="form.controls.nom" style="color:red;text-align: center;"></control-messages>
                </div>
                </div>
            </div>
            <div class="row" style="margin-left: 6%;margin-top: -30px;">
              <div class="col-10">
                <div class="title textContenu">
                  <input class="font-weight-bold" placeholder="Prénom*" formControlName="prenom" name="prenom">
                </div><br>
                <div class="col-12">
                  <control-messages [control]="form.controls.prenom" style="color:red;text-align: center;"></control-messages>
                </div>
                </div>
            </div>
            <div class="row" style="margin-left: 6%;margin-top: -30px;">
              <div class="col-10">
                <div class="title textContenu">
                  <input class="font-weight-bold" placeholder="Email*" formControlName="email" name="email">
  
                </div><br>
                <div class="col-12">
                  <control-messages [control]="form.controls.email" style="color:red;text-align: center;"></control-messages>
                  <p *ngIf="showErrorEmail" style="color:red;font-size:16px;text-align: center;">Cette adresse est dèjà utilisée</p>
                </div>
              </div>
            </div>
            <div class="row" style="margin-left: 6%;margin-top: -30px;">
              <div class="col-10">
                <div class="title textContenu">
                  <input class="font-weight-bold" type="password" formControlName="password" name="password"  placeholder="Mot de passe*">
  
                </div><br>
                <div class="col-12">
                <control-messages [control]="form.controls.password" style="color:red;text-align: center;"></control-messages>
                </div>
              </div>
            </div>
  
            <div class="row" style="margin-left: 6%;margin-top: -30px;">
                <div class="col-10">
                  <div class="title textContenu">
                    <input class="font-weight-bold" type="password" placeholder="Confirmer mot de passe*" formControlName="confirmationPassword" name="confirmationPassword">
  
                  </div><br>
                  <div class="col-12">
                    <control-messages [control]="form.controls.confirmationPassword" style="color:red;text-align: center;"></control-messages>
                    <p style="color:red;font-size:16px;text-align: center;" *ngIf="errorPassword">Les mots de passe ne correspondent pas !</p>
                  </div>
                </div>
            </div>
  
            <div class="modal-footer d-flex justify-content-center">
              <div class=" col d-flex justify-content-center buttonsPopup">
                <div class="col-auto nbr-dossier ">
                  <table>
                    <tr>
                      <td class="tdButt1 ">
                        <button class="btn-valider" [disabled]="!form.valid" ><img src="assets/valider.png" class="iconBoutton">Ajouter </button>
                      </td>
                      <td class="tdButt2 ">
                        <button class="btn-annule" data-dismiss="modal" #closeAddExpenseModal><img src="assets/annuler.png" class="iconBoutton">Annuler </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            </form>
        </div>
      </div>
    </div>
  </div>
  