import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder,Validators } from '@angular/forms';
import { API_CONFIG } from 'config/API_CONFIG';
import { _getURL } from 'config/API_CONFIG';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { AdminService } from 'src/app/services/admin/admin.service';
import { ValidationService } from 'src/app/services/validate/validateError.service';
import { sortTable } from '../../search/sortTable';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.css','../../medecins/medecins/medecins.component.css']
})
export class AdminsComponent implements OnInit {

  public search: any = '';
  form: any;
  admin: any;
  admins: any[] = [];
  showErrorEmail = false;
  errorPassword = false;
  @ViewChild('closeAddExpenseModal') closeAddExpenseModal: ElementRef;
  selectedAdmin: any;
  p:any;
  constructor(private adminService: AdminService,  private http: HttpClient, private authService: AuthService,
    private router: Router, private formBuilder: FormBuilder) {
    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if (!this.admin.roles.gestionAdmins) {
        this.router.navigate(['/notFound']);
        return false;
      }
    },
      err => {
        console.log(err);
        return false;
      });

  }

  ngOnInit() {
    /*let el = document.getElementById('scrolltoverify');
    el.scrollIntoView({ behavior: "smooth" });*/
    this.getAdmins()
    this.form = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      prenom: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      email: ['', [Validators.required, ValidationService.emailValidator, Validators.maxLength(50)]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
      confirmationPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
    });
  }

  getAdmins() {
    this.adminService.getAllSousAdmin().subscribe((admins:any[]) => {
      this.admins = admins;

    },
      err => {
        console.log(err);
        return false;
      });
  }
  saveAdmin() {
    this.form.value.sousAdmin = true;
    if (this.form.value.password != this.form.value.confirmationPassword) {
      this.errorPassword = true;
      return false;
    }
    this.errorPassword = false;
    this.adminService.verifyEmail(this.form.value).subscribe((data:any) => {
      if (data.success) {
        this.showErrorEmail = true
        return false;
      }
      else {
        this.adminService.registerAdmin(this.form.value).subscribe(data => {
          this.admins.push(data);
          this.form.reset();
          this.getAdmins()
        });
        Swal.fire({
          width: 500,
          background: '#e1e1e1',
          timer: 3000,
          html: '<div class="row">' +
            '<div class="col-3">' +
            '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
            '</div>' +
            '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">ADMIN A ÉTÉ AJOUTÉ avec succès</h4></div>' +
            '</div>',
          showConfirmButton: false
        }
        );
        this.closeAddExpenseModal.nativeElement.click();
      }
    })

  }
  setAdminTodelete(admin) {
    this.selectedAdmin = admin
  }
  deleteAdmin(admin) {
    admin = this.selectedAdmin
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    this.http.delete(_getURL(API_CONFIG.admin) + '/' + admin._id, { headers: headers }).subscribe((res: any) => {
      this.admins.splice(this.admins.indexOf(admin), 1);
      Swal.fire( {
        width: 500,
        background: '#e1e1e1',
        timer: 3000,
        html: '<div class="row">' +
          '<div class="col-3">' +
          '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
          '</div>' +
          '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Admin a été supprimé avec succès</h4></div>' +
          '</div>',
        showConfirmButton: false
      }
    );
    })
  }
  sortTable(n) {
    sortTable(n)
  }

}

