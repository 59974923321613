<div id="scrolltoverify">

</div>
<div class="modal-header d-flex justify-content-center">
  <h3 class="titre-modal-statut text-uppercase">
    Ajouter des patients
  </h3>
</div>
<div class="ajoutAll">
  <div class="telechargerFichier">
    <a (click)="gotoExel()" Target="_blank">Télécharger le fichier modèle</a>
  </div>
  <form [formGroup]="form">
    <div class="row">
      <p style="color:green" *ngIf="successAdd">L'ajout a été effectué avec succès !</p>
      <p style="color:red">{{errorLigne}}</p>
    </div>
    <div class="row">
      <div class="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-11">
        <div class="title textContenu">
          <!--<h4>Médecin*</h4>-->
          <select class=" select-pop2 " formControlName="medecin" name="medecin" (change)="selectMedecin()">
            <option value="" disabled selected STYLE="color:#E5B92C ">Médecin*</option>
            <option *ngFor="let medecin of medecins" [ngValue]="medecin">{{medecin.nom}} {{medecin.prenom}}</option>
          </select>
          <control-messages [control]="form.controls.medecin"></control-messages>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="selectedMedecin && showFilesInput" style="position: relative;top: 15px;left: 15px;">
      <label for="prenom">Détails du médecin</label>
      <li style="list-style-type: none;">Nom & Prénom : {{selectedMedecin.nom}} {{selectedMedecin.prenom}}</li>
      <li style="list-style-type: none;">Email : {{selectedMedecin.email}}</li>
      <li style="list-style-type: none;">Téléphone : {{selectedMedecin.tel}}</li>
      <li style="list-style-type: none;">N°d'inscription au conseil de l'ordre : {{selectedMedecin.numInscription}}</li>
      <br>
    </div>
    <div class="row">
      <div class="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-11">
        <div class="title textContenu">
          <label style="font-size: 15px;font-weight: bold;    color: #666666;">Fichier(xlsx)</label>
          <input class="input-btn-file" [(ngModel)]="fileToChoose" formControlName="fileToChoose" name="fileToChoose" (change)="onFileChange($event)" type="file" id="file" accept=".xlsx" required />
        </div>
        <control-messages [control]="form.controls.fileToChoose"></control-messages>
      </div>
    </div>
    <div class="row buts">
      <div class="col-1 col-xl-2 col-lg-2 col-md-1 col-sm-1"></div>
      <div class="col-12 col-xl-6 col-lg-8 col-md-12 col-sm-12">
        <div class="row ">
          <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm 12">
            <button class="btn-valider"  [disabled]="!form.valid" data-toggle="modal" data-target="#myModal3"><img src="assets/valider.png" class="iconBoutton">Ajouter </button>
          </div>
          <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm 12 ">
            <button class="btn-annule" [routerLink]="['/patients']"><img src="assets/annuler.png" class="iconBoutton">Annuler </button>
          </div>
        </div>
      </div>
      <div class="col-1 col-xl-2 col-lg-2 col-md-1 col-sm-1"></div>
    </div>
  </form>
</div>

<!---------------------------- Pop up supprimer admin ---------->
<div class="modal fade" id="myModal3" tabindex="-1" role="dialog" aria-labelledby="myModal6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan">
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut text-uppercase">
          AJOUTER DES PATIENTS
        </h3>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-left: 6%;">
          <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop"></div>
          <div class="col-10">
            <h4 class="textContenu text-uppercase">VOULEZ-VOUS SAUVEGARDER CE FICHIER?</h4>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <div class=" col d-flex justify-content-center buttonsPopup">
            <div class="col-auto nbr-dossier ">
              <table>
                <tr>
                  <td class="tdButt1 ">
                    <button class="btn-valider" data-dismiss="modal" data-toggle="modal" data-target="#deleted" (click)="savePatients()"><img src="assets/valider.png" class="iconBoutton">Sauvegarder </button>
                  </td>
                  <td class="tdButt2 ">
                    <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton">Annuler </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
