<!-- formulaire d'inscription d'un médecin  -->
<section id="services-section" id="scrolltoverify">
    <div class="content-section" id="services">
        <div class="container">
            <div class="row">
                <div class="heading-section col-md-12 text-center wow animated fadeInDown">
                    <h3
                        style="text-align: center;color: #E5B92C;text-transform: uppercase!important;font-size: 1.75rem;">
                        Ajouter un nouvel
                        événement</h3>
                    <h3 style="color:rgb(15, 70, 103);display: inline-block;font-size: 25px;"></h3>
                </div>
                <!-- /.heading-section -->
            </div>
            <ng-template #customLoadingTemplate></ng-template>
            <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
            </ngx-loading>
            <!-- /.row -->
            <div id="sevices-item" class="row">
                <form [formGroup]="form" class="form-horizontal" role="form" (ngSubmit)="saveSujet()">
                    <fieldset>
                        <!-- Form Name -->
                        <div class="form-group col-md-12 col-sm-6">
                            <legend>Informations</legend>
                            <p style="color:green" *ngIf="successRegister">L'événement a été ajouté avec succès</p>
                        </div>
                        <!-- Text input-->
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <div class="title textContenu">
                                    <label for="nom">Sujet* </label>
                                    <input type="text" class="form-control input-sm" id="nom" formControlName="title"
                                        name="title" placeholder="Sujet">
                                </div>
                                <control-messages [control]="form.controls.title"></control-messages>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="title textContenu">
                                    <label for="specialite">Thème*</label>
                                    <select class="form-control input-sm" style="border:none" id="specialite"
                                        formControlName="theme" name="theme">
                                        <option>Cinéma</option>
                                        <option>Education</option>
                                        <option>Gastronomie</option>
                                        <option>Musique</option>
                                        <option>Parties</option>
                                        <option>Rencontres et réunions</option>
                                        <option>Santé et Bien être</option>
                                        <option>Sports </option>
                                        <option>Théâtre</option>
                                        <option>Tradeshows</option>
                                    </select>
                                </div>
                                <control-messages [control]="form.controls.theme"></control-messages>
                            </div>
                        </div>
                        <br><br>
                        <div class="form-group col-md-12">
                            <label for="">Description*</label>
                            <textarea id="myTextArea" class="mceEditor" rows="3"
                                style="margin: 0px; height: 163px;width: 1096px;" formControlName="description"
                                name="desciprion" placeholder="Description"></textarea>
                            <control-messages [control]="form.controls.description"></control-messages>
                            <p *ngIf="errorDescription" style="color:red">Vérifier la description (10 caratères minimum
                                et 2000 caractères maximum) !</p>
                        </div>

                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <div class="title textContenu">
                                    <label for="nom">De* </label>
                                    <input type="datetime-local" class="form-control input-sm" id="nom"
                                        formControlName="date" name="date" placeholder="date">
                                </div>
                                <control-messages [control]="form.controls.date"></control-messages>
                                <p style="color:red;font-size: 15px;" *ngIf="errorDate"> Merci de vérifier les dates !
                                </p>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="title textContenu">
                                    <label for="specialite">A*</label>
                                    <input type="datetime-local" class="form-control input-sm" id="nom"
                                        formControlName="dateFin" name="dateFin" placeholder="dateFin">
                                </div>
                                <control-messages [control]="form.controls.dateFin"></control-messages>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                <div class="title textContenu">
                                    <label for="nom">Lieu* </label>
                                    <input type="text" class="form-control input-sm" id="nom" formControlName="lieu"
                                        name="lieu" placeholder="Lieu">
                                </div>
                                <control-messages [control]="form.controls.lieu"></control-messages>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <div class="title textContenu">
                                    <label for="nom">Lien d'inscription </label>
                                    <input type="text" class="form-control input-sm" id="nom" formControlName="lienSite"
                                        name="lienSite" placeholder="Lien d'inscription">
                                </div>
                                <control-messages [control]="form.controls.lienSite"></control-messages>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="title textContenu">
                                    <label for="nom">Frais d'inscription </label>
                                    <input type="text" class="form-control input-sm" id="nom"
                                        formControlName="fraisInscription" name="fraisInscription"
                                        placeholder="Frais d'inscription">
                                </div>
                                <control-messages [control]="form.controls.fraisInscription"></control-messages>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <div class="title textContenu">
                                    <label for="nom">Image </label>
                                    <input type="file" class="form-control input-sm" id="fileDropCss" name="image"
                                        ng2FileSelect [uploader]="uploader" accept="image/*" placeholder="Image">
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="title textContenu">
                                    <label for="nom">Pour* </label>
                                    <select class="form-control input-sm" style="border:none" id="cible"
                                        formControlName="cible" name="cible">
                                        <option>Patient</option>
                                        <option>Médecin</option>
                                        <option>Les deux</option>
                                    </select>
                                </div>
                                <control-messages [control]="form.controls.cible"></control-messages>
                            </div>

                        </div>
                        <div class="row buts" style="position: relative;left: 11%;top: 25px;">
                            <div class="col-1 col-xl-2 col-lg-2 col-md-1 col-sm-1"></div>
                            <div class="col-12 col-xl-6 col-lg-8 col-md-12 col-sm-12">
                                <div class="row ">
                                    <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm 12">
                                        <button class="btn-valider" [disabled]="!form.valid" type="submit"><img
                                                src="assets/valider.png" class="iconBoutton">Ajouter </button>
                                    </div>
                                    <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm 12 ">
                                        <button class="btn-annule" [routerLink]="['/events']"><img
                                                src="assets/annuler.png" class="iconBoutton">Annuler </button>
                                    </div>
                                </div>
                                <br><br><br>
                            </div>
                            <div class="col-1 col-xl-2 col-lg-2 col-md-1 col-sm-1"></div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <!-- /.row -->


        </div>
        <!-- /.container -->
    </div>


    <!-- /#services -->
</section>



<!-- end formulaire d'inscription d'un médecin  -->