<!-- formulaire d'inscription d'un médecin  -->
<section id="services-section" id="scrolltoverify">
    <div class="content-section" id="services">
      <div class="container">
        <div class="row">
          <div class="heading-section col-md-12 text-center wow animated fadeInDown" style="padding: 1rem 1rem;border-bottom: 1px solid #dee2e6;border-top-left-radius: calc(0.3rem - 1px);border-top-right-radius: calc(0.3rem - 1px);">
            <h3 style="text-align: center;color: #E5B92C;text-transform: uppercase!important;font-size: 1.75rem;">Ajouter un secrètaire</h3>
            <h3 style="color:rgb(15, 70, 103);display: inline-block;font-size: 25px;"></h3>
          </div>
          <!-- /.heading-section -->
        </div>
        <!-- /.row -->
        <div id="sevices-item" class="row">
          <form [formGroup]="form" class="form-horizontal" role="form"  style="width: 100%;" >
            <br>
            <fieldset>
              <!-- Form Name -->
              <div class="form-group row col-sm-6">
                <legend>Informations</legend>
                <p style="color:green" *ngIf="successRegister">Le médecin est ajouté avec succès</p>
              </div>
              <!-- Text input-->
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="nom">Nom* </label>
                    <input type="text" class="form-control input-sm" id="nom" formControlName="nom" name="nom" placeholder="Nom" >
                  </div>
                  <control-messages [control]="form.controls.nom"></control-messages>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="prenom">Prénom*</label>
                    <input type="text" formControlName="prenom" name="prenom" class="form-control input-sm" id="prenom" placeholder="Prénom" >
                  </div>
                  <control-messages [control]="form.controls.prenom"></control-messages>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="tel">Email*</label>
                    <input type="text" formControlName="email" name="email" class="form-control input-sm" id="email" placeholder="Email" >
                  </div>
                  <control-messages [control]="form.controls.email"></control-messages>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="numInscription">N° d'inscription au conseil de l'ordre*</label>
                    <input type="text" formControlName="numInscription"  name="numInscription" class="form-control input-sm" id="numInscription" placeholder="N°d'inscription au conseil de l'ordre">
                  </div>
                  <control-messages [control]="form.controls.numInscription"></control-messages>
                  <p style="color:red;font-size: 14px;" *ngIf="errorNumeroInscription">Le numéro d'inscription est déjà utilisé</p>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="tel">Mot de passe*</label>
                    <input type="password" formControlName="password" name="password" class="form-control input-sm" id="email" placeholder="Mot de passe" >
                  </div>
                  <control-messages [control]="form.controls.password"></control-messages>
                  <div *ngIf="f && f.password && f.password.errors && f.password.errors.pattern" style="color: red;">
                    Le mot de passe doit avoir au moins 6 caractères, incluant au moins une lettre en minuscule, une lettre en majuscule, un chiffre et un caractère spécial.
                  </div>
                </div>

                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="tel">Confirmation du mot de passe*</label>
                    <input type="password" formControlName="confrimPassword" name="confrimPassword" class="form-control input-sm" id="email" placeholder="Confirmation du mot de passe" >
                  </div>
                  <control-messages [control]="form.controls.confrimPassword"></control-messages>
                  <div *ngIf="f && f.confrimPassword && f.confrimPassword.errors && f.confrimPassword.errors.mustMatch" style="color: red;">
                    Les mots de passe ne correspondent pas.
                  </div>
                </div>
                
              </div>
              <br><br>
              <div class="row">
                <table class="table  "  >
                  <tbody>
                  <tr class=" gauche">
                    <td  class=" gauche"  >Gestion des rendez-vous</td>
                    <td  class=" gauche" >
                      <label class="switch">
                        <input type="checkbox" id="TriSeaPrimary" name="gestionRdvs"  formControlName="gestionRdvs" >
                        <span class="slider round"></span>
                      </label>
                    </td>
                  </tr>
                  <tr class=" gauche">
                    <td  class=" gauche"  > Gestion des patients  </td>
                    <td  class=" gauche" >
                      <label class="switch">
                        <input type="checkbox" id="TriSeaPrimary1" name="gestionPatient" formControlName="gestionPatient" >
                        <span class="slider round"></span>
                      </label>
                    </td>
                  </tr>
                  <tr class=" gauche">
                    <td  class=" gauche"  > Gestion d'agenda  </td>
                    <td  class=" gauche" >
                      <label class="switch">
                        <input type="checkbox" id="TriSeaPrimary2" name="gestionAgenda"  formControlName="gestionAgenda" >
                        <span class="slider round"></span>
                      </label>
                    </td>
                  </tr>
                  <tr class=" gauche">
                    <td  class=" gauche"  > Gestion des documents  </td>
                    <td  class=" gauche" >
                      <label class="switch">
                        <input id="TriSeaPrimary3" name="gestionDocuments" type="checkbox" formControlName="gestionDocuments"  >
                        <span class="slider round"></span>
                      </label>
                    </td>
                  </tr>
                  <tr class=" gauche">
                    <td  class=" gauche"  > Gestion des certificats  </td>
                    <td  class=" gauche" >
                      <label class="switch">
                        <input id="TriSeaPrimary4" name="gestionCertificats" type="checkbox" formControlName="gestionCertificats"  >
                        <span class="slider round"></span>
                      </label>
                    </td>
                  </tr>
                  <tr class=" gauche">
                    <td  class=" gauche"  > Gestion des ordonnances  </td>
                    <td  class=" gauche" >
                      <label class="switch">
                        <input id="TriSeaPrimary5" name="gestionOrdonnaces" type="checkbox" formControlName="gestionOrdonnaces" >
                        <span class="slider round"></span>
                      </label>
                    </td>
                  </tr>
                  <tr class=" gauche">
                    <td  class=" gauche"  > Gestion des médecins  </td>
                    <td  class=" gauche" >
                      <label class="switch">
                        <input id="TriSeaPrimary6" name="gestionMedecins" type="checkbox" formControlName="gestionMedecins" >
                        <span class="slider round"></span>
                      </label>
                    </td>
                  </tr>
                  <tr class=" gauche">
                    <td  class=" gauche"  > Gestion des évenements  </td>
                    <td  class=" gauche" >
                      <label class="switch">
                        <input id="TriSeaPrimary7" name="gestionEvenements" type="checkbox" formControlName="gestionEvenements" >
                        <span class="slider round"></span>
                      </label>
                    </td>
                  </tr>
                  <tr class=" gauche">
                    <td  class=" gauche"  > Gestion des salles d'attente  </td>
                    <td  class=" gauche" >
                      <label class="switch">
                        <input id="TriSeaPrimary7" name="gestionSalleAttente" type="checkbox" formControlName="gestionSalleAttente" >
                        <span class="slider round"></span>
                      </label>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div class="row" style="position: relative;top: 35px;margin-left: 0px;color: red;margin-bottom: 20px;">
                {{showerrorligne}}
              </div>
              <div class="row buts" style="position: relative;left: 2%;">
                <div class="col-1 col-xl-2 col-lg-2 col-md-1 col-sm-1"></div>
                <div class="col-12 col-xl-6 col-lg-8 col-md-12 col-sm-12">
                  <div class="row ">
                    <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm 12">
                      <button class="btn-valider"  (click)="onRegisterSecretaire()"  ><img src="assets/valider.png" class="iconBoutton">Ajouter </button>
                    </div>
                    <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm 12 ">
                      <button class="btn-annule" [routerLink]="['/medecins']"><img src="assets/annuler.png" class="iconBoutton">Annuler </button>
                    </div>
                  </div>
                  <br><br><br>
                </div>
                <div class="col-1 col-xl-2 col-lg-2 col-md-1 col-sm-1"></div>
              </div>
            </fieldset>
          </form>
        </div>
        <!-- /.row -->
  
  
      </div>
      <!-- /.container -->
    </div>
  
  
    <!-- /#services -->
  </section>
  
  
  
  <!-- end formulaire d'inscription d'un médecin  -->
  