import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FileUploader } from 'ng2-file-upload';
import { API_CONFIG } from 'config/API_CONFIG';
import { _getURL } from 'config/API_CONFIG';
import { ImageService } from 'src/app/services/image/image.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AdminService } from 'src/app/services/admin/admin.service';
import { uploadFolderRootAdmin } from 'config/API_CONFIG';
import { Location } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css','../medecins/medecins/medecins.component.css']
})
export class ProfilComponent implements OnInit {

  image: any;
  form: any;
  admineditPassword: any;
  testPassword = false;
  testOldPassword = false;
  testConfirmPassword = false;
  admin: any;
  RolesAdmin: any[] = [];
  interval: any;
  urlImage: any;
  public loading = false;
  public uploader: FileUploader = new FileUploader({ url: _getURL(API_CONFIG.image) + '/adminImage' });
  location: Location;
  sizeLimitOne = 10000000; //10MB
  fileToChoose: any;
  constructor(private adminService: AdminService, private formBuilder: FormBuilder, private imageService: ImageService,
     location: Location, private authService: AuthService) {
    this.location = location;
    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
    },
      err => {
        return false;
      });
    this.form = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
      confirmPassword: ['', [Validators.required, Validators.maxLength(50)]]
    });
  }
  ngOnInit() {
    let el = document.getElementById('scrolltoverify');
    el.scrollIntoView({ behavior: "smooth" });
    var adminString = localStorage.getItem('admin');
    var adminId = JSON.parse(adminString).id;
    var adminNom = JSON.parse(adminString).nom;
    var adminPrenom = JSON.parse(adminString).prenom;
    this.imageService.getImageAdmin(adminId).subscribe(profile => {
      this.image = profile[0];
      if (this.image) {
        this.urlImage = uploadFolderRootAdmin + this.image.name
      }
    },
      err => {
        console.log(err);
        return false;
      });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    }
  }

  fileChange($event) {

    var myfile = $event.target.files.item(0)
    if (myfile) {
      if (!myfile.type.match('image.*')) {
        this.fileToChoose = undefined
        this.uploader.queue.length = 0
        Swal.fire({
          width: 500,
          background: '#e1e1e1',
          timer: 3000,
          html: '<div class="row">' +
            '<div class="col-3">' +
            '<img src="assets/error-4.png" style="height:60px; margin-left: 20px">' +
            '</div>' +
            '<div><h4 class="text-uppercase" style="margin-top: 23px; font-size:16px">Impossible de télécharger ce fichier !</h4></div>' +
            '</div>',
          showConfirmButton: false
        }
        );
        return false;
      }
      if (myfile.size > this.sizeLimitOne) {
        this.fileToChoose = undefined
        this.uploader.queue.length = 0
        Swal.fire({
          width: 500,
          background: '#e1e1e1',
          timer: 3000,
          html: '<div class="row">' +
            '<div class="col-3">' +
            '<img src="assets/error-4.png" style="height:60px; margin-left: 20px">' +
            '</div>' +
            '<div><h4 class="text-uppercase" style="margin-top: 23px; font-size:16px">Un fichier sélectionné est trop volumineux.La taille maximale est 10 Mo !</h4></div>' +
            '</div>',
          showConfirmButton: false
        }
        );
        return false;
      }
      this.uploader.options.additionalParameter = {
        nom: this.admin.nom,
        prenom: this.admin.prenom,
        adminId: this.admin._id
      };
    } else {
      return false;
    }

  }
  refresh() {
    this.loading = true;
    setTimeout(function() { location.reload(); this.loading = false; }, 9000);
  }
  editPassword(admin) {
    this.testOldPassword = false;
    this.testPassword = false;
    this.testConfirmPassword = false;
    var oldAdmin = {
      _id: admin._id,
      password: this.form.value.oldPassword
    }
    var newAdmin = {
      _id: admin._id,
      email: admin.email,
      password: this.form.value.password
    }
    this.adminService.verifyPassword(admin._id, oldAdmin).subscribe((data:any) => {
      if (data.success) {
        if (this.form.value.oldPassword == this.form.value.password) {
          this.testPassword = true;
          return false;
        }
        if (this.form.value.password != this.form.value.confirmPassword) {
          this.testConfirmPassword = true;
          return false;
        }
        else {
          this.adminService.editProfil(newAdmin).subscribe(newAdmin => {
            this.admineditPassword = newAdmin;
          });
          document.getElementById("btn-annule-password").click();
          Swal.fire({
            width: 500,
            background: '#e1e1e1',
            timer: 3000,
            html: '<div class="row">' +
              '<div class="col-3">' +
              '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
              '</div>' +
              '<div><h4 class="text-uppercase" style="margin-top: 23px; font-size:16px">Le mot de passe a été Modifié avec succès</h4></div>' +
              '</div>',
            showConfirmButton: false
          }
          );
          this.form.reset()
          this.testOldPassword = false
          this.testConfirmPassword = false
          this.testPassword = false
        }
      } else {
        this.testOldPassword = true;
        return false;
      }
    });

  }
  initialiserForm() {
    this.form.reset()
    this.testOldPassword = false
    this.testConfirmPassword = false
    this.testPassword = false
  }

  initialisFormPhoto(){
    this.fileToChoose = undefined
    this.uploader.queue.length = 0
  }

}
