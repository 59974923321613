import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';
const headers = new HttpHeaders({
    'Authorization': localStorage.getItem('id_token_adminMedivisto'),
    'Content-Type': 'application/json'
});
@Injectable()
export class SecretaireService {
    constructor(private http: HttpClient) {

    }
    getAllsecretairesByMedecin(id) {
        return this.http.get(_getURL(API_CONFIG.secretaires) + '/medecin/' + id, { headers: headers })
    }
    registerSecretaire(data) {
        return this.http.post(_getURL(API_CONFIG.secretaires), JSON.stringify(data), { headers: headers })
    }
    getSecretairesById(id) {
        return this.http.get(_getURL(API_CONFIG.secretaires) + '/' + id, { headers: headers })
    }
    updateSecretaire(data,id) {
        return this.http.put(_getURL(API_CONFIG.secretaires)+'/'+id, JSON.stringify(data), { headers: headers })
    }
}