<div id="scrolltoverify">

</div>
<!------------------ Liste des articles affichés ----------------------->
<div class="informationPat3" *ngIf="showPart()==1">
  <div class="row bar">
    <div class="col-10 col-xl-4 col-lg-4 col-md-10 col-sm-10 bar-section" (click)="modifPart1()"><a class="linkPart2"><b >Liste des articles</b></a></div>
    <div class="col-10 col-xl-4 col-lg-4 col-md-10 col-sm-10 bar-section" (click)="modifPart2()"><a class="linkPart"><b>Liste des articles archivés</b></a></div>
  </div>
  <div class=" search4">
    <div class="row cover-search-row2">
      <div class="col-lg-5 col-md-8 col-sm-10 col-xs-10" style="margin-right: 4%">
        <div class="row rowSearch2" >
          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 name-col-10">
            <input type="text" [(ngModel)]="search" name="search" class="inputRecherche"
                   placeholder="Rechercher">
          </div>
          <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 name-col-1" style="">
            <img id="name_img" src="" alt="" style="height: 25px;   margin-top: 19px; margin-left: -8px">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row rightButts">
    <div class="col-11 " > 
        <span class="dot" style="background-color: red;position: relative;top: 6px;margin-right: 10px;"></span>Invisible sur le site
        <span class="dot" style="background-color: green;position: relative;top: 6px;margin-left: 10px;margin-right: 10px;"></span>Visible sur le site
    </div>
    <div class="col-1">
      <div class=" buttoncadre3"><a [routerLink]="['/ajoutArticle']" title="Ajouter un article">
        <img class="iconButt2 " src="assets/plus-4.png" ></a>
      </div>
    </div>
  </div>
  <div>
    <!--<a class="param" data-toggle="modal" data-target="#myModal4">Paramètres du tableau</a>-->
  </div>
  <div class="div-tab2">
    <div class="row" style="overflow-x:auto;" >
      <table class="table  "  >
        <thead >
        <th id="firstThTable" class=" gauche text-uppercase" ><b>TITRE</b></th>
        <th  class=" gauche text-uppercase" ><b>CATÉGORIE</b></th>
        <th  class=" gauche text-uppercase" ><b>DATE DE PUBLICATION</b></th>
        <th  class=" gauche text-uppercase" ><b>STATUT</b></th>
        <th class="  gauche text-uppercase" style="width: 20%;margin-right: 26px" ><b>PARAMÈTRES</b></th>
        </thead>
        <tbody>
        <tr class=" gauche" *ngFor="let article of listArticleNonArchives|searchPatient: {titre: search, categorie:search} | paginate: { itemsPerPage: 25, currentPage: p,id:'api' }">
            <td [routerLink]="['/article/modifier',article._id]" style="text-transform: capitalize;">{{article.titre}}</td>
            <td  [routerLink]="['/article/modifier',article._id]" style="text-transform: capitalize;">{{article.categorie}}</td>
            <td  [routerLink]="['/article/modifier',article._id]">{{article.date.toLocaleDateString('fr-FR', { day: '2-digit', month:
              '2-digit', year: 'numeric' })}}</td>
              <td *ngIf="article.visibilite" class="align-center">
                <span class="dot" style="background-color: green"></span>
              </td>
              <td *ngIf="!article.visibilite" class="align-center">
                <span class="dot" style="background-color: red"></span>
              </td>
          <td class="row gauche" style="border-bottom-style: none;">
            <div class="col-1" *ngIf="article.visibilite" style="margin-right: 18px !important;">
                <a  title="Masquer" (click)="setarticleTodelet(article)" data-toggle="modal" data-target="#myModalAcceptMedecin">
                  <img src="assets/eye2.png" class="iconParametres" style="height: 32px;"></a>
              </div>
              <div class="col-1" *ngIf="!article.visibilite" style="margin-right: 20px !important;">
                <a  (click)="setarticleTodelet(article)" data-toggle="modal" data-target="#myModalAcceptMedecin" title="Visualiser">
                  <img src="assets/oeil-4.png" class="iconParametres" style="height: 27px;"></a>
              </div>
            <div class="col-1"  style="margin-right: 10px !important;">
              <a [routerLink]="['/article/modifier',article._id]" title="Modifier">
                <img src="assets/pencil-7.png" class="iconParametres" ></a>
            </div>
            <div class="col-1"  style="margin-right: 10px !important;">
              <a (click)="setarticleTodelet(article)" data-toggle="modal" data-target="#myModalSuppMedAcc" title="Archiver"><img src="assets/delete-7.png" class="iconParametres" ></a>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="pagination2" *ngIf="listArticleNonArchives && listArticleNonArchives.length > 25">
    <div class="row">
      <pagination-controls (pageChange)="pageChanged($event);p = $event" id="api"></pagination-controls>
    </div>
  </div>
</div>
<!------------------ Liste des médecins en attente ----------------------->
<div class="informationPat3" *ngIf="showPart()==2">
  <div class="row bar">
    <div class="col-10 col-xl-4 col-lg-4 col-md-10 col-sm-10 bar-section" (click)="modifPart1()"><a class="linkPart"><b >Liste des articles</b></a></div>
    <div class="col-10 col-xl-4 col-lg-4 col-md-10 col-sm-10 bar-section" (click)="modifPart2()"><a class="linkPart2"><b>Liste des articles archivés</b></a></div>
  </div>
  <div class=" search4">
    <div class="row cover-search-row2">
      <div class="col-lg-5 col-md-8 col-sm-10 col-xs-10" style="margin-right: 4%">
        <div class="row rowSearch2" >
          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 name-col-10">
            <input type="text" [(ngModel)]="searchArchived" name="searchArchived" class="inputRecherche"
                   placeholder="Rechercher">
          </div>
          <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 name-col-1" style="">
            <img id="name_img" src="" alt="" style="height: 25px;   margin-top: 19px; margin-left: -8px">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row rightButts">
    <div class="col-11 " > <div class=" buttoncadre0">
    </div>
    </div>
    <div class="col-1">
      <div class=" buttoncadre3"><a [routerLink]="['/ajoutArticle']" title="Ajouter un article">
        <img class="iconButt2 " src="assets/plus-4.png" ></a>
      </div>
    </div>
  </div>
  <div>
  </div>
  <div class="div-tab2">
    <div class="row" style="overflow-x:auto;" >
      <table class="table  "  >
        <thead >
          <th id="firstThTable" class=" gauche text-uppercase" ><b>Titre</b></th>
          <th  class=" gauche text-uppercase" ><b>Catégorie</b></th>
          <th  class=" gauche text-uppercase" ><b>DATE DE PUBLICATION</b></th>
          <th class="  gauche text-uppercase" style="width: 20%;margin-right: 26px" ><b>PARAMÈTRES</b></th>
        </thead>
        <tbody>
        <tr class=" gauche" *ngFor="let article of listArticleArchives|searchPatient: {titre: searchArchived, categorie:searchArchived} | paginate: { itemsPerPage: 25, currentPage: pc, id:'apic'  }">
          <td [routerLink]="['/article/modifier',article._id]" style="text-transform: capitalize;">{{article.titre}}</td>
          <td  [routerLink]="['/article/modifier',article._id]" style="text-transform: capitalize;">{{article.categorie}}</td>
          <td  [routerLink]="['/article/modifier',article._id]">{{article.date.toLocaleDateString('fr-FR', { day: '2-digit', month:
            '2-digit', year: 'numeric' })}}</td>
          <td class="row gauche" style="border-bottom-style: none;">
           
            <div class="col-1" >
              <a  (click)="setarticleTodelet(article)" data-toggle="modal" data-target="#myModelReset" title="Restaurer"><img src="assets/reset.png" style="height: 30px;" class="iconParametres" ></a>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="pagination2" *ngIf="listArticleArchives && listArticleArchives.length > 25">
    <div class="row">
      <pagination-controls (pageChange)="pageChanged($event);pc = $event" id ="apic"></pagination-controls>
    </div>
  </div>
</div>

<!---------------------------- Pop up supprimer Medecin ---------->
<div class="modal fade" id="myModalAcceptMedecin" tabindex="-1" role="dialog"
     aria-labelledby="myModal6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan" >
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut text-uppercase">
          Afficher/Masquer un article
        </h3>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-left: 6%;">
          <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop" ></div>
          <div class="col-10"><h4 class="textContenu text-uppercase" >VOULEZ-VOUS Afficher/Masquer CEt ARTICLE?</h4></div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <div class=" col d-flex justify-content-center buttonsPopup" >
            <div class="col-auto nbr-dossier " >
              <table>
                <tr>
                  <td class="tdButt1 ">
                    <button class="btn-valider" (click)="showAndHideArticle()"  data-dismiss="modal" data-toggle="modal" data-target="#deleted" ><img src="assets/valider.png" class="iconBoutton" >Enregistrer </button>
                  </td>
                  <td  class="tdButt2 " >
                    <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton" >Annuler  </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>      </div>
      </div>
    </div>  </div>
</div>

<div class="modal fade" id="myModalSuppMedAcc" tabindex="-1" role="dialog"
     aria-labelledby="myModal6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan" >
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut text-uppercase">
            Archiver ARTICLE
        </h3>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-left: 6%;">
          <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop" ></div>
          <div class="col-10"><h4 class="textContenu text-uppercase" >VOULEZ-VOUS Archiver CEt article?</h4></div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <div class=" col d-flex justify-content-center buttonsPopup" >
            <div class="col-auto nbr-dossier " >
              <table>
                <tr>
                  <td class="tdButt1 ">
                    <button class="btn-valider" (click)="removeArtcile()"  data-dismiss="modal" data-toggle="modal" data-target="#deleted" ><img src="assets/delete2.png" class="iconBoutton" >Archiver </button>
                  </td>
                  <td  class="tdButt2 " >
                    <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton" >Annuler  </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>      </div>
      </div>
    </div>  </div>
</div>


<div class="modal fade" id="myModelReset" tabindex="-1" role="dialog"
     aria-labelledby="myModal6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan" >
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut text-uppercase">
            Restaurer Article
        </h3>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-left: 6%;">
          <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop" ></div>
          <div class="col-10"><h4 class="textContenu text-uppercase" >VOULEZ-VOUS Restaurer CET Article?</h4></div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <div class=" col d-flex justify-content-center buttonsPopup" >
            <div class="col-auto nbr-dossier " >
              <table>
                <tr>
                  <td class="tdButt1 ">
                    <button class="btn-valider" (click)="restorerArticle()"  data-dismiss="modal" data-toggle="modal" data-target="#deleted" ><img src="assets/delete2.png" class="iconBoutton" >Restaurer </button>
                  </td>
                  <td  class="tdButt2 " >
                    <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton" >Annuler  </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>      </div>
      </div>
    </div>  </div>
</div>
<!---------------------------- Pop up accepter médecin ---------->
<!-- <div class="modal fade" id="myModalAcceptMedecin" tabindex="-1" role="dialog"
     aria-labelledby="myModal6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan" >
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut text-uppercase">
          accepter médecin
        </h3>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-left: 6%;">
          <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop" ></div>
          <div class="col-10"><h4 class="textContenu text-uppercase" >VOULEZ-VOUS accepter CE médecin?</h4></div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <div class=" col d-flex justify-content-center buttonsPopup" >
            <div class="col-auto nbr-dossier " >
              <table>
                <tr>
                  <td class="tdButt1 ">
                    <button class="btn-valider" (click)="accepteMedecin()" data-dismiss="modal" data-toggle="modal" data-target="#deleted" ><img src="assets/like.png" class="iconBoutton" >Accepter </button>
                  </td>
                  <td  class="tdButt2 " >
                    <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton" >Annuler  </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>      </div>
      </div>
    </div>  </div>
</div> -->
<!---------------------------- Pop up Paramètres du tableau Medecins ---------->
<!-- <div class="modal fade" id="myModal4" tabindex="-1" role="dialog"
     aria-labelledby="myModal4" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan" >
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut text-uppercase">
          Paramètres du tableau
        </h3>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-left: 21%;">
          <div class="col-12" >
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
              <label class="form-check-label" for="flexCheckDefault">
                Spécialité
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
              <label class="form-check-label" for="flexCheckChecked">
                Email
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked2" checked>
              <label class="form-check-label" for="flexCheckChecked2">
                Num d'inscription
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked3" checked>
              <label class="form-check-label" for="flexCheckChecked3">
                Diplôme
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>  </div>
</div> -->
