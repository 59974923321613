import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';

@Injectable()
export class PatientService {
    constructor(private http: HttpClient) {

    }
    editProfil(patient) {
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('id_token_adminMedivisto'),
            'Content-Type': 'application/json'
        });
        return this.http.put(_getURL(API_CONFIG.patients), JSON.stringify(patient), { headers: headers })

    }
    verifyPassword(id, patient) {
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('id_token_adminMedivisto'),
            'Content-Type': 'application/json'
        });
        return this.http.post(_getURL(API_CONFIG.patients) + '/verified/' + id, JSON.stringify(patient), { headers: headers })


    }
    saveAllPatientsMedecin(data) {
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('id_token_adminMedivisto'),
            'Content-Type': 'application/json'
        });
        return this.http.post(_getURL(API_CONFIG.patients) + '/save/all/patients', JSON.stringify(data), { headers: headers })

    }
    getPatientByID(id) {
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('id_token_adminMedivisto'),
            'Content-Type': 'application/json'
        });
        return this.http.get(_getURL(API_CONFIG.patients) + '/' + id, { headers: headers })

    }
}