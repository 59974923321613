<div class="all" *ngIf="medicament" >
    <div class="row" style="margin-bottom: -18px;">
      <div class="col-12">
        <div class="row pat">
          <div class="col-12">
            <b style="text-transform: capitalize;">{{medicament.nom}}</b>
          </div>
        </div>
      </div>
    </div>
    <div class="informationPat2">
      <img src="assets/man.png" class="imgMed">
    </div>
    <div class="informationPat row">
      <div class="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12">
        <div class="row infoTitre">
          <div class=" infMed" >
            <b class="ficheNum">Informations : </b>
          </div>
        </div>
        <div class=" informationsPat">
    
            <span><b>Nom complet  : </b></span> <span style="text-transform: capitalize;">{{medicament.nom}}</span> <br>
            <span><b>Nom commercial  : </b></span> <span style="text-transform: capitalize;">{{medicament.nomCommercial}}</span> <br>
            <span><b>DCI : </b></span> <span style="text-transform: capitalize;">{{medicament.dci}}</span> <br>
            <span><b>Présentation : </b></span> <span>{{medicament.presentation}}</span> <br>
            <span><b>Forme : </b></span> <span>{{medicament.forme}}</span> <br>
            <span><b>Dosage : </b></span> <span>{{medicament.dosage}}</span> <br>
            <span><b>Spécification  : </b></span> <span>{{medicament.specification}}</span> <br>
            <span><b>Tableau  : </b></span> <span>{{medicament.tableau}}</span> <br>
            <span><b>Date  : </b></span> <span>{{medicament.created_at| date: 'dd/MM/yyyy'}}</span> <br>
        </div>
      </div>
      <div class="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12">
        <div class="row infoTitre">
          <div class=" infMed" >
            <b class="ficheNum"> </b>
          </div>
        </div>
        <div class=" informationsPat" style="position: relative;top: 33px;">
            <span><b>Laboratoire  : </b></span> <span>{{medicament.laboratoire}}</span> <br>
            <span><b>Famille  : </b></span> <span>{{medicament.famille}}</span> <br>
            <span><b>Composition  : </b></span> <span>{{medicament.composition}} </span> <br>
            <span><b>Classe Thérapeutique : </b></span> <span>{{medicament.classeTHerapeutique}}</span> <br>
            <span><b>Classe pharmacologique : </b></span> <span> {{medicament.classePharmacologique}}</span> <br>
        </div>
      </div>
    <div class="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <div class="row infoTitre">
        <div class=" infMed" >
          <b class="ficheNum"> </b>
        </div>
      </div>
      <div class=" informationsPat" style="position: relative;top: 33px;">
          <span><b>Sous classe  : </b></span> <span>{{medicament.sousClasse}}</span> <br>
          <span><b>Durée conservation  : </b></span> <span>{{medicament.dureeConservation}}</span> <br>
          <span><b>Prix : </b></span> <span>{{medicament.prix}}</span> <br>
          <span><b>Conditionnement : </b></span> <span>{{medicament.conditionnement}}</span> <br>
      </div>
    </div>
    </div>
    </div>
    