import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';
import { Observable } from 'rxjs';
const headers = new HttpHeaders({
    'Authorization': localStorage.getItem('id_token_adminMedivisto'),
    'Content-Type': 'application/json'
  });
@Injectable()
export class ArticleService {

    constructor(private http:HttpClient) { }

  
    addArticle(formData:FormData):Observable<any>
    {
      return this.http.post<any>(_getURL(API_CONFIG.article)+"addArticle",formData);
    }
  
    modifierArticle(formData:FormData,id:String):Observable<any>
    {
      return this.http.put<any>(_getURL(API_CONFIG.article)+"updateArticle"+'/'+id,formData);
    }
  
  
  
    listArticle():Observable<any[]>
    {
      return this.http.get<any[]>(_getURL(API_CONFIG.article)+"getAllArticle", { headers: headers });
    }
  
  
    deleteArticle(id:string)
    {
      return this.http.delete(_getURL(API_CONFIG.article)+"deleteArticle"+'/'+id, { headers: headers })
    }
    setVisibiliteArticle(id)
    {
      return this.http.post(_getURL(API_CONFIG.article)+"setVisibiliteArticle"+'/'+id, JSON.stringify({"id":id}), { headers: headers })
    }
  
    setArchiveArticle(id:string):Observable<any>
    {
      return this.http.get<any>(_getURL(API_CONFIG.article)+"setArticleArchive"+'/'+id, { headers: headers });
    }
    setArchiveArticleToFalse(id:string):Observable<any>
    {
      return this.http.get<any>(_getURL(API_CONFIG.article)+"setArticleArchivetoFalse"+'/'+id, { headers: headers });
    }
  
    listArticleArchives():Observable<any[]>
    {
      return this.http.get<any[]>(_getURL(API_CONFIG.article)+"getAllArticleArchived", { headers: headers });
    }
  
    listArticleNonArchive():Observable<any[]>
    {
      return this.http.get<any[]>(_getURL(API_CONFIG.article)+"getAllArticleNonArchived", { headers: headers });
    }
  
    findArticleById(id:String):Observable<any>
    {
      return this.http.get<any>(_getURL(API_CONFIG.article)+"findArticleById"+'/'+id, { headers: headers });
    }
  
  
  
  
  }
  
