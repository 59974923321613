import { Component, OnInit, EventEmitter,Output } from '@angular/core';
declare var $: any;
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { API_CONFIG } from 'config/API_CONFIG';
import { _getURL } from 'config/API_CONFIG';
import { EvenementService } from 'src/app/services/evenement/evenement.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { FileUploader } from 'ng2-file-upload';
import { uploadFolderRootEvenement } from 'config/API_CONFIG';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import 'tinymce';
import 'tinymce/themes/modern';

import 'tinymce/plugins/code';
import 'tinymce/plugins/lists';
declare var tinymce: any;

@Component({
  selector: 'app-update-evenements',
  templateUrl: './update-evenements.component.html',
  styleUrls: ['../../patients/ajouter-patients-med/ajouter-patients-med.component.css', '../../medecins/medecins/medecins.component.css'],
})
export class UpdateEvenementsComponent implements OnInit {
  admin: any;
  evenement: any;
  form: any;
  image: any;
  idEvent: any;
  errorDate = false;
  evenements: any[] = [];
  loading = false
  public uploader: FileUploader = new FileUploader({ url: _getURL(API_CONFIG.image) + '/adminEventPatient/' + this.idEvent });
  sizeLimitOne = 5000000; //5MB
  @Output() onEditorContentChange = new EventEmitter();
  errorDescription = false
  editor;
  successRegister:any
  constructor(private activatedRoute:ActivatedRoute,private notificationService: NotificationService, private evenementService: EvenementService, private authService: AuthService, private router: Router, private formBuilder: FormBuilder) {
    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if (!this.admin.roles.gestionEvenements) {
        this.router.navigate(['/notFound']);
        return false;
      }
    },
      err => {
        console.log(err);
        return false;
      });

    this.form = this.formBuilder.group({
      title: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(5000)]],
      theme: ['Cinéma', Validators.required],
      description: ['',],
      date: ['', Validators.required],
      dateFin: ['', Validators.required],
      lieu: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(5000)]],
      lienSite: [''],
      fraisInscription: [''],
      cible:['Patient',Validators.required]
    });
  }
  ngOnInit() {
    this.uploader.onBeforeUploadItem = (item) => {
        item.withCredentials = false;
      }
      tinymce.init({
        selector: '#myTextArea',
        skin_url: '/assets/skins/lightgray',
        height: 350,
        width: 1095,
        resize: false,
        theme: 'modern',
        plugins: ['code', 'lists'],

        mobile: {
          theme: 'mobile',
          plugins: ['autosave', 'lists'],
          toolbar: ['undo', 'bold', 'italic', 'styleselect', 'checklist']
        },
        content_css: [
          '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
          '//www.tinymce.com/css/codepen.min.css'],
        paste_data_images: true,
        setup: editor => {
          this.editor = editor;
          editor.on('keyup change', () => {
            const content = editor.getContent();
            this.onEditorContentChange.emit(content);
          });
        }
      });
      let params: any = this.activatedRoute.snapshot.params;
      this.evenementService.getMEvenementById(params.id).subscribe(evenement => {
        this.evenement = evenement;
        this.evenement.image = this.evenement.image.replace("https://www.file.medivisto.com",_getURL('').replace("api","")+ 'data/files')
        if(this.evenement && this.evenement.patientEvenement){
          this.evenement.cible = "Patient"
        }
        if(this.evenement && !this.evenement.patientEvenement){
          this.evenement.cible = "Médecin"
        }
        if(this.evenement && this.evenement.doubleEvenement){
          this.evenement.cible = "Les deux"
        }
        if(this.evenement){
          tinymce.get('myTextArea').setContent(this.evenement.description)
        
        }
      },
        err => {
          console.log(err);
          return false;
        });
  }
  ngOnDestroy() {
    tinymce.remove(this.editor);
  }
  saveSujet(evenement) {
    evenement.doubleEvenement = false;
    evenement.patientEvenement = false;
    evenement.description = tinymce.get('myTextArea').getContent()
    if(!evenement.description || evenement.description == "" || (evenement.description && (evenement.description.length < 10 || evenement.description.length > 2000))){
      this.errorDescription = true
      return false;
    }
    this.errorDescription = false
    if(evenement.cible === "Les deux"){
      evenement.doubleEvenement = true;
    }
    if(evenement.cible === "Patient"){
      evenement.patientEvenement = true;
    }
    if(evenement.cible === "Médecin"){
      evenement.patientEvenement = false;
    }
    this.errorDate = false;
    if (evenement.date >= evenement.dateFin) {
      this.errorDate = true;
      return false;
    }
    this.errorDate = false;
    const file = this.uploader.queue[0]
    if (file) {
      if (file._file.type != 'image/png' && file._file.type != 'image/jpg' && file._file.type != 'image/jpeg') {
        this.uploader.queue = [];
        alert("Impossible de télécharger ce fichier.")
        return false;
      }
      if (file._file.size > this.sizeLimitOne) {
        this.uploader.queue = [];
        alert("Ce fichier sélectionné est trop volumineux.La taille maximale est 5 Mo.")
        return false;
      }
    }
    evenement.accepted = true;
    this.loading = true;
    evenement.show = true;
    this.evenementService.editEvenement(evenement)
      .subscribe((data:any) => {
        this.evenements.push(data);
        this.uploader.setOptions({ url: _getURL(API_CONFIG.image) + '/adminEventPatient/' + data._id });
        this.uploader.uploadAll();
        this.form.reset();
      });
      setTimeout(() => {
        Swal.fire( {
            width: 500,
            background: '#e1e1e1',
            timer: 3000,
            html: '<div class="row">' +
              '<div class="col-3">' +
              '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
              '</div>' +
              '<div><h4 class="text-uppercase" style="margin-top: 23px; font-size:16px">Évenement a été MODIFIÉ avec succès</h4></div>' +
              '</div>',
            showConfirmButton: false
          }
        );
        this.loading = false;
        this.router.navigate(['/events']);
      }, 5000);
  }
  deleteLogo(evenement){
  if (window.confirm("Voulez vous vraiment supprimer le logo?")) {
    evenement.image = null
   }
}
}

