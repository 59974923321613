import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import {ConseilService} from 'src/app/services/conseil/conseil.service';
import { _getURL } from 'config/API_CONFIG';

@Component({
  selector: 'app-details-conseil',
  templateUrl: './details-conseil.component.html',
  styleUrls: ['../../medecins/medecins/medecins.component.css'],
})
export class DetailsConseilComponent implements OnInit {

  admin: any;
  conseil: any;

  constructor(private conseilService:ConseilService, private authService: AuthService, private activatedRoute: ActivatedRoute,
    private router: Router) {

    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if (!this.admin.roles.gestionConseils) {
        this.router.navigate(['/notFound']);
        return false;
      }
    },
      err => {
        console.log(err);
        return false;
      });
  }
  ngOnInit() {
    let el = document.getElementById('scrolltoverify');
    el.scrollIntoView({ behavior: "smooth" });
    let params: any = this.activatedRoute.snapshot.params;
    this.conseilService.getConseilById(params.id).subscribe((conseil:any) => {
      if (conseil && conseil.image && conseil.image.indexOf("https://www.file.medivisto.com") > -1) {
        conseil.image = conseil.image.replace("https://www.file.medivisto.com", _getURL('').replace("api", "") + 'data/files')
      }
      if (conseil && conseil.image && conseil.image.indexOf("https://www.file.medivisto.com") < 0) {
        conseil.image = _getURL('').replace("api", "") + 'data/files/conseil/' + conseil.image
      }
      this.conseil = conseil;
    },
      err => {
        console.log(err);
        return false;
      });
  }
}