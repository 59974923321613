<!------------------ Liste des médicaments ----------------------->
<div class="informationPat3" *ngIf="showPart()==1">
    <div class="row bar">
      <div class="col-10 col-xl-4 col-lg-4 col-md-10 col-sm-10 bar-section"><a class="linkPart2"><b>Liste des médicaments</b></a></div>
      <div class="col-10 col-xl-4 col-lg-4 col-md-10 col-sm-10 bar-section" (click)="modifPart2()"><a class="linkPart"><b>Liste des nouveaux médicaments</b></a></div>
    </div>
    <div class=" search4">
      <div class="row cover-search-row2">
        <div class="col-lg-5 col-md-8 col-sm-10 col-xs-10" style="margin-right: 4%">
          <div class="row rowSearch2">
            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 name-col-10">
              <input type="text" [(ngModel)]="search" name="search" class="inputRecherche" placeholder="Rechercher">
            </div>
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 name-col-1" style="">
              <img id="name_img" src="" alt="" style="height: 25px;   margin-top: 19px; margin-left: -8px">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="div-tab2">
      <div class="row" style="overflow-x:auto;">
        <table class="table  ">
          <thead>
            <th id="firstThTable" class=" gauche text-uppercase"><b>Nom</b></th>
            <th class=" gauche text-uppercase"><b>Nom commercial</b></th>
            <th class=" gauche text-uppercase"><b>dci</b></th>
            <th class=" gauche text-uppercase"><b>présentation</b></th>
            <th class="  gauche text-uppercase" style="width: 20%;margin-right: 26px"><b>PARAMÈTRES</b></th>
          </thead>
          <tbody>
            <tr class=" gauche" *ngFor="let medicament of medicaments| paginate: { itemsPerPage: 25, currentPage: ddd, id: 'ddd' }|searchingMedicaments : search.toString().toLowerCase()">
              <td class=" gauche" [routerLink]="['/medicament/details',medicament._id]" style="text-transform: capitalize;">{{medicament.nom}}</td>
              <td class=" gauche" [routerLink]="['/medicament/details',medicament._id]" style="text-transform: capitalize;">{{medicament.nomCommercial}}</td>
              <td class=" gauche" [routerLink]="['/medicament/details',medicament._id]" style="text-transform: capitalize;">{{medicament.dci}}</td>
              <td class=" gauche" [routerLink]="['/medicament/details',medicament._id]">{{medicament.presentation}}</td>
              <td class="row gauche" style="border-bottom-style: none;">
                <div class="col-1" style="margin-right: 10px !important;">
                  <a [routerLink]="['/medicament/details',medicament._id]" title="Détails">
                    <img src="assets/voirDetails-7.png" class="iconParametres"></a>
                </div>
                <div class="col-1">
                  <a (click)="setVariableTodelete(medicament)" title="Supprimer" data-toggle="modal" data-target="#myModal3"><img src="assets/delete-7.png" class="iconParametres"></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row" *ngIf="medicaments && medicaments.length > 25">
          <div class="col col-xs-6">
            <pagination-controls (pageChange)="ddd = $event" id="ddd"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  
  </div>
  <!------------------ Liste des nouveaux médicaments ----------------------->
  <div class="informationPat3" *ngIf="showPart()==2">
    <div class="row bar">
      <div class="col-10 col-xl-4 col-lg-4 col-md-10 col-sm-10 bar-section" (click)="modifPart1()"><a class="linkPart"><b>Liste des médicaments</b></a></div>
      <div class="col-10 col-xl-4 col-lg-4 col-md-10 col-sm-10 bar-section"><a class="linkPart2"><b>Liste des nouveaux médicaments</b></a></div>
    </div>
    <div class=" search4">
      <div class="row cover-search-row2">
        <div class="col-lg-5 col-md-8 col-sm-10 col-xs-10" style="margin-right: 4%">
          <div class="row rowSearch2">
            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 name-col-10">
              <input type="text" [(ngModel)]="searchSecond" name="searchSecond" class="inputRecherche" placeholder="Rechercher">
            </div>
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 name-col-1" style="">
              <img id="name_img" src="" alt="" style="height: 25px;   margin-top: 19px; margin-left: -8px">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="div-tab2">
      <div class="row" style="overflow-x:auto;">
        <table class="table  ">
          <thead>
            <th id="firstThTable" class=" gauche text-uppercase"><b>nom</b></th>
            <th class=" gauche text-uppercase"><b>Date de création</b></th>
            <th class="  gauche text-uppercase" style="width: 20%;margin-right: 26px"><b>PARAMÈTRES</b></th>
          </thead>
          <tbody>
            <tr class=" gauche" *ngFor="let medicament of newMedicaments| paginate: { itemsPerPage: 25, currentPage: dd, id: 'dd' }|searchingMedicaments : searchSecond.toString().toLowerCase()">
              <td class=" gauche" style="text-transform: capitalize;">{{medicament.nom}}</td>
              <td class=" gauche">{{medicament.created_at | date: 'dd/MM/yyyy à HH:mm' }}</td>
              <td class="row gauche" style="border-bottom-style: none;">
                <div class="col-1" style="margin-right: 10px !important;">
                  <a (click)="setVariableTodelete(medicament)" title="Enregistrer" data-toggle="modal" data-target="#myModal2">
                    <img src="assets/checked.png" class="iconParametres"></a>
                </div>
                <div class="col-1">
                  <a (click)="setVariableTodelete(medicament)" title="Supprimer" data-toggle="modal" data-target="#myModal33"><img src="assets/delete-7.png" class="iconParametres"></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row" *ngIf="newMedicaments && newMedicaments.length > 25">
          <div class="col col-xs-6">
            <pagination-controls (pageChange)="dd = $event" id="dd"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---------------------------- Pop up supprimer médicament ---------->
  <div class="modal fade" id="myModal3" tabindex="-1" role="dialog" aria-labelledby="myModal6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-scan">
        <div class="modal-header d-flex justify-content-center">
          <h3 class="titre-modal-statut text-uppercase">
            SUPPRIMER médicament
          </h3>
        </div>
        <div class="modal-body">
          <div class="row" style="margin-left: 6%;">
            <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop"></div>
            <div class="col-10">
              <h4 class="textContenu text-uppercase">VOULEZ-VOUS SUPPRIMER CE médicament?</h4>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center buttonsPopup">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td class="tdButt1 ">
                      <button class="btn-valider" (click)="removemedicament(medicament)" title="Supprimer" data-dismiss="modal" data-toggle="modal" data-target="#deleted"><img src="assets/delete2.png" class="iconBoutton">Supprimer </button>
                    </td>
                    <td class="tdButt2 ">
                      <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton">Annuler </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!---------------------------- Pop up supprimer médicament ---------->
  <div class="modal fade" id="myModal33" tabindex="-1" role="dialog" aria-labelledby="myModal33" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-scan">
        <div class="modal-header d-flex justify-content-center">
          <h3 class="titre-modal-statut text-uppercase">
            SUPPRIMER médicament rr
          </h3>
        </div>
        <div class="modal-body">
          <div class="row" style="margin-left: 6%;">
            <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop"></div>
            <div class="col-10">
              <h4 class="textContenu text-uppercase">VOULEZ-VOUS SUPPRIMER CE médicament?</h4>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center buttonsPopup">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td class="tdButt1 ">
                      <button class="btn-valider" (click)="removeNewmedicament(medicament)" title="Supprimer" data-dismiss="modal" data-toggle="modal" data-target="#deleted"><img src="assets/delete2.png" class="iconBoutton">Supprimer </button>
                    </td>
                    <td class="tdButt2 ">
                      <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton">Annuler </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---------------------------- Pop up Enregistrer médicament ---------->
  <div class="modal fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModal6" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-scan">
        <div class="modal-header d-flex justify-content-center">
          <h3 class="titre-modal-statut text-uppercase">
            Enregistrer médicament
          </h3>
        </div>
        <div class="modal-body">
          <div class="row" style="margin-left: 6%;">
            <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop"></div>
            <div class="col-10">
              <h4 class="textContenu text-uppercase">VOULEZ-VOUS Enregistrer CE médicament?</h4>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center buttonsPopup">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td class="tdButt1 ">
                      <button class="btn-valider" (click)="addMedicaments(medicament)" data-dismiss="modal" data-toggle="modal" data-target="#deleted"><img src="assets/valider.png" class="iconBoutton">Enregistrer </button>
                    </td>
                    <td class="tdButt2 ">
                      <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton">Annuler </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  