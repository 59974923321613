import { Component, OnInit } from '@angular/core';
declare var $: any;
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AdminService } from 'src/app/services/admin/admin.service';
import { MedecinService } from 'src/app/services/medecin/medecin.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { rootModelsFichierPatients } from '../../../../../../config/API_CONFIG';
import * as XLSX from "xlsx";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
@Component({
  selector: 'app-ajouter-patients-med',
  templateUrl: './ajouter-patients-med.component.html',
  styleUrls: ['./ajouter-patients-med.component.css','../../medecins/medecins/medecins.component.css']
})
export class AjouterPatientsMedComponent implements OnInit {

  form: any;
  public search: string = '';
  admin: any;
  medecins: any[] = [];
  showFilesInput = false;
  selectedMedecin: any;
  fileToChoose: any;
  sizeLimitOne = 10000000; //10MB
  loading = false;
  patients: any[] = [];
  allpatients: any[] = [];
  successAdd = false;
  errorLigne: any;
  constructor(private patientService: PatientService, private medecinService: MedecinService, private adminService: AdminService,  private authService: AuthService,
    private router: Router, private formBuilder: FormBuilder) {
    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if (!this.admin.roles.gestionMedecins) {
        this.router.navigate(['/notFound']);
        return false;
      }
    },
      err => {
        console.log(err);
        return false;
      });
  }
  ngOnInit() {
    let el = document.getElementById('scrolltoverify');
    el.scrollIntoView({ behavior: "smooth" });
    this.loading = true;
    this.form = this.formBuilder.group({
      medecin: ['', Validators.required],
      fileToChoose: ['', Validators.required],
    })
    this.medecinService.getMedecinAccepted().subscribe((medecins:any[]) => {
      this.medecins = medecins;
    },
      err => {
        return false;
      });
      this.medecinService.getAllPatients().subscribe((patients:any[]) => {
        this.allpatients = patients;
        this.loading = false;
      },
        err => {
          return false;
        });
  }
  selectMedecin() {
    if (this.form.value.medecin) {
      this.selectedMedecin = this.form.value.medecin
      this.showFilesInput = true;
    } else {
      this.showFilesInput = false;
    }
  }
  // get data
  onFileChange(file: any) {
    // Vider le tableau et réinitialiser l'affichage
    /* wire up file reader */
    this.errorLigne = " "
    this.patients = []
    var myfile = file.target.files.item(0)
    if (myfile) {
      if (!myfile.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.ms-excel')) {
        file = undefined
        this.fileToChoose = undefined
        Swal.fire( {
        width: 500,
        background: '#e1e1e1',
        timer: 3000,
        html: '<div class="row">' +
          '<div class="col-3">' +
          '<img src="assets/error-4.png" style="height:60px; margin-left: 20px">' +
          '</div>' +
          '<div><h4 class="text-uppercase" style="margin-top: 23px; font-size:16px">Impossible de télécharger ce fichier !</h4></div>' +
          '</div>',
        showConfirmButton: false
      }
    );
        return false;
      }
      if (myfile.size > this.sizeLimitOne) {
        file = undefined
        this.fileToChoose = undefined
        Swal.fire( {
        width: 500,
        background: '#e1e1e1',
        timer: 3000,
        html: '<div class="row">' +
          '<div class="col-3">' +
          '<img src="assets/error-4.png" style="height:60px; margin-left: 20px">' +
          '</div>' +
          '<div><h4 class="text-uppercase" style="margin-top: 23px; font-size:16px">Un fichier sélectionné est trop volumineux.La taille maximale est 10 Mo !</h4></div>' +
          '</div>',
        showConfirmButton: false
      }
    );
        return false;
      }
      this.loading = true;
      const target: DataTransfer = <DataTransfer>(file.target);
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        var data = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
        for (var i = 1; i < data.length; i++) {
          if(data[i][0] !="" && data[i][0] != undefined && data[i][1] !="" && data[i][1] != undefined && data[i][2] !="" && data[i][2] != undefined){
          let newPatient = {
            "prenom": data[i][0],
            "nom": data[i][1],
            "numFiche": data[i][2],
            "convention": data[i][3],
            "medecinId": this.form.value.medecin._id
          }
          this.patients.push(newPatient)
           }
        }
        if (this.patients.length > 0) {
          for (var j = 0; j < this.patients.length; j++) {
            let occurrence = this.getNbOccur(this.patients[j], this.patients)
            if (occurrence > 1) {
              Swal.fire( {
              width: 500,
              background: '#e1e1e1',
              timer: 3000,
              html: '<div class="row">' +
                '<div class="col-3">' +
                '<img src="assets/error-4.png" style="height:60px; margin-left: 20px">' +
                '</div>' +
                '<div><h4 class="text-uppercase" style="margin-top: 23px; font-size:16px">Le patient N° '+ Number(j + 2) +' apparait plusieurs fois !</h4></div>' +
                '</div>',
              showConfirmButton: false
            }
          );
              this.loading = false;
              this.showFilesInput = false;
              this.form.reset()
              return false;
            }
          }
        }
        this.errorLigne = " "
        this.loading = false;
      };
      if (target.files.length > 0) {
        reader.readAsBinaryString(target.files[0]);
      }
    } else {
      return false;
    }

  }
  savePatients() {
    this.successAdd = false;
      if (this.patients.length > 0) {
      //  this.loading = true;
        for (var j = 0; j < this.patients.length; j++) {
          let occurrence = this.getNbOccur(this.patients[j], this.allpatients)
          if (occurrence > 0) {
            Swal.fire( {
            width: 500,
            background: '#e1e1e1',
            timer: 3000,
            html: '<div class="row">' +
              '<div class="col-3">' +
              '<img src="assets/error-4.png" style="height:60px; margin-left: 20px">' +
              '</div>' +
              '<div><h4 class="text-uppercase" style="margin-top: 23px; font-size:16px">Le ligne '+ Number(j + 2) +' existe dèjà !</h4></div>' +
              '</div>',
            showConfirmButton: false
          }
        );
            this.loading = false;
            this.showFilesInput = false;
            this.form.reset()
            return false;
          }
        }
        this.patientService.saveAllPatientsMedecin(this.patients).subscribe(medecin => {
          this.loading = false;
          this.form.reset();
          Swal.fire( {
        width: 500,
        background: '#e1e1e1',
        timer: 3000,
        html: '<div class="row">' +
          '<div class="col-3">' +
          '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
          '</div>' +
          '<div><h4 class="text-uppercase" style="margin-top: 23px; font-size:16px">La liste a été AJOUTÉE avec succès</h4></div>' +
          '</div>',
        showConfirmButton: false
      }
    );
        });
      }

  }
  getNbOccur(obj, arr) {
    var occurs = 0;
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].prenom.toLowerCase() === obj.prenom.toLowerCase() && arr[i].nom.toLowerCase() === obj.nom.toLowerCase() && arr[i].numFiche == obj.numFiche && arr[i].medecinId === obj.medecinId) occurs++;
    }
    return occurs;
  }
  gotoExel(){
    window.location.href = rootModelsFichierPatients
  }

}

