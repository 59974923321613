<!--Réglage de la fenetre  -->
<div class="reglage" *ngIf="evenement" id="scrolltoverify">
    <div class="container">
      <div class="row profilMedecin" >
          <img src="assets/img/evenements.png" class="img-profile-medecin" *ngIf="!evenement.image">
          <img src="{{evenement.image}}" class="img-profile-medecin" *ngIf="evenement.image">
      </div>
      <br><br>
      <div class="col-md-12">
        <ul class="ulInformations">
          <li><p>Sujet : {{evenement.title}} </p></li>
          <li><p>Thème : {{evenement.theme}} </p></li>
          <li >Description : <div [innerHTML]="evenement.description" ></div></li>
          <li><p>Lieu : {{evenement.lieu}} </p></li>
          <li><p>Date : {{evenement.date| date: 'dd/MM/yyyy à HH:mm'}} </p></li>
          <li><p>Lien d'inscription : <a href="{{evenement.lienSite}}" *ngIf="evenement.lienSite">Cliquer ici !</a></p></li>
          <li><p>Frais d'inscription : {{evenement.fraisInscription}} </p></li>
  
        </ul>
  
      </div>
    </div>
  </div>
  