import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';

@Injectable()
export class NotificationService {
    constructor(private http: HttpClient) {

    }
    //get  8 last  Notifications by admin
    getNotificationsByAdmin() {
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('id_token_adminMedivisto'),
            'Content-Type': 'application/json'
          });
        return this.http.get(_getURL(API_CONFIG.notification) + '/admin/', { headers: headers })
            
    }
    //get  all  Notifications by admin

    getNotificationsAllByAdmin() {
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('id_token_adminMedivisto'),
            'Content-Type': 'application/json'
          });
        return this.http.get(_getURL(API_CONFIG.notification) + '/admin/all', { headers: headers })
            
    }
    addNotification(newNotification) {
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('id_token_adminMedivisto'),
            'Content-Type': 'application/json'
          });
        return this.http.post(_getURL(API_CONFIG.notification), newNotification, { headers: headers })
            
    }
    editNotification(notification) {
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('id_token_adminMedivisto'),
            'Content-Type': 'application/json'
          });
        return this.http.put(_getURL(API_CONFIG.notification) + '/' + notification._id, notification, { headers: headers })
            
    }
}
