import { Component, OnInit } from '@angular/core';
import {Chart} from 'chart.js';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  showMyContainer = 1;

  constructor() { }

  async  ngOnInit() {
    /* bar chart */
    const myChart = new Chart('myChart' , {
      type: 'bar',
      data: {
        labels: ['Cardiologue', 'Généraliste', 'Dermatologue', ' Gynécologue', ' Neurolog', ' Pédiatre', 'Cardiologue', 'Généraliste', 'Dermatologue', ' Gynécologue', ' Neurolog', ' Pédiatre'],
        datasets: [{
          label: 'Nombre total des médecins',
          data: [12, 19, 3, 5, 0, 3, 8, 15, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }]
      }
    });
    /* line chart */
    const myChart2 = new Chart('myChart2' , {
      type: 'line',
      data: {
        labels: ['Janvier', 'Fevrier', 'Mars', ' Avril', ' Mai', ' Juin', 'Juillet', 'Aout', 'Septembre', ' Octobre', ' Novembre', ' Decembre'],
        datasets: [{
          label: 'Nombre total des médecins',
          data: [12, 19, 3, 5, 0, 3, 8, 15, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        },{
          label: 'Nombre total des patients',
          data: [2, 25, 7, 2, 8, 9, 5, 10, 6, 7, 3, 1],
          backgroundColor: [
            'rgba(255, 206, 86, 0.2)'
          ],
          borderColor: [
            'rgba(255, 206, 86, 1)'
          ],
          borderWidth: 1
        }]
      },

    });

  }
  modifPart1() {
    if (this.showMyContainer !== 1)
    {
      this.showMyContainer = 1;
    }
    else {
      this.showMyContainer = 0;
    }
  }

  modifPart2(){
    if (this.showMyContainer !== 2)
    {
      this.showMyContainer = 2;
    }
    else {
      this.showMyContainer = 0;
    }  }
  modifPart3(){
    if (this.showMyContainer !== 3)
    {
      this.showMyContainer = 3;
    }
    else {
      this.showMyContainer = 0;
    }  }
  modifPart4(){
    if (this.showMyContainer !== 4)
    {
      this.showMyContainer = 4;
    }
    else {
      this.showMyContainer = 0;
    }  }
  modifPart5(){
    if (this.showMyContainer !== 5)
    {
      this.showMyContainer = 5;
    }
    else {
      this.showMyContainer = 0;
    }  }
  modifPart6(){
    if (this.showMyContainer !== 6)
    {
      this.showMyContainer = 6;
    }
    else {
      this.showMyContainer = 0;
    }  }
  modifPart7(){
    if (this.showMyContainer !== 7)
    {
      this.showMyContainer = 7;
    }
    else {
      this.showMyContainer = 0;
    }  }
  showPart() {
    return this.showMyContainer;
  }
  scroll(id) {
   // console.log(`scrolling to ${id}`);
    const el = document.getElementById(id);
    el.scrollIntoView();
  }
}

