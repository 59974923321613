import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';
@Injectable()
export class AdminService{

  constructor(private http:HttpClient){
  }
getAdminById(id){
  const headers = new HttpHeaders({
    'Authorization': localStorage.getItem('id_token_adminMedivisto'),
    'Content-Type': 'application/json'
  });
return this.http.get(_getURL(API_CONFIG.admin)+'/'+id,{headers: headers})
    
}
  getAllEvenementsPatient(){
    return this.http.get(_getURL(API_CONFIG.evenement)+'/patient')
        
  }
getAllSousAdmin(){
  const headers = new HttpHeaders({
    'Authorization': localStorage.getItem('id_token_adminMedivisto'),
    'Content-Type': 'application/json'
  });
return this.http.get(_getURL(API_CONFIG.admin)+'/sousAdmin',{headers: headers})
    
}
// verify existance d'un mail
verifyEmail(admin){
  const headers = new HttpHeaders({
    'Authorization': localStorage.getItem('id_token_adminMedivisto'),
    'Content-Type': 'application/json'
  });
return this.http.post(_getURL(API_CONFIG.admin)+'/verifiedEmail', admin, {headers: headers})
    
}
  registerAdmin(newAdmin){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
      return this.http.post(_getURL(API_CONFIG.admin), newAdmin, {headers: headers})
          
  }
  editEvenement(evenement){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.evenement)+'/'+evenement._id, evenement, {headers: headers})
        
  }
  editAdmin(admin){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
  return this.http.put(_getURL(API_CONFIG.admin)+'/'+admin._id, admin, {headers: headers})
      
  }
  verifyPassword(id,admin){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
  return this.http.post(_getURL(API_CONFIG.admin)+'/verified/'+id, admin, {headers: headers})
      
  }

  editProfil(admin){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
      return this.http.put(_getURL(API_CONFIG.admin)+'/password/'+admin._id, admin, {headers: headers})
          
  }
  recoverPassword(admin){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  return this.http.post(_getURL(API_CONFIG.admin)+'/forgot', admin, {headers: headers})
      
  }

  verifytoken(admin,token){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  return this.http.put(_getURL(API_CONFIG.admin)+'/reset/'+token, admin, {headers: headers})
      
  }
  }
