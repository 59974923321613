<div id="scrolltoverify">

</div>
<!--Réglage de la fenetre  -->
<div class="reglage" *ngIf="conseil" >
  <div class="container">
    <div class="row profilMedecin" >
        <img src="assets/img/forum.jpeg" style="width: 30%;margin: auto;" class="img-profile-medecin" *ngIf="!conseil.image">
        <img src="{{conseil.image}}" style="width: 30%;margin: auto;" class="img-profile-medecin" *ngIf="conseil.image">
    </div>
    <br><br>
    <div class="col-md-12">
      <ul class="ulInformations">
        <li><p style="text-transform: capitalize;">Sujet : {{conseil.titre}} </p></li>
        <li><p style="text-transform: capitalize;">Théme : {{conseil.categorie}} </p></li>
        <li><p>Description : {{conseil.description}} </p></li>
        <li><p>Date : {{conseil.created_at| date: 'dd/MM/yyyy'}}</p></li>
      </ul>

    </div>
  </div>
</div>
