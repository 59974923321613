import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
declare var $: any;
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder,Validators } from '@angular/forms';
import { AdminService } from 'src/app/services/admin/admin.service';
import { ValidationService } from 'src/app/services/validate/validateError.service';
import { ValidateService } from 'src/app/services/validate/validate.service';
import { MedecinService } from 'src/app/services/medecin/medecin.service';
import { pays } from '../../pays/pays';
import { tri } from '../../pays/pays';
import { villes } from '../../pays/villes';
import { codepostale } from '../../pays/villes';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
@Component({
  selector: 'app-update-medecin',
  templateUrl: './update-medecin.component.html',
  styleUrls: ['../medecins/medecins.component.css','../ajout-medecin/ajout-medecin.component.scss']
})
export class UpdateMedecinComponent implements OnInit {

  form: any;
  admin: any;
  pays: any[] = [];
  errorNumeroInscription: any;
  successRegister: any;
  medecins: any[] = [];
  medecin: any;
  numInscription: any;
  selectedpays = "Tunisie"
  selectedville = ''
  listePostal = [ ];
  listefiltrecode = ['2001','2002','2020','2022', '2027', '2032', '2035', '2036', '2037', '2041', '2056', '2057', '2058',  '2061', '2073', '2080', '2081', '2083', '2088', '2091', '2094','2095']
  listeVilles:any[]=[];
  isTunisie = true
  listefiltreville = [ ];
  selectedpostal = ''
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {}
  showerrorligne = ""
  today = new Date().toISOString().split('T')[0]
  constructor(private validateService:ValidateService,private medecinService: MedecinService, private adminService: AdminService,  private authService: AuthService, location: Location, private activatedRoute: ActivatedRoute,
    private router: Router, private formBuilder: FormBuilder) {
    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if (!this.admin.roles.gestionMedecins) {
        this.router.navigate(['/notFound']);
        return false;
      }
    },
      err => {
        return false;
      });
  }
  ngOnInit() {
    let el = document.getElementById('scrolltoverify');
    el.scrollIntoView({ behavior: "smooth" });
    this.pays = pays.sort(tri);
    this.errorNumeroInscription = false;
    this.successRegister = false;
    this.form = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      prenom: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      email: ['', [Validators.required, ValidationService.emailValidator, Validators.maxLength(50)]],
      numInscription: ['', [Validators.required, ValidationService.numberValidator, Validators.maxLength(25)]],
      tel: ['+216', [Validators.required, ValidationService.phonenumber]],
      telFixe: ['+216'],
      telPerso: ['+216'],
      specialite: ['', Validators.required],
      dateAnniversaire: ['', [ValidationService.dateValidator]],
      sexe: ['Masculin'],
      adresseCabinet: this.formBuilder.group({
        adresse: ['', [Validators.required, Validators.minLength(3),Validators.maxLength(500)]],
        ville: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
        pays: ['Tunisie', Validators.required],
        codePostal: ['', [Validators.required, ValidationService.numberValidator, Validators.maxLength(4)]],
      }),
      diplome: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      codeCNAM: ['', [ValidationService.numberValidator, Validators.maxLength(25)]],
      centreCNAM: ['', Validators.maxLength(25)],
      dateDebutFonction: [''],
      languesParlees: ['', []],
      dateInscription: [''],
      notes: ['', Validators.maxLength(1000)],
    })
    this.dropdownList = [{ "id": 1, "itemName": "Allemand" },
    { "id": 2, "itemName": "Arabe" },
    { "id": 3, "itemName": "Anglais" },
    { "id": 4, "itemName": "Français" },
    { "id": 5, "itemName": "Italien" },
    ];
    this.dropdownSettings = {
      singleSelection: false,
      text: "Choisissez langues ",
      selectAllText: 'Sélectionnez tous',
      unSelectAllText: 'Désélectionne tous',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };
    let params: any = this.activatedRoute.snapshot.params;
    this.medecinService.getMedecinById(params.id).subscribe(medecin => {
      this.medecin = medecin;
      if (this.medecin) {
        this.numInscription = this.medecin.numInscription
        if( this.medecin.dateInscription &&  this.medecin.dateInscription != null &&  this.medecin.dateInscription != undefined &&  this.medecin.dateInscription != ""){
          this.medecin.dateInscription = new Date(this.medecin.dateInscription).toISOString().substring(0, 10);
        }
          if(this.medecin.tel && this.medecin.tel.indexOf("+") === -1){
        this.medecin.tel = '+' + this.medecin.tel
      }
        if(this.medecin.telFixe && this.medecin.telFixe.indexOf("+") === -1){
        this.medecin.telFixe = '+' + this.medecin.telFixe
        }
        if(this.medecin.telPerso && this.medecin.telPerso.indexOf("+") === -1){
          this.medecin.telPerso = '+' + this.medecin.telPerso
        }
        this.form.controls['adresseCabinet'].controls['ville'].setValue(this.medecin.adresseCabinet.ville);
        this.form.controls['adresseCabinet'].controls['codePostal'].setValue(this.medecin.adresseCabinet.codePostal);
      }
    },
      err => {
        return false;
      });
  }
  onItemSelect(item: any) {

  }
  OnItemDeSelect(item: any) {

  }
  onSelectAll(items: any) {

  }
  onDeSelectAll(items: any) {

  }
  onSelectville(selectedville){
    this.listePostal = codepostale.filter(x => x.ville == selectedville);
    this.listePostal.forEach(index => this.listefiltrecode = index.code);
  }

  onSelectpays(selectedpays){
    if(selectedpays === 'Tunisie' || selectedpays === ''){
      this.isTunisie = true;
      this.listeVilles = villes.filter(x => x.pays == selectedpays);
      this.listeVilles.forEach(index =>
        this.listefiltreville = index.ville);
    } else {
      this.selectedville = undefined
      this.selectedpostal = undefined
      this.form.controls['adresseCabinet'].controls['ville'].setValue('');
      this.form.controls['adresseCabinet'].controls['codePostal'].setValue('');
      this.isTunisie = false;
    }
  }
  // enregistrer un médecin
  onRegisterMedecin(medecin) {
    if(!medecin.nom || medecin.nom.length < 3 || medecin.nom.length > 25 || !medecin.prenom || medecin.prenom.length < 3 || medecin.prenom.length > 25){
      this.showerrorligne = "Merci de vérifier les champs 'Nom & Prénom' (minimum 3, maximum 25)"
      return false;
    }
    if(!medecin.email || medecin.email.length < 3 || medecin.email.length > 50 ){
      this.showerrorligne = "Merci de vérifier le champ 'Email' (minimum 3, maximum 50)"
      return false;
    }
    if(medecin.email && medecin.email.length > 1 && !this.validateService.validateEmail(medecin.email)){
      this.showerrorligne = "Merci de vérifier le champ 'Email'"
      return false;
    }
    if(!medecin.numInscription || medecin.numInscription.length < 3 || medecin.numInscription.length > 25 || medecin.numInscription.match(/^[0-9]+$/) === null){
      this.showerrorligne = "Merci de vérifier le champ 'N° d'inscription au conseil de l'ordre' (minimum 3, maximum 25)"
      return false;
    }
    if(!medecin.tel || (medecin.tel && medecin.tel.substr(0, 4) == "+216" && medecin.tel[4] != "2" && medecin.tel[4] != "9" && medecin.tel[4] != "5" && medecin.tel.length != 12)){
      this.showerrorligne = "Merci de vérifier le champ 'Contact 1'"
      return false;
    }
    if(medecin.tel.length != 12){
      this.showerrorligne = "Merci de vérifier le champ 'Contact 1'"
      return false;
    }
    if((medecin.telFixe && medecin.telFixe.length > 4 && medecin.telFixe.length != 12)){
      this.showerrorligne = "Merci de vérifier le champ 'Contact 2'"
      return false;
    }
    if((medecin.telPerso && medecin.telPerso.length > 4 && medecin.telPerso.length != 12)){
      this.showerrorligne = "Merci de vérifier le champ 'Contact 3'"
      return false;
    }
    if(!medecin.diplome || medecin.diplome.length < 3 || medecin.diplome.length > 100){
      this.showerrorligne = "Merci de vérifier le champ 'Diplôme' (minimum 3, maximum 100)"
      return false;
    }
    if(!medecin.specialite){
      this.showerrorligne = "Merci de vérifier le champ 'Spécialité'"
      return false;
    }
    if(medecin.dateAnniversaire && medecin.dateAnniversaire > this.today){
      this.showerrorligne = "Merci de vérifier le champ 'Date de naissance'"
      return false;
    }
    if(medecin.codeCNAM && medecin.codeCNAM.length > 25 || medecin.codeCNAM && medecin.codeCNAM.match(/^[0-9]+$/) === null){
      this.showerrorligne = "Merci de vérifier le champ 'Code CNAM' (maximum 25)"
      return false;
    }
    if(medecin.centreCNAM && medecin.centreCNAM.length > 25){
      this.showerrorligne = "Merci de vérifier le champ 'Centre CNAM' (maximum 25)"
      return false;
    }
    if(!medecin.adresseCabinet.adresse ||  medecin.adresseCabinet.adresse.length < 3 ||  medecin.adresseCabinet.adresse.length > 500){
      this.showerrorligne = "Merci de vérifier le champ 'Adresse' (minimum 3, maximum 500)"
      return false;
    }
    if(!medecin.adresseCabinet.ville ||  medecin.adresseCabinet.ville.length < 3 ||  medecin.adresseCabinet.ville.length > 25){
      this.showerrorligne = "Merci de vérifier le champ 'Ville' (minimum 3, maximum 25)"
      return false;
    }
    if(!medecin.adresseCabinet.codePostal ||  medecin.adresseCabinet.codePostal.length != 4 || (medecin.adresseCabinet.codePostal && medecin.adresseCabinet.codePostal.match(/^[0-9]+$/) === null)){
      this.showerrorligne = "Merci de vérifier le champ 'Code postal' (maximum 4)"
      return false;
    }
    this.showerrorligne = ""
    if (this.numInscription != medecin.numInscription) {
      this.medecinService.verifyNumInscription(medecin).subscribe((data:any) => {
        if (data.success) {
          this.errorNumeroInscription = true;
          this.showerrorligne = "Le numéro d'inscription a été déjà utilisé"
          this.successRegister = false;
          return false;
        }
        else {
          this.errorNumeroInscription = false
          this.medecinService.updateMedecinAdmin(medecin).subscribe(medecin => {
            Swal.fire( {
          width: 500,
          background: '#e1e1e1',
          timer: 3000,
          html: '<div class="row">' +
            '<div class="col-3">' +
            '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
            '</div>' +
            '<div><h4 class="text-uppercase" style="margin-top: 23px; font-size:16px">Le médecin a été Modifié avec succès</h4></div>' +
            '</div>',
          showConfirmButton: false
        }
      );
      this.router.navigate(['/medecins'], {
        queryParams: {refresh: new Date().getTime()}
      });
          });
        }
      })
    } else {
      this.medecinService.updateMedecinAdmin(medecin).subscribe(medecin => {
        Swal.fire( {
      width: 500,
      background: '#e1e1e1',
      timer: 3000,
      html: '<div class="row">' +
        '<div class="col-3">' +
        '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
        '</div>' +
        '<div><h4 class="text-uppercase" style="margin-top: 23px; font-size:16px">Le médecin a été Modifié avec succès</h4></div>' +
        '</div>',
      showConfirmButton: false
    }
  );
  this.router.navigate(['/medecins'], {
    queryParams: {refresh: new Date().getTime()}
  });
      });
    }
  }
  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }
}
