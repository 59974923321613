import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import 'tinymce';
import 'tinymce/themes/modern';

import 'tinymce/plugins/code';
import 'tinymce/plugins/lists';
declare var tinymce: any;
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { Router } from '@angular/router';
import { ArticleService } from 'src/app/services/article/article.service';
@Component({
  selector: 'app-ajout-article',
  templateUrl: './ajout-article.component.html',
  styleUrls: ['./ajout-article.component.css','../../patients/ajouter-patients-med/ajouter-patients-med.component.css', '../../medecins/medecins/medecins.component.css'],
})
export class AjoutArticleComponent implements OnInit {
  admin: any;
  evenement: any;
  form: any;
  image: any;
  idEvent: any;
  errorDate = false;
  evenements: any[] = [];
  loading = false
  categorieList = ['Maladie', 'Nutrition', 'Actualités médicales','Médicaments et traitements','Santé mentale','Beauté et bien-être',
  'Découvertes scientifiques et technologiques','Prévention et mode de vie sain'] 

  sizeLimitOne = 5000000; //5MB
  @Output() onEditorContentChange = new EventEmitter();
  errorDescription = false
  editor;
  successRegister:any
  imageCouverture: File = null;
  imageDescription: File = null;
  showSizeErrorCouv: boolean = false;
  showSizeErrorDesc: boolean = false;
  showRequiredErrorCouv: boolean = false;
  showRequiredErrorDesc: boolean = false;
  editorInstance: any;
  style1 = "#dc3545";
  style2 = "bold";
  style3 = "small";
  style4 = "green"
  submitted = false;
  activeColor: string = 'green';
  baseColor: string = '#ccc';
  overlayColor: string = 'rgba(255,255,255,0.5)';
  dragging: boolean = false;
  loaded: boolean = false;
  imageLoaded: boolean = false;
  imageSrcCouverture: string = '';
  imageSrcDescription: string = '';
  today = new Date().toISOString().split('T')[0];
  tags:string[]=[]
  errormessageMotCles=false

  constructor(private formBuilder:FormBuilder,private router:Router,private articleService:ArticleService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      titre: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(500)]],
      categorie: ['Cinéma', Validators.required],
      description: ['',],
      date: [this.today, Validators.required],
      slogan: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(500)]],
      motCles: [""],
      visibilite: [false, [Validators.required]],
      fileCover: [null, [ Validators.pattern("[^\\s]+(.*?)\\.(png|PNG|gif|GIF|jpg|JPG|jpeg|JPEG )$")]],
     filedescription: [null, [Validators.pattern("[^\\s]+(.*?)\\.(png|PNG|gif|GIF|jpg|JPG|jpeg|JPEG )$")]]
    });
    
    tinymce.init({
      selector: '#myTextArea',
      skin_url: '/assets/skins/lightgray',
      height: 350,
      width: 1095,
      resize: false,
      theme: 'modern',
      plugins: ['code', 'lists'],

      mobile: {
        theme: 'mobile',
        plugins: ['autosave', 'lists'],
        toolbar: ['undo', 'bold', 'italic', 'styleselect', 'checklist']
      },
      content_css: [
        '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
        '//www.tinymce.com/css/codepen.min.css'],
      paste_data_images: true,
      setup: editor => {
        this.editor = editor;
        editor.on('keyup change', () => {
          const content = editor.getContent();
          this.onEditorContentChange.emit(content);
        });
      }
    });
}
addTag() {
  const newTag = this.form.get('motCles').value.trim();
  if (newTag !== '' && !this.tags.includes(newTag) ) {
    this.tags.push(newTag);
    this.form.get('motCles').reset();
  }
  else if (this.tags.includes(newTag)){
    this.errormessageMotCles=true
    setTimeout(() => {
      this.errormessageMotCles = false;
    }, 2000);
  }
  this.form.value.motCles=this.tags
  
}
removeTag(tag: string) {
  const tagIndex = this.tags.indexOf(tag);
  if (tagIndex !== -1) {
    this.tags.splice(tagIndex, 1);
  }
}
onEnterPressed(event: KeyboardEvent) {
  event.preventDefault(); 
}


ngOnDestroy() {
  tinymce.remove(this.editor);
}
handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDragOverCouv(event: DragEvent) {
    event.preventDefault();
    this.dragging = true;
    

  }

  handleDragOverDesc(event: DragEvent) {
    event.preventDefault();
    this.dragging = true;

  }

  handleDropCouv(e: DragEvent) {
    e.preventDefault();
    this.dragging = false;
    this.imageCouverture = e.dataTransfer.files[0]
    // Vérifier la taille de l'image
    if (this.imageCouverture.size > 2000000) {
      // La taille de l'image dépasse la limite autorisée
      this.showSizeErrorCouv = true;
      return; // Arrêtez le traitement si la taille dépasse la limite
    }
    var file = e.dataTransfer.files[0]
    var reader = new FileReader();
    this.loaded = false;

    reader.onload = this._handleReaderLoadedCouv.bind(this);
    reader.readAsDataURL(file);
    this.showRequiredErrorCouv=false
  
  }
  handleDropDesc(e: DragEvent) {
    e.preventDefault();
    this.dragging = false;
   
    this.imageDescription =e.dataTransfer.files[0];
    // Vérifier la taille de l'image
    if (this.imageDescription.size > 2000000) {
      // La taille de l'image dépasse la limite autorisée
      this.showSizeErrorDesc = true;
      return; // Arrêtez le traitement si la taille dépasse la limite
    }
    var file =e.dataTransfer.files[0];

    var reader = new FileReader();
    this.loaded = false;

    reader.onload = this._handleReaderLoadedDesc.bind(this);
    reader.readAsDataURL(file);
    this.showRequiredErrorDesc=false
    
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  handleInputChangeCouv(e) {

    this.imageCouverture = e.target.files[0];
    // Vérifier la taille de l'image
    if (this.imageCouverture.size > 2000000) {
      // La taille de l'image dépasse la limite autorisée
      this.showSizeErrorCouv = true;
      return; // Arrêtez le traitement si la taille dépasse la limite
    }
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var reader = new FileReader();
    this.loaded = false;

    reader.onload = this._handleReaderLoadedCouv.bind(this);
    reader.readAsDataURL(file);
    this.showRequiredErrorCouv=false
  }

  handleInputChangeDesc(e) {
    this.imageDescription = e.target.files[0];
    // Vérifier la taille de l'image
    if (this.imageDescription.size > 2000000) {
      // La taille de l'image dépasse la limite autorisée
      this.showSizeErrorDesc = true;
      return; // Arrêtez le traitement si la taille dépasse la limite
    }
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    var reader = new FileReader();
    this.loaded = false;

    reader.onload = this._handleReaderLoadedDesc.bind(this);
    reader.readAsDataURL(file);
    this.showRequiredErrorDesc=false
  }
  
  _handleReaderLoadedCouv(e) {
    var reader = e.target;
    this.imageSrcCouverture = reader.result;
    this.loaded = true;
  }

  _handleReaderLoadedDesc(e) {
    var reader = e.target;
    this.imageSrcDescription = reader.result;
    this.loaded = true;
  }

  addBlog() {
    this.submitted = true;
    if(this.tags && this.tags.length ===0){
      return;
     }
        if(this.imageDescription===null && this.imageCouverture===null)
    {
      this.showRequiredErrorDesc=true;
      this.showRequiredErrorCouv=true;
      return;
    }
    if((this.imageCouverture==null && this.imageDescription!==null)||(this.imageCouverture!==null && this.imageDescription==null) )
    {
      if(this.imageCouverture==null){
        this.showRequiredErrorCouv=true;
        this.showRequiredErrorDesc=false;
        return;
      }
      else if (this.imageDescription==null){
        this.showRequiredErrorDesc=true;
        this.showRequiredErrorCouv=false;
        return;
      }
    }
    else {
      const formData = new FormData();
      this.form.value.motCles = this.tags
      formData.append('categorie', this.form.value.categorie);
      formData.append('titre', this.form.value.titre);
      formData.append('date', this.form.value.date);
      formData.append('slogan', this.form.value.slogan);
      formData.append('description', tinymce.get('myTextArea').getContent());
      formData.append('motCles', this.form.value.motCles);
      formData.append('visibilite', this.form.value.visibilite);
      if(this.imageCouverture){
        formData.append("file", this.imageCouverture, this.imageCouverture.name);
      }
      if(this.imageDescription){
        formData.append("file", this.imageDescription, this.imageDescription.name);
      }
      ////pour afficher le formDATA dans le console pour le tester
      const formDataObject: any = {};
      formData.forEach((value, key) => {
        // Handle multiple values for the same key as an array
        if (formDataObject.hasOwnProperty(key)) {
          if (!Array.isArray(formDataObject[key])) {
            formDataObject[key] = [formDataObject[key]];
          }
          formDataObject[key].push(value);
        } else {
          formDataObject[key] = value;
        }
      })
      ////////////////////////////////////////////////////////////////
      this.articleService.addArticle(formData).subscribe(
        (response) => {
          setTimeout(() => {
            Swal.fire( {
                width: 500,
                background: '#e1e1e1',
                timer: 3000,
                html: '<div class="row">' +
                  '<div class="col-3">' +
                  '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
                  '</div>' +
                  '<div><h4 class="text-uppercase" style="margin-top: 23px; font-size:16px">Article a été AJOUTÉ avec succès</h4></div>' +
                  '</div>',
                showConfirmButton: false
              }
            );
            this.loading = false;
            this.router.navigate(['/articles']);
          }, 1000);
        }
      )
    }

  }

}
