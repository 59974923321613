import { Component, OnInit,Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MedecinService } from 'src/app/services/medecin/medecin.service';
import { uploadFolderRootMedecin } from 'config/API_CONFIG';
@Component({
  selector: 'app-details-medecins',
  templateUrl: './details-medecins.component.html',
  styleUrls: ['./details-medecins.component.css']
})
export class DetailsMedecinsComponent implements OnInit {

  admin: any;
  medecin: any;
  @Input() imgPath:string = uploadFolderRootMedecin;

  constructor(private medecinService: MedecinService,  private authService: AuthService, private activatedRoute: ActivatedRoute,
    private router: Router) {
    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if (!this.admin.roles.gestionMedecins) {
        this.router.navigate(['/notFound']);
        return false;
      }
    },
      err => {
        console.log(err);
        return false;
      });
  }
  ngOnInit() {

    let params: any = this.activatedRoute.snapshot.params;
    this.medecinService.getMedecinById(params.id).subscribe(medecin => {
      this.medecin = medecin;
    },
      err => {
        console.log(err);
        return false;
      });
  }
}

