<div id="scrolltoverify">

</div>
<div class="cadreProfile" *ngIf="admin">
  <!-- <span class="triangle"></span>-->
  <div class="row profil-top">
    <div class="col-1 col-xl-1 col-lg-1 col-md-1 col-sm-1">
      <div class="image-div" title="Télécharger une photo" data-toggle="modal" data-target="#myModal5">
        <img src="assets/Groupe%2068.png" class="image-admin" *ngIf="!image">
        <img src="{{urlImage}}" class="image-admin" *ngIf="image">
      </div>
    </div>
    <div class="col-11 col-xl-11 col-lg-11 col-md-11 col-sm-11">
      <h3 class="name" style="text-transform: capitalize;">{{admin.prenom}} {{admin.nom}}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-xl-6 col-lg-10 col-md-12 col-sm-12">
      <div class="row bar" style="margin-bottom: 30px !important;">
        <div class="col-10 col-xl-10 col-lg-10 col-md-10 bar-section"><a class="linkPart2"><b>Mes informations de base</b></a></div>
      </div>
      <div class="row height40" style="    margin-left: 55px; margin-bottom: 15px">
        <div class="col-xl-4 col-lg-4 col-md-10 col-12">
          <h6>Nom</h6>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-10 col-12">
          <div class="info" style="text-transform: capitalize;">{{admin.nom}}</div>
        </div>
      </div>
      <div class="row height40" style="    margin-left: 55px; margin-bottom: 15px">
        <div class="col-xl-4 col-lg-4 col-md-10 col-12">
          <h6>Prénom</h6>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-10 col-12">
          <div class="info" style="text-transform: capitalize;">{{admin.prenom}}</div>
        </div>
      </div>
      <div class="row height40" style="    margin-left: 55px; margin-bottom: 15px">
        <div class="col-xl-4 col-lg-4 col-md-10 col-12">
          <h6>Email</h6>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-10 col-12">
          <div class="info">{{admin.email}}</div>
        </div>
      </div>
      <div class="row height40" style="    margin-left: 55px; margin-bottom: 15px">
        <div class="col-xl-4 col-lg-4 col-md-10 col-12">
          <h6>Mot de passe <a href="#" title="Modifier le mot de passe" (click)="initialiserForm()" data-toggle="modal" data-target="#myModal4"><img src="assets/pencil-7.png" style="height: 15px;cursor: pointer"></a></h6>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-10 col-12">
          <div class="info">•••••••••••••</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-xl-5 col-lg-10 col-md-12 col-sm-12">
      <div class="row bar" style="margin-bottom: 30px !important;">
        <div class="col-10 col-xl-10 col-lg-10 col-md-10 col-sm-10 bar-section"><a class="linkPart2"><b>Réglages du système</b></a></div>
      </div>
      <div class="row height40" style="    margin-left: 55px; margin-bottom: 15px">
        <div class="col-xl-4 col-lg-4 col-md-10 col-12">
          <h6>Langue</h6>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-10 col-12">
          <div class="info">Français</div>
        </div>
      </div>
      <div class="row height40" style="    margin-left: 55px; margin-bottom: 15px">
        <div class="col-xl-8 col-lg-8 col-md-10 col-12">
          <h6>Paramètres de notifications</h6>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-10 col-12">
          <!--<div class="modif-param"><a>Modifier</a></div>-->
        </div>
      </div>
      <div class="row height40" style="    margin-left: 55px; margin-bottom: 15px">
        <div class="col-xl-8 col-lg-8 col-md-10 col-12">
          <h6>Paramètres des messages</h6>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-10 col-12">
          <!--<div class="modif-param"><a>Modifier</a></div>-->
        </div>
      </div>
    </div>
  </div>
</div>
<!---------------------------- Pop up modifier mot de passe ---------->
<div class="modal fade" id="myModal4" tabindex="-1" role="dialog" aria-labelledby="myModal4" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan">
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut">
          MODIFICATION MOT DE PASSE
        </h3>
      </div>
      <div class="modal-body" *ngIf="admin">
        <form class="" [formGroup]="form">
        <div class="row" style="margin-left: 6%;">
          <div class="col-10">
            <div class="title textContenu">
              <input class="font-weight-bold" formControlName="oldPassword" name="oldPassword" type="password" placeholder="Taper votre ancien mot de passe*">
            </div>
            <control-messages style="color:red;position: relative;top: 25px;" [control]="form.controls.oldPassword"></control-messages>
            <p style="color:red;position: relative;top: 25px;" *ngIf="testOldPassword">Vérifiez votre ancien mot de passe<p>
          </div>
        </div>
        <div class="row" style="margin-left: 6%;">
          <div class="col-10">
            <div class="title textContenu">
              <input class="font-weight-bold" placeholder="Taper votre nouveau mot de passe*" formControlName="password" name="password" type="password">
            </div>
            <control-messages style="color:red;position: relative;top: 25px;" [control]="form.controls.password"></control-messages>
            <p style="color:red;position: relative;top: 25px;" *ngIf="testPassword">Le nouveau mot de passe est déjà utilisé<p>
          </div>
        </div>
        <div class="row" style="margin-left: 6%;">
          <div class="col-10">
            <div class="title textContenu">
              <input class="font-weight-bold" placeholder="Retaper votre nouveau mot de passe*" formControlName="confirmPassword" name="confirmPassword" type="password">
            </div>
            <control-messages style="color:red;position: relative;top: 25px;" [control]="form.controls.confirmPassword"></control-messages>
            <p style="color:red;position: relative;top: 25px;" *ngIf="testConfirmPassword">Les mots de passe ne correspondent pas. Veuillez réessayer !<p>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <div class=" col d-flex justify-content-center buttonsPopup">
            <div class="col-auto nbr-dossier ">
              <table>
                <tr>
                  <td class="tdButt1 ">
                    <button class="btn-valider" [disabled]="!form.valid" (click)="editPassword(admin)"><img src="assets/valider.png" class="iconBoutton">Valider </button>
                  </td>
                  <td class="tdButt2 ">
                    <button class="btn-annule" id="btn-annule-password" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton">Annuler </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!---------------------------- Pop up modifier photo ---------->
<div class="modal fade" id="myModal5" tabindex="-1" role="dialog" aria-labelledby="myModal4" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan">
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut">
          MODIFICATION PHOTO
        </h3>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-left: 6%;">
          <div class="col-10">
            <div class="title textContenu">
              <label style="font-size: 15px;font-weight: bold;    color: #666666;">Télécharger une photo</label>

              <input class="input-btn-file" type="file" id="file" [(ngModel)]="fileToChoose" name="fileToChoose" ng2FileSelect [uploader]="uploader" accept="image/*" (change)="fileChange($event)" />
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <div class=" col d-flex justify-content-center buttonsPopup">
            <div class="col-auto nbr-dossier ">
              <table>
                <tr>
                  <td class="tdButt1 ">
                    <button class="btn-valider" (click)="uploader.uploadAll();refresh()" [disabled]="!uploader.getNotUploadedItems().length"><img src="assets/valider.png" class="iconBoutton">Enregistrer </button>
                  </td>
                  <td class="tdButt2 ">
                    <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton">Annuler </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
