import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';
@Injectable()
export class ImageService{

  constructor(private http:HttpClient){
  }
  getImageAdmin(id){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
  return this.http.get(_getURL(API_CONFIG.image)+'/admin/'+id, {headers: headers})
  }

  }
