import { Component, OnInit } from '@angular/core';
declare var $: any;
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { MedicamentsService } from 'src/app/services/medicaments/medicaments.service';
@Component({
  selector: 'app-details-medicaments',
  templateUrl: './details-medicaments.component.html',
  styleUrls: ['./details-medicaments.component.css','../../medecins/medecins/medecins.component.css']
})
export class DetailsMedicamentsComponent implements OnInit {

  admin: any;
  medicament:any;
  constructor(  private authService: AuthService, private activatedRoute: ActivatedRoute,private medicamentsService:MedicamentsService,
    private router: Router, private formBuilder: FormBuilder) {
    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if (!this.admin.roles.gestionConseils) {
        this.router.navigate(['/notFound']);
        return false;
      }
    })
  }
  ngOnInit() {
    let params: any = this.activatedRoute.snapshot.params;
    this.medicamentsService.getmedicamentById(params.id).subscribe(medicament => {
      this.medicament = medicament;
    },
      err => {
        return false;
      });
  }

}