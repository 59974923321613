<br><br>
<div class="">
  <img src="assets/medivisto-yellow.png" alt="" class="logo-admin">
</div>
<br><br><br>
<div class="row">
  <div class="col-12 col-sm-12 col-md-12  col-lg-3">
    <img src="assets/doctors.png" alt="" class="img-login-admin">
  </div>
  <div class="col-12 col-sm-12 col-md-12 col-lg-9">
    <div class="login-page">
      <div class="form">
        <div id="exTab2" class="">
          <p *ngIf="errorMsg" style="color: red;">{{errorMsg}}</p>
          <p *ngIf="counterClick>4" id="alert-msg-nombre-tentative">Ce mot de passe est incorrect. <br> 
            Pour des raisons de sécurité, après <b>10</b> tentatives de connexion, vous devrez attendre <b>30</b> minutes avant de réessayer<br>
        </p>
          <div class="tab-content" style="padding-top: 30px">
            <div class="tab-pane active" id="1">
              <form  class="login-form" (submit)="loginAdmin()">
                <input type="text" placeholder="Email" name="email" [(ngModel)]="email" onkeypress="return /[a-zA-Z0-9@._-]/.test(event.key)" />
                <input type="password" placeholder="Mot de passe" name="password" [(ngModel)]="password"/>
                <input type="submit"  class="btn" style="background-color: #E5B92C;font-size: 17px;font-weight: bold;color: white;" [attr.disabled]="!isValid ? true : null" value="Connexion">
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
