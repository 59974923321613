import { Component, OnInit } from '@angular/core';
import {AuthService} from 'src/app/services/auth/auth.service';
import { Router,ActivatedRoute}from '@angular/router';
import { AdminService } from 'src/app/services/admin/admin.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
@Component({
  selector: 'app-gestion-roles-admin',
  templateUrl: './gestion-roles-admin.component.html',
  styleUrls: ['./gestion-roles-admin.component.css','../../medecins/medecins/medecins.component.css']
})
export class GestionRolesAdminComponent implements OnInit {

  admin:any;
  sousAdmin:any;
  gestionAdmins:any;
  gestionForums:any;
  gestionEvenements:any;
  gestionMedecins:any;
  gestionAccueil:any;
  gestionAgenda:any;
  gestionConseils:any;
  constructor(private adminService:AdminService, private authService:AuthService,private activatedRoute:ActivatedRoute ,
    private router:Router) {
      this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if(!this.admin.roles.gestionAdmins){
        this.router.navigate(['/notFound']);
        return false;
      }
      },
      err => {
      console.log(err);
      return false;
      });
  }
      ngOnInit(){
        let el = document.getElementById('scrolltoverify');
      el.scrollIntoView({ behavior: "smooth" });
        let params: any = this.activatedRoute.snapshot.params;
            this.adminService.getAdminById(params.id).subscribe(sousAdmin => {
            this.sousAdmin = sousAdmin;
            this.gestionAgenda = this.sousAdmin.roles.gestionAgenda;
            this.gestionAdmins = this.sousAdmin.roles.gestionAdmins;
            this.gestionForums= this.sousAdmin.roles.gestionForums;
            this.gestionEvenements= this.sousAdmin.roles.gestionEvenements;
            this.gestionMedecins= this.sousAdmin.roles.gestionMedecins;
            this.gestionAccueil= this.sousAdmin.roles.gestionAccueil;
            this.gestionConseils= this.sousAdmin.roles.gestionConseils;
          },
          err => {
            console.log(err);
            return false;
          });
  
        }
  // modifier les rôles d'un médecin
  modifierRoles(){
          let params: any = this.activatedRoute.snapshot.params;
        var newAdmin = {
          _id:params.id,
          roles:{
            gestionAgenda:this.gestionAgenda,
            gestionAdmins:this.gestionAdmins,
            gestionForums:this.gestionForums,
            gestionEvenements:this.gestionEvenements,
            gestionMedecins:this.gestionMedecins,
            gestionAccueil:this.gestionAccueil,
            gestionConseils:this.gestionConseils
          }
  
        }
        this.adminService.editAdmin(newAdmin).subscribe(sousAdmin => {
            this.sousAdmin = sousAdmin;
        });
        Swal.fire( {
            width: 500,
            background: '#e1e1e1',
            timer: 3000,
            html: '<div class="row">' +
              '<div class="col-3">' +
              '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
              '</div>' +
              '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Les rôles ont été MODIFIÉ avec succès</h4></div>' +
              '</div>',
            showConfirmButton: false
          }
        );
        this.router.navigate(['/admins']);
  }
  
  
  }
  