
<section id="services-section" id="scrolltoverify" *ngIf="patient">
    <div class="content-section" id="services">
      <div class="container">
        <div class="row">
          <div class="heading-section col-md-12 text-center wow animated fadeInDown" style="padding: 1rem 1rem;border-bottom: 1px solid #dee2e6;border-top-left-radius: calc(0.3rem - 1px);border-top-right-radius: calc(0.3rem - 1px);">
            <h3 style="text-align: center;color: #E5B92C;text-transform: uppercase!important;font-size: 1.75rem;">Modifier un patient</h3>
            <h3 style="color:rgb(15, 70, 103);display: inline-block;font-size: 25px;"></h3>
          </div>
          <!-- /.heading-section -->
        </div>
  
        <!-- /.row -->
        <div id="sevices-item" class="row">
          <form [formGroup]="form" style="width: 100%;">
            <br>
            <fieldset>
              <legend style="font-family: exo;">Informations personnelles</legend>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="nom">Nom* </label>
                    <input type="text" class="form-control input-sm" id="nom" formControlName="nom" value="{{patient.nom}}" [(ngModel)]="patient.nom"  name="nom" placeholder="Nom">
                  </div>
                  <control-messages [control]="form.controls.nom"></control-messages>
                </div>
                <div class="col-md-6 col-sm-6" >
                  <div class="title textContenu">
                  <label for="prenom">Prénom*</label>
                  <input type="text" formControlName="prenom" name="prenom" value="{{patient.prenom}}" [(ngModel)]="patient.prenom" class="form-control input-sm" id="prenom" placeholder="Prénom">
                  </div>
                  <control-messages [control]="form.controls.prenom"></control-messages>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6" >
                  <div class="title textContenu">
                  <label for="tel">Email*</label>
                  <input type="text" formControlName="email" name="email" value="{{patient.email}}" [(ngModel)]="patient.email"  class="form-control input-sm" id="email" placeholder="Email">
  
                  </div>
                  <control-messages [control]="form.controls.email"></control-messages>
                </div>
                <div class="col-md-6 col-sm-6" >
                  <div class="title textContenu">
                  <label for="tel">Sexe</label>
                  <select class="select-pop2 form-control input-sm" id="sexe" style="border-bottom: 3px solid #E1E1E1;margin-top: 2px;padding:0px;" formControlName="sexe" name="sexe" value="{{patient.sexe}}" [(ngModel)]="patient.sexe">
                    <option>Masculin</option>
                    <option>Féminin </option>
                  </select>
  
                  </div>
                  <control-messages [control]="form.controls.sexe"></control-messages>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6" >
                  <div class="title textContenu">
                  <label for="telMobile">Date de naissance*</label>
                  <input placeholder="Date de naissance" [max]="getToday()" formControlName="dateAnniversaire" name="dateAnniversaire" value="{{patient.dateAnniversaire}}" [(ngModel)]="patient.dateAnniversaire" type="date" id="date-picker-example" class="form-control input-sm">
  
                  </div>
                  <control-messages [control]="form.controls.dateAnniversaire"></control-messages>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="title textContenu">
                    <label for="telMobile">Contact*</label>
                    <international-phone-number formControlName="tel" [(ngModel)]="patient.tel" name="tel" placeholder="Contact 1" [maxlength]="12" [defaultCountry]="'tn'" [required]="true"></international-phone-number>
                  </div>
                  <control-messages [control]="form.controls.tel"></control-messages>
                </div>
              </div>
              <div formGroupName="adresse">
                <br>
                <legend style="font-family: exo;">Adresse</legend>
                <div class="row">
                  <div class="col-md-6 col-sm-6" >
                    <div class="title textContenu">
                    <label for="pays">Pays*</label>
                    <select class="select-pop2 form-control input-sm" id="pays" value="{{patient.adresse.pays}}" formControlName="pays" name="patient.adresse.pays"  [(ngModel)]="patient.adresse.pays" style="border-bottom: 3px solid #E1E1E1;margin-top: 2px;padding:0px;" (ngModelChange)="onSelectpays(patient.adresse.pays)">
                      <option  selected>Tunisie</option>
                      <option *ngFor="let py of pays">{{py.name}}</option>
                    </select>
  
                    </div>
                    <control-messages [control]="form.controls.adresse.controls.pays"></control-messages>
                  </div>
                  <div class="col-md-6 col-sm-6" >
                    <div class="title textContenu">
                    <label for="ville">Ville*</label>
                    <input type="text" formControlName="ville" value="{{patient.adresse.ville}}"  name="patient.adresse.ville" [(ngModel)]="patient.adresse.ville" class="form-control input-sm" id="ville" placeholder="Ville" *ngIf="!isTunisie">
                    <select class="form-control input-sm" style="border:none" id="specialite" formControlName="ville"  [(ngModel)]="patient.adresse.ville" name="patient.adresse.ville" value="{{patient.adresse.ville}}" (ngModelChange)="onSelectville(patient.adresse.ville)" *ngIf="isTunisie">
                      <option></option>
                      <option *ngFor="let ville of listefiltreville">{{ville}}</option>
                    </select>
                    </div>
                    <control-messages [control]="form.controls.adresse.controls.ville"></control-messages>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6" >
                    <div class="title textContenu">
                    <label for="codePostal">Code postal*</label>
                    <input type="text" formControlName="codePostal" value="{{patient.adresse.codePostal}}"  name="patient.adresse.codePostal" [(ngModel)]="patient.adresse.codePostal" class="form-control input-sm" id="codePostal" placeholder="Code postal" *ngIf="!isTunisie">
                    <select class="form-control input-sm" style="border:none" formControlName="codePostal"  value="{{patient.adresse.codePostal}}" [(ngModel)]="patient.adresse.codePostal" name="patient.adresse.codePostal" *ngIf="isTunisie">
                      <option></option>
                      <option *ngFor="let postal of listefiltrecode">{{postal}}</option>
                    </select>
                    </div>
                    <control-messages [control]="form.controls.adresse.controls.codePostal"></control-messages>
                  </div>
                  <div class="col-md-6 col-sm-6" >
                    <div class="title textContenu">
                    <label for="numero">Adresse*</label>
                    <input type="text" formControlName="adresse" name="adresse" class="form-control input-sm" id="rue" placeholder="Adresse" value="{{patient.adresse.adresse}}" [(ngModel)]="patient.adresse.adresse">
                    </div>
                    <control-messages [control]="form.controls.adresse.controls.adresse"></control-messages>
                  </div>
                </div>
              </div>
              <div class="row buts" style="position: relative;left: 11%;top: 25px;">
                <div class="col-1 col-xl-2 col-lg-2 col-md-1 col-sm-1"></div>
                <div class="col-12 col-xl-6 col-lg-8 col-md-12 col-sm-12">
                  <div class="row ">
                    <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm 12">
                      <button class="btn-valider" (click)="savePatient(patient)" [disabled]="!form.valid" data-toggle="modal" data-target="#myModal3"><img src="assets/valider.png" class="iconBoutton">Modifier </button>
                    </div>
                    <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm 12 ">
                      <button class="btn-annule" [routerLink]="['/patients']"><img src="assets/annuler.png" class="iconBoutton">Annuler </button>
                    </div>
                  </div>
                  <br><br><br>
                </div>
                <div class="col-1 col-xl-2 col-lg-2 col-md-1 col-sm-1"></div>
              </div>
            </fieldset>
          </form>
        </div>
        <!-- /.row -->
  
  
      </div>
      <!-- /.container -->
    </div>
  
    <!---------------------------- Pop up supprimer admin ---------->
    <div class="modal fade" id="myModal3" tabindex="-1" role="dialog" aria-labelledby="myModal6" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-content-scan">
          <div class="modal-header d-flex justify-content-center">
            <h3 class="titre-modal-statut text-uppercase">
              MODIFIER UN PATIENT
            </h3>
          </div>
          <div class="modal-body">
            <div class="row" style="margin-left: 6%;">
              <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop"></div>
              <div class="col-10">
                <h4 class="textContenu text-uppercase">VOULEZ-VOUS SAUVEGARDER VOS MODIFICATIONS?</h4>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <div class=" col d-flex justify-content-center buttonsPopup">
                <div class="col-auto nbr-dossier ">
                  <table>
                    <tr>
                      <td class="tdButt1 ">
                        <button class="btn-valider" data-dismiss="modal" data-toggle="modal" data-target="#deleted" ><img src="assets/valider.png" class="iconBoutton">Sauvegarder </button>
                      </td>
                      <td class="tdButt2 ">
                        <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton">Annuler </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!-- /#services -->
  </section>
  