import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms'
import { AuthService } from 'src/app/services/auth/auth.service';

import * as $ from "jquery";
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_CONFIG, _getURL } from 'config/API_CONFIG';
declare var jQuery: any
import { RdvService } from 'src/app/services/rdv/rdv.service';
import { CalendarComponent } from 'ng-fullcalendar';
@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit {

  form: any;
  patient: any;
  rdvs: any[] = [];
  start: any;
  rdv: any;
  id: any;
  admin: any;
  RolesAdmin: any[] = [];
  dataRefresher: any;
  interval: any;
  updatedNom: any;
  updatedPrenom: any;
  @ViewChild(CalendarComponent) calendar: CalendarComponent;
  //calendar:any;
  changeCalendarView(view) {
    this.calendar.fullCalendar('changeView', view);
  }
  constructor(private router: Router, private http: HttpClient, private rdvService: RdvService,
    private formBuilder: FormBuilder, private authService: AuthService,
    ) {
    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if (!this.admin.roles.gestionAgenda) {
        this.router.navigate(['/notFound']);
        return false;
      }
    },
      err => {
        return false;
      });

  }
  ngOnInit() {
    let el = document.getElementById('scrolltoverify');
    el.scrollIntoView({ behavior: "smooth" });
    var adminString = localStorage.getItem('admin');
    var adminId = JSON.parse(adminString).id;
    var adminNom = JSON.parse(adminString).nom;
    var adminPrenom = JSON.parse(adminString).prenom;
    this.updatedNom = adminNom.charAt(0).toUpperCase() + adminNom.slice(1);
    this.updatedPrenom = adminPrenom.charAt(0).toUpperCase() + adminPrenom.slice(1);
    this.rdvService.getAllRdvsByAdmin(adminId).subscribe((rdvs:any[]) => {
      this.rdvs = rdvs;
      this.calendarOptions.events = this.rdvs
      this.calendar.fullCalendar('renderEvents', this.calendarOptions.events, true);
    },
      err => {
        console.log(err);
        return false;
      });
    this.form = this.formBuilder.group({
      title: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      heure: ['', Validators.required],
      lieu: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      adminId: adminId
    });
  }
  calendarOptions: any = {
    header: {
      left: 'prev,next today',
      center: 'title',
      right: 'month,agendaWeek,agendaDay'
    },
    locale: 'fr',
    lang: 'fr',
    buttonText: {
      today: 'Aujourd\'hui',
      month: 'Mois',
      week: 'Semaine',
      day: 'Jour'
    },
    allDayDefault: false,
    selectable: true,
    selectHelper: true,
    editable: true,
    height: 700,
    timeFormat: 'H:mm',
    //eventClick: (calEvent, jsEvent, view) => this.clickEvent(calEvent, jsEvent, view),
    //dayClick: (date, jsEvent, view) => this.clickDay(date, jsEvent, view),
    eventLimit: true, // allow "more" link when too many events
    events: [],
  };
  eventClick(calEvent) {

    if (calEvent.rdvPerso == true) {
      jQuery('#titleMod').val(calEvent.title);
      jQuery('#start').val(calEvent.start._i);
      jQuery('#lieu').val(calEvent.lieu);
      jQuery('#deleteButton').val(calEvent._id);
      jQuery('#updateButton').val(calEvent._id);
      jQuery('#detailsEventModal').modal('show');
    }
  }
  dayClick(details) {
    jQuery('#addEventModal').modal('show');
    this.start = details.date.format();
  }
  saveEventPerso() {
    this.form.value.start = this.start + 'T' + this.form.value.heure
    this.form.value.backgroundColor = '#008000';
    this.form.value.rdvPerso = true;
    this.rdvService.registerEventAdminPerso(this.form.value)
      .subscribe(rdv => {
        this.rdvs.push(rdv);
        this.calendar.fullCalendar('renderEvent', rdv, true);
        jQuery('#addEventModal').modal('toggle');
        this.form.reset();
      },
        err => {
          console.log(err);
          return false;
        });

  }

  deleteEvent() {
    var rdvId = jQuery('#deleteButton').val();
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
      this.http.delete(_getURL(API_CONFIG.rdvs) + '/' + rdvId, { headers: headers }).subscribe((res: any) => {
         this.calendar.fullCalendar('removeEvents', rdvId);
         jQuery('#detailsEventModal').modal('toggle');
      })
  }

  modifierEvent() {
    var adminString = localStorage.getItem('admin');
    var adminId = JSON.parse(adminString).id
    var rdvId = jQuery('#updateButton').val();
    var title = jQuery('#titleMod').val();
    var start = jQuery('#start').val();
    var lieu = jQuery('#lieu').val();
    var _rdv = {
      _id: rdvId,
      title: title,
      start: start,
      lieu: lieu
    };
    this.calendar.fullCalendar('removeEvents', rdvId);
    this.rdvService.editRdv(_rdv).subscribe(data => {
      this.rdv = data;
      this.rdvService.getMyRdv(rdvId).subscribe(myrdv => {
        this.calendar.fullCalendar('renderEvent', myrdv, true);
        jQuery('#detailsEventModal').modal('toggle');
      },
        err => {
          console.log(err);
          return false;
        });

    });
  }


}

