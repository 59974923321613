import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';
@Injectable()
export class MedicamentsService{

  constructor(private http:HttpClient){
  }
  getAllKnowMedicaments(){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
  return this.http.get(_getURL(API_CONFIG.medicament)+'/display/allKnown', {headers: headers})
      
  }

  getAllNotKnowMedicaments(){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
  return this.http.get(_getURL(API_CONFIG.medicament)+'/display/not/known', {headers: headers})
      
  }
  editMedicaments(medicament) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.medicament) + '/' + medicament._id, JSON.stringify(medicament), { headers: headers })
      
  }
  getmedicamentById(id){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
  return this.http.get(_getURL(API_CONFIG.medicament)+'/'+id, {headers: headers})
      
  }
  }
