import { Component, OnInit } from '@angular/core';
import { ArticleService } from 'src/app/services/article/article.service';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss','../../medecins/medecins/medecins.component.css']
})
export class ArticlesComponent implements OnInit {

  listArticleArchives:any[]
  listArticleNonArchives:any[]
  idArticleToArchive:string
  idArticleTochange:string
  idArticleTorestore:string
  secretKey = '1aB$9pQcR*5zY';
  searchArchived = '';
  search='';
  dataSource: any;
////////////////article non archives
  page: number = 1;
  count: number = 0;
  tableSize: number = 8;
 
////////////////article  archives
pageArchive: number = 1;
countArchive: number = 0;
tableSizeArchive: number = 8;
showMyContainer = 1
 pc = 1
 p=1
 choosedArticle:any
  constructor(private articleService:ArticleService,private route:Router ) { }

  ngOnInit(): void {
    
    this.getListArticlesArchives()
    this.getListArticlesNonArchives()
  }




  getListArticlesArchives(){
    this.articleService.listArticleArchives().subscribe(
      (response)=>{
        this.listArticleArchives=response;
        for ( let article of this.listArticleArchives){
          let date=new Date(article.date)
          article.date=date
        
        }
      }
     )
  }
  getListArticlesNonArchives(){
    this.articleService.listArticleNonArchive().subscribe(
      (response)=>{
        this.listArticleNonArchives=response;
        for ( let article of this.listArticleNonArchives){
          let date=new Date(article.date)
          article.date=date
        
        }
      }
     )
  }

  getIdArticle(id:string){
  this.idArticleToArchive=id
  }

  archiverArticle(){
    this.articleService.setArchiveArticle(this.idArticleToArchive).subscribe(() =>
    {    
      this.getListArticlesArchives()
    this.getListArticlesNonArchives()
    }
    )

  }
  setarticleTodelet(article){
    this.choosedArticle = article
  }
  showAndHideArticle(){
    this.choosedArticle.visibilite = !this.choosedArticle.visibilite
      this.idArticleTochange = this.choosedArticle._id
      this.setArticleVisibilite()
      return false;
  }
  pageChanged(event) {
  }
  getIdArticleTochange(id:string){
   this.idArticleTochange=id;
  }
  setArticleVisibilite()
  {
    this.articleService.setVisibiliteArticle(this.idArticleTochange).subscribe(() => {    
    })

  }
  removeArtcile(){
    this.choosedArticle.archive = true;
    this.idArticleToArchive = this.choosedArticle._id
    this.archiverArticle()
    return true;
  }
 
  goToUpdateArticle(id:String){
    const encryptedId = CryptoJS.AES.encrypt(id.toString(), this.secretKey).toString();
    this.route.navigate(['/modifier-article', encryptedId]);
  }
  Articlearestorer(id:string){
    this.idArticleTorestore=id;
  }
  restorerArticle(){
    this.articleService.setArchiveArticleToFalse(this.choosedArticle._id).subscribe(() => {    
      this.getListArticlesNonArchives()
      this.getListArticlesArchives()
    })

  }
  
  onTableDataChange(event: any) {
    this.page = event;
    this.getListArticlesNonArchives();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getListArticlesNonArchives();

}
onTableArchiveDataChange(event: any) {
  this.pageArchive = event;
  this.getListArticlesArchives();
}
onTableArchivesSizeChange(event: any): void {
  this.tableSizeArchive = event.target.value;
  this.pageArchive = 1;
  this.getListArticlesArchives();

}
applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  return this.dataSource.filter = filterValue.trim().toLowerCase();
}


 modifPart1() {
  this.showMyContainer = 1;
 }
 modifPart2() {
     this.showMyContainer = 2;
 }
 showPart() {
  return this.showMyContainer;
}
}