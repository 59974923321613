import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';
@Injectable()
export class EvenementService{

  constructor(private http:HttpClient){
  }

  getAllEvenementsPatient(){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.evenement)+'/patient', {headers: headers})
        
  }

    getAllEvenementsMedecin(){
      const headers = new HttpHeaders({
        'Authorization': localStorage.getItem('id_token_adminMedivisto'),
        'Content-Type': 'application/json'
      });
      return this.http.get(_getURL(API_CONFIG.evenement)+'/medecin', {headers: headers})
          
    }
  registerEvenement(newEvenement){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
      return this.http.post(_getURL(API_CONFIG.evenement), JSON.stringify(newEvenement), {headers: headers})
          
  }
  editEvenement(evenement){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.evenement)+'/'+evenement._id, JSON.stringify(evenement), {headers: headers})
        
  }
  getMEvenementById(id){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
  return this.http.get(_getURL(API_CONFIG.evenement)+'/'+id, {headers: headers})
      
   }
  }
