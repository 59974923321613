import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ValidateService } from 'src/app/services/validate/validate.service';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { PasswordOublieComponent } from './password-oublie/password-oublie.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { AdminService } from 'src/app/services/admin/admin.service';

@NgModule({
  declarations: [LoginComponent, PasswordOublieComponent, UpdatePasswordComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule
  ],
  providers:[AuthService,ValidateService,AuthGuard,AdminService],

})
export class LoginModule { }
