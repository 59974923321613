import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
 name: 'searchingMedicaments'
})
export class SearchingMedicamentsPipe implements PipeTransform {
  transform(value, args: string[]){
   if (!value) return value;
   if (value.filter((item) => item.nom.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.nom.toLowerCase().includes(args));}
     // if (value.filter((item) => item.nomCommercial.toLowerCase().includes(args)) != '') {
     //   return value.filter((item) => item.nomCommercial.toLowerCase().includes(args));}
     if (value.filter((item) => item.dci.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.dci.toLowerCase().includes(args));}
     if (value.filter((item) => item.presentation.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.presentation.toLowerCase().includes(args));}
 }}
