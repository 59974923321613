import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EvenementService } from 'src/app/services/evenement/evenement.service';
import { _getURL } from '../../../../../../config/API_CONFIG';

@Component({
  selector: 'app-details-evenements',
  templateUrl: './details-evenements.component.html',
  styleUrls: ['./details-evenements.component.css','../../medecins/medecins/medecins.component.css']
})
export class DetailsEvenementsComponent implements OnInit {
  admin: any;
  evenement: any;

  constructor(private evenementService:EvenementService, private authService: AuthService, private activatedRoute: ActivatedRoute,
    private router: Router) {
    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if (!this.admin.roles.gestionEvenements) {
        this.router.navigate(['/notFound']);
        return false;
      }
    },
      err => {
        console.log(err);
        return false;
      });
  }
  ngOnInit() {
    let params: any = this.activatedRoute.snapshot.params;
    this.evenementService.getMEvenementById(params.id).subscribe(evenement => {
      this.evenement = evenement;
      this.evenement.image = this.evenement.image.replace("https://www.file.medivisto.com",_getURL('').replace("api","")+ 'data/files')
    },
      err => {
        console.log(err);
        return false;
      });
  }
}
