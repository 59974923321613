export const specialites =  [ 'ANATOMIE PATHOLOGIQUE',
  'ANESTHESIE & ANESTHESIE REANIMATION',
  'Allergologue',
  'Angiologue',
  'BACTERIOLOGIE & MICROBIOLOGIE',
  'BIOCHIMIE',
  'BIOLOGIE CLINIQUE',
  'BIOPHYSIQUE ET MEDECINE NUCLEAIRE',
  'CARDIOLOGIE',
  'CHIRURGIE CARCINOLOGIE',
  'CHIRURGIE CARDIO-VASCULAIRE & PERIPHERIQUE',
  'CHIRURGIE GENERALE',
  'CHIRURGIE ORTHOPEDIQUE',
  'CHIRURGIE PEDIATRIQUE',
  'CHIRURGIE PLASTIQUE & REPARATRICE',
  'DERMATOLOGIE',
  'ENDOCRINOLOGIE',
  'GASTRO-ENTEROLOGIE',
  'GYNECOLOGIE-OBSTETRIQUE',
  'HEMATOLOGIE BIOLOGIQUE',
  'HEMATOLOGIE CLINIQUE',
  'HISTO-EMBRYOLOGIE',
  'IMMUNOLOGIE & VIROLOGIE',
  'MALADIES INFECTIEUSES',
  'MEDECIN DENTISTE',
  'MEDECINE CARCINOLOGIQUE',
  'MEDECINE DU TRAVAIL',
  'MEDECINE GENERALE',
  'MEDECINE INTERNE',
  'MEDECINE PHYSIQUE',
  'NEPHROLOGIE',
  'NEURO-CHIRURGIE',
  'NEUROLOGIE',
  'NUTRITION',
  'O.R.L.',
  'OPHTALMOLOGIE',
  'ORTHOPEDIE DENTO FACIALE',
  'PEDIATRIE',
  'PHYSIOLOGIE & EXPLORATION FONCTIONNELLE',
  'PHYSIOTHERAPIE',
  'PNEUMO-PHTISIOLOGIE',
  'PSYCHIATRIE',
  'PSYCHIATRIE INFANTILE',
  'RADIOLOGIE (DIAGNOSTIC)',
  'RADIOTHERAPIE',
  'REANIMATION MEDICALE',
  'RHUMATOLOGIE',
  'STOMATOLOGIE ET CHIRURGIE MAXILLO-FACIALE',
  'UROLOGIE' ]