import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {AuthService} from 'src/app/services/auth/auth.service';
import {ValidateService} from 'src/app/services//validate/validate.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/services/admin/admin.service';

@Component({
  selector: 'app-password-oublie',
  templateUrl: './password-oublie.component.html',
  styleUrls: ['../login/login.component.css']
})
export class PasswordOublieComponent implements OnInit {

  email: any;
  password: any;
  admin: any;
  isValid = true;
  errorMsg:any
  constructor(
    private router: Router,
    private validateService: ValidateService,
    private adminService: AdminService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService) { }
  ngOnInit() {
  }

  // check login and password
  recoverPassword() {
    var admin = {
      email: this.email
    }

    if (admin.email == undefined) {
      this.isValid = false
      this.errorMsg = 'Ajoutez votre email ';
      setTimeout(() => {
        this.isValid = true
      },
        5000);
      return false;
    }
    if (!this.validateService.validateEmail(admin.email)) {
      this.isValid = false
      this.errorMsg = 'Vérifiez votre email';
      setTimeout(() => {
        this.isValid = true
      },
        5000);
      return false;
    }
    else {
      this.adminService.recoverPassword(admin)
        .subscribe((data:any) => {
          if(data.success){
            this.errorMsg = 'Votre demande a été enregistrée. Vous allez recevoir dans un email un code pour la réinitialisation de votre mot de passe. Veuillez consulter votre boite email.'
          }
        });
      this.email = '';
      this.isValid = false

      setTimeout(() => {
        this.isValid = true
      },
        15000);
    }
  }

}

