import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {API_CONFIG} from 'config/API_CONFIG';
import {_getURL} from 'config/API_CONFIG';

@Injectable()
export class RdvService{
    constructor(private http:HttpClient){

    }
//get all rdvs by medecin
    getRdvsByMedecin(medecinId){
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('id_token_adminMedivisto'),
            'Content-Type': 'application/json'
        });
        return this.http.get(_getURL(API_CONFIG.rdvs)+'/medecin/'+medecinId, {headers: headers})
            
    }
    getAllRdvsByPatient(patientId){
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('id_token_adminMedivisto'),
            'Content-Type': 'application/json'
        });
    return this.http.get(_getURL(API_CONFIG.rdvs)+'/patient/'+patientId, {headers: headers})
        
    }

    getAllRdvsByAdmin(adminId){
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('id_token_adminMedivisto'),
            'Content-Type': 'application/json'
        });
    return this.http.get(_getURL(API_CONFIG.rdvs)+'/admin/'+adminId, {headers: headers})
        
    }
    getRdv(){
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('id_token_adminMedivisto'),
            'Content-Type': 'application/json'
        });
        return this.http.get(_getURL(API_CONFIG.rdvs)+'/:id', {headers: headers})
            
    }

    addRdv(newRdv){
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('id_token_adminMedivisto'),
            'Content-Type': 'application/json'
        });
        return this.http.post(_getURL(API_CONFIG.rdvs), JSON.stringify(newRdv), {headers: headers})
            
    }
    registerEventPerso(rdv){
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('id_token_adminMedivisto'),
            'Content-Type': 'application/json'
        });
    return this.http.post(_getURL(API_CONFIG.rdvs), JSON.stringify(rdv), {headers: headers})
        
    }

    registerEventAdminPerso(rdv){
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('id_token_adminMedivisto'),
            'Content-Type': 'application/json'
        });
    return this.http.post(_getURL(API_CONFIG.rdvs)+'/admin', JSON.stringify(rdv), {headers: headers})
        
    }

    deleteRdv(id){
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('id_token_adminMedivisto'),
            'Content-Type': 'application/json'
        });
        return this.http.delete(_getURL(API_CONFIG.rdvs)+'/:id', {headers: headers})
            
    }

    accepteRDV(rdv){
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('id_token_adminMedivisto'),
            'Content-Type': 'application/json'
        });
        return this.http.put(_getURL(API_CONFIG.rdvs)+'/'+rdv._id, JSON.stringify(rdv), {headers: headers})
            
    }
    editRdv(rdv){
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('id_token_adminMedivisto'),
            'Content-Type': 'application/json'
        });
    return this.http.put(_getURL(API_CONFIG.rdvs)+'/'+rdv._id, JSON.stringify(rdv), {headers: headers})
        
    }
    getMyRdv(id){
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('id_token_adminMedivisto'),
            'Content-Type': 'application/json'
        });
    return this.http.get(_getURL(API_CONFIG.rdvs)+'/'+id, {headers: headers})
        
    }
}
