<br><br>
<div class="">
  <img src="assets/medivisto-yellow.png" alt="" class="logo-admin">
</div>
<br><br><br>
<div class="row">
  <div class="col-12 col-sm-12 col-md-12  col-lg-3">
    <img src="assets/doctors.png" alt="" class="img-login-admin">

  </div>
  <div class="col-12 col-sm-12 col-md-12 col-lg-9">
    <div class="login-page">
      <div class="form">
        <div id="exTab2" class="">

          <div class="tab-content" style="padding-top: 30px">
            <div class="tab-pane active" id="1">
              <form  class="login-form" (submit)="updatePassword()">
                <p *ngIf="errorMsg" style="color: red;">{{errorMsg}}</p>
                <p *ngIf="sucessMessage" style="color:green">{{sucessMessage}}</p>
                <input type="password" name="password" [(ngModel)]="password"  value="" placeholder="Mot de passe" />
                <input type="password" name="confirmPassword" [(ngModel)]="confirmPassword"  value="" placeholder="Rétaper votre mot de passe" />
                <input type="submit"  class="btn" style="background-color: #E5B92C;font-size: 17px;font-weight: bold;color: white;" [attr.disabled]="!isValid ? true : null" value="Envoyer">
              </form>
            </div>
          </div>
          <p class="message">
            <a [routerLink]="['/login']">
              Connexion
            </a>
          </p>
        </div>
      </div>
    </div>

  </div>
</div>
