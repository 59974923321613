import { Component, OnInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
declare var $: any;
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AdminService } from 'src/app/services/admin/admin.service';
import { ValidationService } from 'src/app/services/validate/validateError.service';
import { MedecinService } from 'src/app/services/medecin/medecin.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { pays } from '../../pays/pays';
import { tri } from '../../pays/pays';
import { villes } from '../../pays/villes';
import { codepostale } from '../../pays/villes';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
@Component({
  selector: 'app-update-patient',
  templateUrl: './update-patient.component.html',
  styleUrls: ['../../medecins/medecins/medecins.component.css','../../medecins/ajout-medecin/ajout-medecin.component.scss']
})
export class UpdatePatientComponent implements OnInit {

  form: any;
  public search: string = '';
  loading = false;
  admin: any;
  patient: any;
  medecins: any[] = [];
  pays: any[] = [];
  medecinPatients: any[] = [];
  listePostal = [ ];
  listefiltrecode = ['2001','2002','2020','2022', '2027', '2032', '2035', '2036', '2037', '2041', '2056', '2057', '2058',  '2061', '2073', '2080', '2081', '2083', '2088', '2091', '2094','2095']
  listeVilles:any[]=[];
  isTunisie = true
  listefiltreville = [ ];
  selectedpays = "Tunisie"
  selectedville = ''
  selectedpostal = ''
  constructor(private medecinService: MedecinService, private adminService: AdminService,   private authService: AuthService, location: Location, private activatedRoute: ActivatedRoute, private patientService: PatientService,
    private router: Router, private formBuilder: FormBuilder) {
    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if (!this.admin.roles.gestionMedecins) {
        this.router.navigate(['/notFound']);
        return false;
      }
    },
      err => {
        console.log(err);
        return false;
      });
  }
  ngOnInit() {
    let params: any = this.activatedRoute.snapshot.params;
    this.patientService.getPatientByID(params.id).subscribe(patient => {
      this.patient = patient;
    },
      err => {
        return false;
      });
    this.pays = pays.sort(tri);

    this.form = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      prenom: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      email: ['', [Validators.required, ValidationService.emailValidator, Validators.maxLength(50)]],
      tel: ['+216', [Validators.required, ValidationService.phonenumber]],
      dateAnniversaire: ['', [Validators.required, ValidationService.dateValidator]],
      sexe: ['Homme'],
      adresse: this.formBuilder.group({
        adresse: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(500)]],
        ville: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
        pays: ['Tunisie', Validators.required],
        codePostal: ['', [Validators.required, ValidationService.numberValidator, Validators.maxLength(4)]],
      })
    })

  }
  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }
  onSelectpays(selectedpays) {
    if (selectedpays === 'Tunisie' || selectedpays === '') {
      this.isTunisie = true;
      this.listeVilles = villes.filter(x => x.pays == selectedpays);
      this.listeVilles.forEach(index =>
        this.listefiltreville = index.ville);
    } else {
      this.selectedville = undefined
      this.selectedpostal = undefined
      this.form.controls['adresse'].controls['ville'].setValue('');
      this.form.controls['adresse'].controls['codePostal'].setValue('');
      this.isTunisie = false;
    }
  }
  onSelectville(selectedville){
    this.listePostal = codepostale.filter(x => x.ville == selectedville);
    this.listePostal.forEach(index => this.listefiltrecode = index.code);
  }
  savePatient(patient) {
    this.form.value._id = patient._id
    this.patientService.editProfil(this.form.value).subscribe(patient => {
      this.patient = patient;
      Swal.fire({
        width: 500,
        background: '#e1e1e1',
        timer: 3000,
        html: '<div class="row">' +
          '<div class="col-3">' +
          '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
          '</div>' +
          '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Le patient a été MODIFIÉ avec succès</h4></div>' +
          '</div>',
        showConfirmButton: false
      }
      );
      this.router.navigate(['/patients']);
    },
      err => {
        return false;
      });

  }
}

