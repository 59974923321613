import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';
@Injectable()
export class ConseilService {

  constructor(private http: HttpClient) {}

  getAllConseils() {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.conseil), { headers: headers })

  }
  registerConseil(newEvent) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
    });
    return this.http.post(_getURL(API_CONFIG.conseil), newEvent, { headers: headers })
  }
  editConseil(conseil,id) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
    });

    return this.http.put(_getURL(API_CONFIG.conseil) + '/' + id, conseil, { headers: headers })

  }
  //get a medecin by its id
  getConseilById(id) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.conseil) + '/' + id, { headers: headers })

  }
}
