<!------------------ Liste des événements des patients ----------------------->
<div id="scrolltoverify">

</div>
<div class="informationPat3" *ngIf="showPart()==1">
  <div class="row bar">
    <div class="col-10 col-xl-4 col-lg-4 col-md-10 col-sm-10 bar-section"><a class="linkPart2"><b >Liste des événements des patients</b></a></div>
    <div class="col-10 col-xl-4 col-lg-4 col-md-10 col-sm-10 bar-section" (click)="modifPart2()"><a class="linkPart"><b>Liste des évenements des médecins</b></a></div>
  </div>
  <div class=" search4">
    <div class="row cover-search-row2">
      <div class="col-lg-5 col-md-8 col-sm-10 col-xs-10" style="margin-right: 4%">
        <div class="row rowSearch2" >
          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 name-col-10">
            <input type="text"  class="inputRecherche"
            placeholder="Recherche" [(ngModel)]="search" name="search">
          </div>
          <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 name-col-1" style="">
            <img id="name_img" src="" alt="" style="height: 25px;   margin-top: 19px; margin-left: -8px">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row rightButts">
    <div class="col-11 " > <div class=" buttoncadre0">
    </div>
    </div>
    <div class="col-1">
      <div class=" buttoncadre3"><a [routerLink]="['/nouveau/evenement']" title="Ajouter un évenement">
        <img class="iconButt2 " src="assets/plus-4.png" ></a>
      </div>
    </div>
  </div>
  <div>
    <!--<a class="param" data-toggle="modal" data-target="#myModal0">Paramètres du tableau</a>-->
  </div>
  <div class="div-tab2">
    <div class="row" style="overflow-x:auto;" >
      <table class="table  "  >
        <thead >
        <th id="firstThTable" class=" gauche text-uppercase" ><b>image</b></th>
        <th  class=" gauche text-uppercase" ><b>sujet</b></th>
        <th  class=" gauche text-uppercase" ><b>thème</b></th>
        <th  class=" gauche text-uppercase" ><b>de</b></th>
        <th  class=" gauche text-uppercase" ><b>à</b></th>
        <th class="  gauche text-uppercase" style="width: 20%;margin-right: 26px" ><b>PARAMÈTRES</b></th>
        </thead>
        <tbody>
        <tr class=" gauche" *ngFor="let evenement of evenements| paginate: { itemsPerPage: 25, currentPage: dd, id: 'dd' }|searchEvenement : search.toString().toLowerCase() ">
          <td style="width: 10%;" *ngIf="image"> <img src="{{evenement.image}}" alt="" style="width: 50%;"> </td>
          <td  class=" gauche" [routerLink]="['/evenement/details',evenement._id]">{{evenement.title}}</td>
          <td  class=" gauche" [routerLink]="['/evenement/details',evenement._id]">{{evenement.theme}}</td>
          <td  class=" gauche" [routerLink]="['/evenement/details',evenement._id]">{{evenement.date| date: 'dd/MM/yyyy à HH:mm'}}</td>
          <td  class=" gauche" [routerLink]="['/evenement/details',evenement._id]">{{evenement.dateFin| date: 'dd/MM/yyyy à HH:mm'}}</td>
          <td class="row gauche" style="border-bottom-style: none;">
            <div class="col-1" style="margin-right: 10px !important;">
              <a  title="Afficher" [routerLink]="['/evenement/details',evenement._id]" title="Détails">
                <img src="assets/voirDetails-7.png" class="iconParametres" ></a>
            </div>
            <div class="col-1" style="margin-right: 10px !important;">
              <a [routerLink]="['/evenement',evenement._id]" title="Modifier" >
                <img src="assets/pencil-7.png" class="iconParametres" ></a>
            </div>
            <div class="col-1" style="margin-right: 10px !important;">
              <a (click)="removeEvenement(evenement)" title="Supprimer" ><img src="assets/delete-7.png" class="iconParametres" ></a>
            </div>
            <!-- <div class="col-1" style="margin-right: 10px !important;">
              <a *ngIf="!evenement.show" (click)="accepterEvenement(evenement)" title="Valider" ><img src="assets/check.png" class="iconParametres" ></a>
            </div>
            <div class="col-1" >
              <a *ngIf="!evenement.show" (click)="refuserEvenement(evenement)" title="Annuler"><img src="assets/delete.png" class="iconParametres" ></a>
            </div> -->
          </td>
        </tr>
       
        </tbody>
      </table>
    </div>
  </div>
  <div class="row" *ngIf="evenements && evenements.length > 25">
    <div class="col col-xs-6">
      <pagination-controls (pageChange)="p = $event" #api></pagination-controls>
    </div>
  </div>
</div>
<!------------------ Liste des événements des médecins ----------------------->
<div class="informationPat3" *ngIf="showPart()==2">
  <div class="row bar">
    <div class="col-10 col-xl-4 col-lg-4 col-md-10 col-sm-10 bar-section"(click)="modifPart1()"><a class="linkPart"><b >Liste des événements des patients</b></a></div>
    <div class="col-10 col-xl-4 col-lg-4 col-md-10 col-sm-10 bar-section" ><a class="linkPart2"><b>Liste des évenements des médecins</b></a></div>
  </div>
  <div class=" search4">
    <div class="row cover-search-row2">
      <div class="col-lg-5 col-md-8 col-sm-10 col-xs-10" style="margin-right: 4%">
        <div class="row rowSearch2" >
          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 name-col-10">
            <input type="text" name="searchMedecins" [(ngModel)]="searchMedecins" class="inputRecherche"
                   placeholder="Rechercher">
          </div>
          <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 name-col-1" style="">
            <img id="name_img" src="" alt="" style="height: 25px;   margin-top: 19px; margin-left: -8px">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row rightButts">
    <div class="col-11 " > <div class=" buttoncadre0">
    </div>
    </div>
    <div class="col-1">
      <div class=" buttoncadre3"><a [routerLink]="['/nouveau/evenement']" title="Ajouter un évenement">
        <img class="iconButt2 " src="assets/plus-4.png" ></a>
      </div>
    </div>
  </div>
  <div>
  <!--  <a class="param" data-toggle="modal" data-target="#myModal0">Paramètres du tableau</a>-->
  </div>
  <div class="div-tab2">
    <div class="row" style="overflow-x:auto;" >
      <table class="table  "  >
        <thead >
        <th id="firstThTable" class=" gauche text-uppercase" ><b>image</b></th>
        <th  class=" gauche text-uppercase" ><b>sujet</b></th>
        <th  class=" gauche text-uppercase" ><b>thème</b></th>
        <th  class=" gauche text-uppercase" ><b>de</b></th>
        <th  class=" gauche text-uppercase" ><b>à</b></th>
        <th class="  gauche text-uppercase" style="width: 20%;margin-right: 26px" ><b>PARAMÈTRES</b></th>
        </thead>
        <tbody>
        <tr class=" gauche" *ngFor="let evenement of evenementsMedecins| paginate: { itemsPerPage: 25, currentPage: dd, id: 'dd' }|searchEvenement : searchMedecins.toString().toLowerCase() ">
          <td style="width: 10%;" *ngIf="image"> <img src="{{evenement.image}}" alt="" style="width: 50%;"> </td>
          <td  class=" gauche" [routerLink]="['/evenement/details',evenement._id]">{{evenement.title}}</td>
          <td  class=" gauche" [routerLink]="['/evenement/details',evenement._id]">{{evenement.theme}}</td>
          <td  class=" gauche" [routerLink]="['/evenement/details',evenement._id]">{{evenement.date| date: 'dd/MM/yyyy à HH:mm'}}</td>
          <td  class=" gauche" [routerLink]="['/evenement/details',evenement._id]">{{evenement.dateFin| date: 'dd/MM/yyyy à HH:mm'}}</td>
          <td class="row gauche" style="border-bottom-style: none;">
            <div class="col-1" style="margin-right: 10px !important;">
              <a [routerLink]="['/evenement/details',evenement._id]" title="Détails">
                <img src="assets/voirDetails-7.png" class="iconParametres" ></a>
            </div>
            <div class="col-1" style="margin-right: 10px !important;">
              <a [routerLink]="['/evenement',evenement._id]" title="Modifier" >
                <img src="assets/pencil-7.png" class="iconParametres" ></a>
            </div>
            <div class="col-1" >
              <a (click)="removeEvenement(evenement)" title="Supprimer" ><img src="assets/delete-7.png" class="iconParametres" ></a>
            </div>
            <!-- <div class="col-1" style="margin-right: 10px !important;">
              <a *ngIf="!evenement.show" (click)="accepterEvenement(evenement)" title="Valider" ><img src="assets/check.png" class="iconParametres" ></a>
            </div>
            <div class="col-1" >
              <a *ngIf="!evenement.show" (click)="refuserEvenement(evenement)" title="Annuler"><img src="assets/delete.png" class="iconParametres" ></a>
            </div> -->
          </td>
        </tr>
       
        </tbody>
      </table>
    </div>
  </div>
  <pagination-controls (pageChange)="dd = $event" id="dd"></pagination-controls>

</div>
<!---------------------------- Pop up supprimer évenement ---------->
<div class="modal fade" id="myModal4" tabindex="-1" role="dialog"
     aria-labelledby="myModal6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan" >
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut text-uppercase">
          SUPPRIMER évenement
        </h3>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-left: 6%;">
          <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop" ></div>
          <div class="col-10"><h4 class="textContenu text-uppercase" >VOULEZ-VOUS SUPPRIMER CEt évenement?</h4></div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <div class=" col d-flex justify-content-center buttonsPopup" >
            <div class="col-auto nbr-dossier " >
              <table>
                <tr>
                  <td class="tdButt1 ">
                    <button class="btn-valider"  data-dismiss="modal" data-toggle="modal" data-target="#deleted" ><img src="assets/delete2.png" class="iconBoutton" >Supprimer </button>
                  </td>
                  <td  class="tdButt2 " >
                    <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton" >Annuler  </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>      </div>
      </div>
    </div>  </div>
</div>
<!---------------------------- Pop up accepter évenement ---------->
<div class="modal fade" id="myModal5" tabindex="-1" role="dialog"
     aria-labelledby="myModal6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan" >
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut text-uppercase">
          accepter évenement
        </h3>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-left: 6%;">
          <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop" ></div>
          <div class="col-10"><h4 class="textContenu text-uppercase" >VOULEZ-VOUS accepter CEt évenement?</h4></div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <div class=" col d-flex justify-content-center buttonsPopup" >
            <div class="col-auto nbr-dossier " >
              <table>
                <tr>
                  <td class="tdButt1 ">
                    <button class="btn-valider"  data-dismiss="modal" data-toggle="modal" data-target="#deleted" ><img src="assets/like.png" class="iconBoutton" >Accepter </button>
                  </td>
                  <td  class="tdButt2 " >
                    <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton" >Annuler  </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>      </div>
      </div>
    </div>  </div>
</div>
<!---------------------------- Pop up refuser évenement ---------->
<div class="modal fade" id="myModal6" tabindex="-1" role="dialog"
     aria-labelledby="myModal6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan" >
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut text-uppercase">
          refuser évenement
        </h3>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-left: 6%;">
          <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop" ></div>
          <div class="col-10"><h4 class="textContenu text-uppercase" >VOULEZ-VOUS refuser CEt évenement?</h4></div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <div class=" col d-flex justify-content-center buttonsPopup" >
            <div class="col-auto nbr-dossier " >
              <table>
                <tr>
                  <td class="tdButt1 ">
                    <button class="btn-valider"  data-dismiss="modal" data-toggle="modal" data-target="#deleted" ><img src="assets/dislike.png" class="iconBoutton" >Refuser </button>
                  </td>
                  <td  class="tdButt2 " >
                    <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton" >Annuler  </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>      </div>
      </div>
    </div>  </div>
</div>
<!---------------------------- Pop up Paramètres du tableau event ---------->
<div class="modal fade" id="myModal0" tabindex="-1" role="dialog"
     aria-labelledby="myModal4" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan" >
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut text-uppercase">
          Paramètres du tableau
        </h3>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-left: 21%;">
          <div class="col-12" >
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
              <label class="form-check-label" for="flexCheckDefault">
                Thème
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
              <label class="form-check-label" for="flexCheckChecked">
                Date
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked2" checked>
              <label class="form-check-label" for="flexCheckChecked2">
                Lieu
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked4" checked>
              <label class="form-check-label" for="flexCheckChecked4">
                Lien d'inscription
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked5" checked>
              <label class="form-check-label" for="flexCheckChecked5">
                Frais d'inscription
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked6" checked>
              <label class="form-check-label" for="flexCheckChecked6">
                Image
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>  </div>
</div>
