<div class="row">
    <!---------------------------Médecins par spécialités----------------------------->
    <div class="informationPat3" id="div1">
        <div class="row bar">
            <div class="col-10 col-xl-4 col-lg-2 col-md-10 col-sm-10 bar-section"><a class="linkPart2"
                    style="border:none;"><b>Médecin par spécialité</b></a></div>
            <div class="col-10 col-xl-4 col-lg-4 col-md-10 col-sm-10 bar-section" (click)="modifPart2()"><a
                    class="linkPart" style="border:none;" (click)="scroll('div2')"><b>Nombre d'utilisateur par
                        mois</b></a></div>
        </div>
    </div>
    <div class=" col-11 col-xl-11 col-lg-11 col-md-11 col-sm-11">
        <div id="divChart">
            <canvas id="myChart"></canvas>
        </div>
    </div>

    <!--<div id="divChart3" >
        <canvas id="myChart3"></canvas>
      </div>-->
    <!--------------------------Nombre d'utilisateur par mois------------------------------>
    <div class="informationPat3" id="div2">
        <div class="row bar">
            <div class="col-10 col-xl-4 col-lg-4 col-md-10 col-sm-10 bar-section" (click)="modifPart1()"><a
                    class="linkPart" style="border:none;" (click)="scroll('div1')"><b>Médecin par spécialité</b></a>
            </div>
            <div class="col-10 col-xl-4 col-lg-4 col-md-10 col-sm-10 bar-section"><a class="linkPart2"
                    style="border:none;"> <b>Nombre d'utilisateur par mois</b></a></div>
        </div>
    </div>
    <div class=" col-11 col-xl-11 col-lg-11 col-md-11 col-sm-11">
        <div id="divChart2">
            <canvas id="myChart2"></canvas>
        </div>
    </div>

    <!---------------------------Utilsateurs et Rdvs par Villes----------------------------->
</div>