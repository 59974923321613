<div class="all" *ngIf="medecin">
    <div class="row" style="margin-bottom: -18px;">
      <div class="col-12">
        <div class="row pat">
          <div class="col-12">
            <b>{{medecin.nom}} {{medecin.prenom}}</b>
          </div>
        </div>
      </div>
    </div>
    <div class="informationPat2">
      <img src="assets/man.png" class="imgMed">
    </div>
    <div class="informationPat row">
      <div class="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12">
        <div class="row infoTitre">
          <div class=" infMed" >
            <b class="ficheNum">Informations Personnelles : </b>
          </div>
        </div>
        <div class=" informationsPat">
    
            <span><b>Nom complet  : </b></span> <span style="text-transform: capitalize;">{{medecin.nom}} {{medecin.prenom}}</span> <br>
            <span><b>Email  : </b></span> <span>{{medecin.email}}</span> <br>
            <span><b>N° d'inscription au conseil de l'ordre : </b></span> <span>{{medecin.numInscription}}</span> <br>
            <span><b>N° mobile : </b></span> <span>{{medecin.tel}}</span> <br>
            <span><b>N° fixe : </b></span> <span>{{medecin.telFixe}}</span> <br>
            <span><b>N° mobile privé : </b></span> <span *ngIf="medecin.telPerso?.length > 4">{{medecin.telPerso}}</span> <br>
            <span><b>Diplôme  : </b></span> <span style="text-transform: capitalize;">{{medecin.diplome}}</span> <br>
            <span><b>Spécialité  : </b></span> <span style="text-transform: capitalize;">{{medecin.specialite}}</span> <br>
            <span *ngIf="medecin.dateInscription"><b>Date d'inscription </b></span> <span>{{medecin.dateInscription | date:'dd/MM/yyyy'}} <br></span>

        </div>
      </div>
      <div class="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12">
        <div class="row infoTitre">
          <div class=" infMed" >
            <b class="ficheNum">Adresse du cabinet : </b>
          </div>
        </div>
        <div class=" informationsPat">
            <span><b>Pays  : </b></span> <span *ngIf="medecin.adresseCabinet" style="text-transform: capitalize;">{{medecin.adresseCabinet.pays}}</span> <br>
            <span><b>Ville  : </b></span> <span *ngIf="medecin.adresseCabinet" style="text-transform: capitalize;">{{medecin.adresseCabinet.ville}}</span> <br>
            <span><b>Code postal  : </b></span> <span *ngIf="medecin.adresseCabinet">{{medecin.adresseCabinet.codePostal}}</span> <br>
    
            <span><b>N° : </b></span> <span *ngIf="medecin.adresseCabinet">{{medecin.adresseCabinet.numero}}</span> <br>
            <span><b>Rue : </b></span> <span *ngIf="medecin.adresseCabinet">{{medecin.adresseCabinet.rue}}</span> <br>
        </div>
      </div>
    <div class="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <div class="row infoTitre">
        <div class=" infMed" >
          <b class="ficheNum">Autres Informations : </b>
        </div>
      </div>
      <div class=" informationsPat">
         <span><b>Notes : </b></span> <span>{{medecin.notes}}</span> <br>
          <span><b>Code CNAM  : </b></span> <span>{{medecin.codeCNAM}} </span> <br>
          <span><b>Centre CNAM  : </b></span> <span>{{medecin.centreCNAM}}</span> <br>
          <span><b>Date de début en fonction : </b></span> <span>{{medecin.dateDebutFonction}}</span> <br>
      </div>
    </div>
    </div>
    </div>
    