import { Component, OnInit, SecurityContext } from '@angular/core';
declare var $: any;
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { API_CONFIG } from '../../../../../../config/API_CONFIG';
import { _getURL } from '../../../../../../config/API_CONFIG';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AdminService } from 'src/app/services/admin/admin.service';
import { MedecinService } from 'src/app/services/medecin/medecin.service';
import { sort } from '../../search/sortTable';
import { sortTable } from '../../search/sortTable';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { DomSanitizer } from '@angular/platform-browser';
import {specialites} from '../../pays/specialites'
import { villes } from '../../pays/villes';
@Component({
  selector: 'app-medecins',
  templateUrl: './medecins.component.html',
  styleUrls: ['./medecins.component.css']
})
export class MedecinsComponent implements OnInit {

  form: any;
  public searchNom: string = '';
  public searchPrenom: string = '';
  public searchSpecialite: string = '';
  public searchEmail: string = '';
  public searchInscription: string = '';
  public searchMd: string = '';
  public searchMedEnAttente: string = '';
  showMyContainer = 1;
  evenement: any;
  admin: any;
  RolesAdmin: any[] = [];
  medecins: any[] = [];
  medecin: any;
  interval: any;
  updatedNom: any;
  updatedPrenom: any;
  showAcceptedMedecins: any;
  showAttendMedecins: any;
  showAttendMedecinsScraped: any;
  showAttendMedecinsScrapedAttente: any;
  medecinsNotAccepted: any[] = [];
  medecinsNotAcceptedFixed:any[]=[]
  medecinsScrapedEnattente: any[] = [];
  medecinsScraped: any[] = [];
  specialite: any;
  email: any;
  numInscription: any;
  adresse: any;
  diplome: any;
  tel: any;
  pd = 1;
  p = 1;
  pc = 1;
  medecinToDelete: any;
  lengthmedecinsScraped = 0
  pageNumber = 0
  timeout: any = null;
  typeOfListMedecins: any;
  fixedMedecin: any[] = []
  addmore = true;
  specialites:any[]=[]
  villes:any[]=[]
  medecinsApi:any[]=[]
  firstReload = false;
  medecinsApiFixed:any[]=[]
  constructor(private sanitizer: DomSanitizer,private medecinService: MedecinService, private adminService: AdminService, private http: HttpClient, private authService: AuthService, private activatedRoute: ActivatedRoute,
    private router: Router) {
    this.authService.getProfileAdmin().subscribe((profile: any) => {
      this.admin = profile.admin;
      if (!this.admin.roles.gestionMedecins) {
        this.router.navigate(['/notFound']);
        return false;
      }
    },
      err => {
        return false;
      });
  }
  ngOnInit() {
    let el = document.getElementById('scrolltoverify');
    this.specialites = specialites
    this.villes=villes[0].ville;
    el.scrollIntoView({ behavior: "smooth" });
    this.showAcceptedMedecins = false;
    this.showAttendMedecins = false;
    this.specialite = true;
    this.email = true;
    this.numInscription = true;
    var adminString = localStorage.getItem('admin');
    var adminId = JSON.parse(adminString).id;
    var adminNom = JSON.parse(adminString).nom;
    var adminPrenom = JSON.parse(adminString).prenom;
    this.updatedNom = adminNom.charAt(0).toUpperCase() + adminNom.slice(1);
    this.updatedPrenom = adminPrenom.charAt(0).toUpperCase() + adminPrenom.slice(1);

    this.medecinService.getMedecinScapedBypage(this.pageNumber, 10).subscribe((medecins: any[]) => {
      this.medecinsScraped = medecins;
      this.firstReload = true;
      this.medecinsScraped = this.medecinsScraped.sort(this.triMedecins);
      this.getNexMedecin()
    },
      err => {
        return false;
      });
    this.medecinService.getMedecinScapedcount().subscribe((length: any) => {
      this.lengthmedecinsScraped = length;
    },
      err => {
        return false;
      });
    this.showAttendMedecinsScraped = true;
    this.pd = 1
    this.p = 1
    this.pc = 1
    this.activatedRoute.queryParamMap.subscribe((paramMap) => {
      const refresh = paramMap.get('refresh');
      if (refresh) {
        var numberPage = Number(localStorage.getItem("medecinIntegreNumberPage"))
        if (numberPage && !isNaN(numberPage)) {
          this.pd = numberPage
          this.p = numberPage
          this.pc = numberPage
        }

      }
    });
    let lastVisited = localStorage.getItem("lastVisited")
    if (lastVisited == "1") {
      this.showMyContainer = 2
      this.modifPart1();
      return true;
    }
    else if (lastVisited == "2") {
      this.modifPart2();
      return true;
    }else if (lastVisited == "4") {
      this.modifPart4();
      return true;
    } else {
      this.modifPart3()
      return true;
    }


  }
  getNexMedecin() {
    this.medecinService.getMedecinAccepted().subscribe((medecinss: any) => {
      this.medecins = medecinss;
      this.fixedMedecin = medecinss
      this.medecins = this.medecins.sort(this.triMedecins);
      this.medecinService.getMedecinNotAccepted().subscribe((medecins: any) => {
        this.medecinsNotAccepted = medecins;
        this.medecinsNotAcceptedFixed= medecins
        this.medecinsNotAccepted = this.medecinsNotAccepted.sort(this.triMedecins);
        //this.getLastListMedecin()
      },
        err => {
          return false;
        });

    },
      err => {
        return false;
      });


  }
  getLastListMedecin() {
    /*this.medecinService.getMedecinScapedNotAccepted().subscribe(medecinsss => {
      this.medecinsScrapedEnattente = medecinsss;

    },
      err => {
        return false;
      });*/

  }
  removeMedecin() {
    var medecin = this.medecinToDelete
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    this.http.delete(_getURL(API_CONFIG.medecin) + '/' + medecin._id, { headers: headers }).subscribe((res: any) => {
      this.medecins.splice(this.medecins.indexOf(medecin), 1);
      Swal.fire({
        width: 500,
        background: '#e1e1e1',
        timer: 3000,
        html: '<div class="row">' +
          '<div class="col-3">' +
          '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
          '</div>' +
          '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Le médecin a été supprimé avec succès</h4></div>' +
          '</div>',
        showConfirmButton: false
      }
      );
    })
  }
  removeMedecinIntegre() {
    var medecin = this.medecinToDelete
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    this.http.delete(_getURL(API_CONFIG.medecinScraped) + '/' + medecin._id, { headers: headers }).subscribe((res: any) => {
      this.medecinsScraped.splice(this.medecinsScraped.indexOf(medecin), 1);
      Swal.fire({
        width: 500,
        background: '#e1e1e1',
        timer: 3000,
        html: '<div class="row">' +
          '<div class="col-3">' +
          '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
          '</div>' +
          '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Le médecin a été supprimé avec succès</h4></div>' +
          '</div>',
        showConfirmButton: false
      }
      );
    })

  }
  removeMedecinIntegreNotAccepeted(medecin) {
    var medecin = this.medecinToDelete
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    this.http.delete(_getURL(API_CONFIG.medecinScraped) + '/' + medecin._id, { headers: headers }).subscribe((res: any) => {
      this.medecinsScraped.splice(this.medecinsScraped.indexOf(medecin), 1);
      this.medecinsScrapedEnattente.splice(this.medecinsScrapedEnattente.indexOf(medecin), 1);
      Swal.fire({
        width: 500,
        background: '#e1e1e1',
        timer: 3000,
        html: '<div class="row">' +
          '<div class="col-3">' +
          '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
          '</div>' +
          '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Le médecin a été supprimé avec succès</h4></div>' +
          '</div>',
        showConfirmButton: false
      }
      );
    })
  }
  accepteMedecinIntegre() {
    var medecin = this.medecinToDelete
    var newMedecin = {
      _id: medecin._id,
      accepted: true
    }
    this.medecinService.updateMedecinIntegre(newMedecin).subscribe(medecin => {
      Swal.fire({
        width: 500,
        background: '#e1e1e1',
        timer: 3000,
        html: '<div class="row">' +
          '<div class="col-3">' +
          '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
          '</div>' +
          '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Le médecin a été Ajouté avec succès</h4></div>' +
          '</div>',
        showConfirmButton: false
      }
      );
    });
  }
  accepteMedecin() {
    var medecin = this.medecinToDelete
    var newMedecin = {
      _id: medecin._id,
      email: medecin.email,
      accepted: true
    }
    this.medecinService.editAcceptedMedecin(newMedecin).subscribe(medecin => {
      this.medecin = medecin;
      Swal.fire({
        width: 500,
        background: '#e1e1e1',
        timer: 3000,
        html: '<div class="row">' +
          '<div class="col-3">' +
          '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
          '</div>' +
          '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Le médecin a été Ajouté avec succès</h4></div>' +
          '</div>',
        showConfirmButton: false
      }
      );
    });
  }
  removeMedecinAttente() {
    var medecin = this.medecinToDelete
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    this.http.delete(_getURL(API_CONFIG.medecin) + '/' + medecin._id + '/' + medecin.email, { headers: headers }).subscribe((res: any) => {
      this.medecinsNotAccepted.splice(this.medecinsNotAccepted.indexOf(medecin), 1);
      Swal.fire({
        width: 500,
        background: '#e1e1e1',
        timer: 3000,
        html: '<div class="row">' +
          '<div class="col-3">' +
          '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
          '</div>' +
          '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Le médecin a été supprimé avec succès</h4></div>' +
          '</div>',
        showConfirmButton: false
      }
      );
    })
  }
  sortTable(n) {
    sortTable(n)
  }
  sort(n) {
    sort(n)
  }
  triMedecins(a, b) {
    if (a.nom < b.nom) return -1;
    else if (a.nom == b.nom) return 0;
    else return 1;
  }
  pageChanged(event) {
    localStorage.setItem("medecinIntegreNumberPage", event)
  }
  callListMedecinsAccepted() {
    this.typeOfListMedecins = 1
    localStorage.setItem("lastVisited", this.typeOfListMedecins)
  }
  modifPart1() {
    this.typeOfListMedecins = 1
    this.searchEmail = "";
    this.searchInscription = "";
    this.searchNom = "";
    this.searchPrenom = "";
    this.searchSpecialite = "";
    this.medecins = this.fixedMedecin
    localStorage.setItem("lastVisited", this.typeOfListMedecins)
    if (this.showMyContainer !== 1) {
      this.showMyContainer = 1;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart2() {
    this.searchEmail = "";
    this.searchInscription = "";
    this.searchNom = "";
    this.searchPrenom = "";
    this.searchSpecialite = "";
    this.typeOfListMedecins = 2
    this.medecinsNotAccepted = this.medecinsNotAcceptedFixed
    localStorage.setItem("lastVisited", this.typeOfListMedecins)
    if (this.showMyContainer !== 2) {
      this.showMyContainer = 2;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart3() {
    this.typeOfListMedecins = 3
    this.searchEmail = "";
    this.searchInscription = "";
    this.searchNom = "";
    this.searchPrenom = "";
    this.searchSpecialite = "";
    this.resetFiltersIntegre()
    localStorage.setItem("lastVisited", this.typeOfListMedecins)
    if (this.showMyContainer !== 3) {
      this.showMyContainer = 3;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart4(){
    this.searchEmail = "";
    this.searchInscription = "";
    this.searchNom = "";
    this.searchPrenom = "";
    this.searchSpecialite = "";
    this.typeOfListMedecins = 4
    this.medecinsNotAccepted = this.medecinsNotAcceptedFixed
    localStorage.setItem("lastVisited", this.typeOfListMedecins)
    if (this.showMyContainer !== 4) {
      this.showMyContainer = 4;
    }
    else {
      this.showMyContainer = 0;
    }
    this.getDataFromApiMedecin()
  }
  getDataFromApiMedecin(){
    this.medecinService.getDataFromApiMedecin().subscribe((medecins: any) => {
     this.medecinsApi=medecins
     this.medecinsApiFixed=medecins
    },
      err => {
        return false;
      });
  }
  showPart() {
    return this.showMyContainer;
  }
  setMedecinTodelet(medecin) {
    this.medecinToDelete = medecin
  }
  onScroll() {
    setTimeout(() => {    //<<<---    using ()=> syntax
      this.addData();
    }, 1000);
  }
  addData() {
    if(!this.addmore){
      return false;
    }
    if (this.medecinsScraped && this.lengthmedecinsScraped === this.medecinsScraped.length) {
      return false;
    }
    this.pageNumber = this.pageNumber + 1
    this.medecinService.getMedecinScapedBypage(this.pageNumber, 10).subscribe((medecins: any[]) => {
      if (medecins) {
        for (var j = 0; j < medecins.length; j++) {
          this.medecinsScraped.push(medecins[j]);
        }
      }
      this.medecinsScraped = this.medecinsScraped.sort(this.triMedecins);
    },
      err => {
        return false;
      });
  }

  // filter and search commandes

  getRechercheResult() {
    this.addmore = false;
    const sanitizedName = this.sanitizer.sanitize(SecurityContext.HTML, this.searchNom);
    const sanitizedFirstName = this.sanitizer.sanitize(SecurityContext.HTML, this.searchPrenom);
    const sanitizedInscription = this.sanitizer.sanitize(SecurityContext.HTML, this.searchInscription?.toString());
    const sanitizedSpecialite = this.sanitizer.sanitize(SecurityContext.HTML, this.searchSpecialite?.toString());
    const sanitizedEmail = this.sanitizer.sanitize(SecurityContext.HTML, this.searchEmail);

    this.medecinService.getMedecinScapedBypageAndRechercheWithLimite(sanitizedName,sanitizedFirstName,sanitizedInscription,sanitizedSpecialite,sanitizedEmail).subscribe((data: any[]) => {
      this.medecinsScraped = data
    },
      err => {
        return false;
      });
    return false;
  }
  // redirection to add doctor based in existed scraped médecin 
  redirectionToaddMedecin(id) {
    if (id && id != "" && id != undefined && id != null) {
      localStorage.setItem("idDoctorExisted", id)
      this.router.navigate(['/ajoutMedecin']);
    }
  }
  // filter liste médeicns 
  filter() {
    this.medecins = this.fixedMedecin;
    let filteredMedecins = [...this.fixedMedecin];
    // Convert filter criteria to lowercase
    const searchNom = this.searchNom ? this.searchNom.toLowerCase() : '';
    const searchPrenom = this.searchPrenom ? this.searchPrenom.toLowerCase() : '';
    const searchEmail = this.searchEmail ? this.searchEmail.toLowerCase() : '';
    const searchInscription = this.searchInscription ? this.searchInscription.toLowerCase() : '';
    const searchSpecialite = this.searchSpecialite ? this.searchSpecialite.toLowerCase() : '';
    filteredMedecins = this.medecins.filter(item => {
      const nomFilter = searchNom ? (item.nom ? item.nom.toLowerCase().includes(searchNom) : false) : true;
      const prenomFilter = searchPrenom ? (item.prenom ? item.prenom.toLowerCase().includes(searchPrenom) : false) : true;
      const specialiteFilter = searchSpecialite ? (item.specialite ? item.specialite.toLowerCase().includes(searchSpecialite) : false) : true;
      const emailFilter = searchEmail ? (item.email ? item.email.toLowerCase().includes(searchEmail) : false) : true;
      const inscriptionFilter = searchInscription ? ((item.numInscription ? item.numInscription.toLowerCase() : '').replace(/\s/g, '')).includes(searchInscription) : true;

      return nomFilter && prenomFilter && specialiteFilter && emailFilter && inscriptionFilter
    });

    this.medecins = filteredMedecins;
  }
  filterApi(){
    this.medecinsApi = this.medecinsApiFixed;
    let filteredMedecins = [...this.medecinsApiFixed];
    // Convert filter criteria to lowercase
    const searchNom = this.searchNom ? this.searchNom.toLowerCase() : '';
    const searchPrenom = this.searchPrenom ? this.searchPrenom.toLowerCase() : '';
    const searchInscription = this.searchInscription ? this.searchInscription.toLowerCase() : '';
    const searchSpecialite = this.searchSpecialite ? this.searchSpecialite.toLowerCase() : '';
    filteredMedecins = this.medecinsApi.filter(item => {
      const nomFilter = searchNom ? (item.NOM ? item.NOM.toLowerCase().includes(searchNom) : false) : true;
      const prenomFilter = searchPrenom ? (item.PRENOM ? item.PRENOM.toLowerCase().includes(searchPrenom) : false) : true;
      const specialiteFilter = searchSpecialite ? (item.SPECIALITE ? item.SPECIALITE.toLowerCase().includes(searchSpecialite) : false) : true;
      const inscriptionFilterGov = searchInscription ? (item.GOUVERNORAT ? item.GOUVERNORAT.toLowerCase().includes(searchInscription) : false) : true;
      return nomFilter && prenomFilter && specialiteFilter  && inscriptionFilterGov 
    });

    this.medecinsApi = filteredMedecins;
  }
  filterEnattente() {
    this.medecinsNotAccepted = this.medecinsNotAcceptedFixed;
    let filteredMedecins = [...this.medecinsNotAcceptedFixed];
    // Convert filter criteria to lowercase
    const searchNom = this.searchNom ? this.searchNom.toLowerCase() : '';
    const searchPrenom = this.searchPrenom ? this.searchPrenom.toLowerCase() : '';
    const searchEmail = this.searchEmail ? this.searchEmail.toLowerCase() : '';
    const searchInscription = this.searchInscription ? this.searchInscription.toLowerCase() : '';
    const searchSpecialite = this.searchSpecialite ? this.searchSpecialite.toLowerCase() : '';
    filteredMedecins = this.medecinsNotAccepted.filter(item => {
      const nomFilter = searchNom ? (item.nom ? item.nom.toLowerCase().includes(searchNom) : false) : true;
      const prenomFilter = searchPrenom ? (item.prenom ? item.prenom.toLowerCase().includes(searchPrenom) : false) : true;
      const specialiteFilter = searchSpecialite ? (item.specialite ? item.specialite.toLowerCase().includes(searchSpecialite) : false) : true;
      const emailFilter = searchEmail ? (item.email ? item.email.toLowerCase().includes(searchEmail) : false) : true;
      const inscriptionFilter = searchInscription ? ((item.numInscription ? item.numInscription.toLowerCase() : '').replace(/\s/g, '')).includes(searchInscription) : true;

      return nomFilter && prenomFilter && specialiteFilter && emailFilter && inscriptionFilter
    });

    this.medecinsNotAccepted = filteredMedecins;
  }
  resetFilters() {
    this.searchEmail = "";
    this.searchInscription = "";
    this.searchNom = "";
    this.searchPrenom = "";
    this.searchSpecialite = "";
    this.medecins = this.fixedMedecin
  }
  resetFiltersEnattente() {
    this.searchEmail = "";
    this.searchInscription = "";
    this.searchNom = "";
    this.searchPrenom = "";
    this.searchSpecialite = "";
    this.medecinsNotAccepted = this.medecinsNotAcceptedFixed
  }
  resetfilterApi(){
    this.searchEmail = "";
    this.searchInscription = "";
    this.searchNom = "";
    this.searchPrenom = "";
    this.searchSpecialite = "";
    this.medecinsApi = this.medecinsApiFixed
  }
  resetFiltersIntegre() {
    this.searchEmail = "";
    this.searchInscription = "";
    this.searchNom = "";
    this.searchPrenom = "";
    this.searchSpecialite = "";
    this.addmore = true;
    this.medecinsScraped = [];
    while (this.medecinsScraped.length) {
      this.medecinsScraped.pop();
    }
    if(this.firstReload){
      this.pageNumber = 0
    this.medecinService.getMedecinScapedBypage(this.pageNumber, 10).subscribe((medecins: any[]) => {
      if (medecins) {
        for (var j = 0; j < medecins.length; j++) {
          this.medecinsScraped.push(medecins[j]);
        }
      }
      this.medecinsScraped = this.medecinsScraped.sort(this.triMedecins);
    },
      err => {
        return false;
      });
    }
    
    return false;
  }

}

