import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'admin-medivisto';
  token:any;
  admin:any;
  constructor(private authService:AuthService,private router:Router){
    this.token = localStorage.getItem('id_token_adminMedivisto');
    if (!this.token) {
      localStorage.clear();
      this.router.navigate(['/login'])
      return;
    }
    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if (!this.admin) {
        localStorage.clear();
        this.router.navigate(['/login'])
        return false;
      }
      if(this.admin){
        if(this.router.url === "/"){
          this.router.navigate(["/accueil"])
          return false;
        }
        this.router.navigate([this.router.url])
      }
    },
      err => {
        localStorage.clear();
        this.router.navigate(['login']);
        return false;
      });
  }
}
