import { Component, OnInit } from '@angular/core';
declare var $: any;
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { API_CONFIG } from 'config/API_CONFIG';
import { _getURL } from 'config/API_CONFIG';
import { sortTable } from '../../search/sortTable';
import { FileUploader } from 'ng2-file-upload';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConseilService } from 'src/app/services/conseil/conseil.service';


@Component({
  selector: 'app-conseils',
  templateUrl: './conseils.component.html',
  styleUrls: ['./conseils.component.css', '../../medecins/medecins/medecins.component.css']
})
export class ConseilsComponent implements OnInit {

  public search: any = '';
  form: any;
  conseils: any[];
  admin: any;
  idEvent: any;
  conseil: any;
  p: any;
  myfileUpdate: any;
  public uploader: FileUploader = new FileUploader({ url: _getURL(API_CONFIG.image) + '/conseil/' + this.idEvent });
  sizeLimitOne = 5000000; //5MB
  public loading = false;
  conseilTodelete: any;
  files: any[];
  constructor(private conseilService: ConseilService, private http: HttpClient, private authService: AuthService, private activatedRoute: ActivatedRoute,
    private router: Router, private formBuilder: FormBuilder) {
    this.authService.getProfileAdmin().subscribe((profile: any) => {
      this.admin = profile.admin;
      if (!this.admin.roles.gestionConseils) {
        this.router.navigate(['/notFound']);
        return false;
      }
    })
  }
  ngOnInit() {
    let el = document.getElementById('scrolltoverify');
    el.scrollIntoView({ behavior: "smooth" });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    }
    this.conseilService.getAllConseils().subscribe((conseils: any[]) => {
        this.conseils = conseils;
        for (var j = 0; j < this.conseils.length; j++) {
          if (this.conseils[j] && this.conseils[j].image && this.conseils[j].image.indexOf("https://www.file.medivisto.com") > -1) {
            this.conseils[j].image = this.conseils[j].image.replace("https://www.file.medivisto.com", _getURL('').replace("api", "") + 'data/files')
          }
          if (this.conseils[j] && this.conseils[j].image && this.conseils[j].image.indexOf("https://www.file.medivisto.com") < 0) {
            this.conseils[j].image = _getURL('').replace("api", "") + 'data/files/conseil/' + this.conseils[j].image
          }
        }
      },
      err => {
        return false;
      });
    this.form = this.formBuilder.group({
      titre: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(5000)]],
      categorie: ['', Validators.required],
      description: ['', [Validators.required, Validators.maxLength(10000)]],
      cible: ['Patient', Validators.required],
      image: [''],
    });
  }
  sortTable(n) {
    sortTable(n)
  }
  saveSujet() {
    if (this.form.value.cible === "Les deux") {
      this.form.value.doubleConseil = true;
    }
    if (this.form.value.cible === "Patient") {
      this.form.value.patientConseil = true;
    }
    if (this.form.value.cible === "Médecin") {
      this.form.value.patientConseil = false;
    }
    const formData: FormData = new FormData();
    if (this.files && this.files.length > 0) {
      let file = this.files[0]
      const originalFileName = file.name;
      let fileExtension = this.getFileExtension(originalFileName);
      fileExtension = '.' + fileExtension
      const timestamp = new Date().toISOString().replace(/[-T:]/g, '').split('.')[0];
      const newFileName = `file_${timestamp}${fileExtension}`
      const newFile: File = new File([file], encodeURIComponent(newFileName), { type: file.type });
      this.form.value.image = newFileName
      formData.append('file', newFile);
    }
    formData.append('objectConseil', JSON.stringify(this.form.value))
    this.loading = true;
    this.conseilService.registerConseil(formData)
      .subscribe((data: any) => {
        if (data && data.image && data.image.indexOf("https://www.file.medivisto.com") > -1) {
          data.image = data.image.replace("https://www.file.medivisto.com", _getURL('').replace("api", "") + 'data/files')
        }
        if (data && data.image && data.image.indexOf("https://www.file.medivisto.com") < 0) {
          data.image = _getURL('').replace("api", "") + 'data/files/conseil/' + data.image
        }
        this.conseils.push(data);
        this.files = []
        while (this.files.length > 0) {
          this.files.pop()
        }
        this.form.reset();
        Swal.fire({
          width: 500,
          background: '#e1e1e1',
          timer: 3000,
          html: '<div class="row">' +
            '<div class="col-3">' +
            '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
            '</div>' +
            '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Le conseil a été AJOUTÉ avec succès</h4></div>' +
            '</div>',
          showConfirmButton: false
        });
        this.loading = false;
      });
  }
  removeConseil() {
    var conseil = this.conseilTodelete
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    this.http.delete(_getURL(API_CONFIG.conseil) + '/' + conseil._id, { headers: headers }).subscribe((res: any) => {
      this.conseils.splice(this.conseils.indexOf(conseil), 1);
      Swal.fire({
        width: 500,
        background: '#e1e1e1',
        timer: 3000,
        html: '<div class="row">' +
          '<div class="col-3">' +
          '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
          '</div>' +
          '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Le conseil a été supprimé avec succès</h4></div>' +
          '</div>',
        showConfirmButton: false
      });
    })
  }
  updateConseil(conseil) {
    const formData: FormData = new FormData();
    if (this.files && this.files.length > 0) {
      let file = this.files[0]
      const originalFileName = file.name;
      let fileExtension = this.getFileExtension(originalFileName);
      fileExtension = '.' + fileExtension
      const timestamp = new Date().toISOString().replace(/[-T:]/g, '').split('.')[0];
      const newFileName = `file_${timestamp}${fileExtension}`
      const newFile: File = new File([file], encodeURIComponent(newFileName), { type: file.type });
      conseil.image = newFileName
      formData.append('file', newFile);
    }
    formData.append('objectConseil', JSON.stringify(conseil))
    this.loading = true;
    this.conseilService.editConseil(formData,conseil._id).subscribe((conseil: any) => {
      if (conseil && conseil.image && conseil.image.indexOf("https://www.file.medivisto.com") > -1) {
        conseil.image = conseil.image.replace("https://www.file.medivisto.com", _getURL('').replace("api", "") + 'data/files')
      }
      if (conseil && conseil.image && conseil.image.indexOf("https://www.file.medivisto.com") < 0) {
        conseil.image = _getURL('').replace("api", "") + 'data/files/conseil/' + conseil.image
      }
      this.conseil = conseil;
      this.files = []
      while (this.files.length > 0) {
        this.files.pop()
      }
      location.reload()
      Swal.fire({
        width: 500,
        background: '#e1e1e1',
        timer: 3000,
        html: '<div class="row">' +
          '<div class="col-3">' +
          '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
          '</div>' +
          '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Le conseil a été Modifié avec succès</h4></div>' +
          '</div>',
        showConfirmButton: false
      });
    });
  }
  scroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({ behavior: "smooth" });
  }
  setConseilTodelete(conseil) {
    this.conseilTodelete = conseil
  }
  // controles added files
  droppedFile(files) {
    this.files = []
    while (this.files.length > 0) {
      this.files.pop()
    }
    this.files = files.target.files;
    if (!this.files || (this.files && this.files.length == 0)) {
      return false;
    }
    const validExtensions = ['png', 'jpg', 'jpeg'];
    for (var j = 0; this.files.length; j++) {
      if (this.files[j] && this.files[j] != undefined) {
        const fileExtension = this.files[j].name.split('.').pop().toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
          Swal.fire({
            width: 500,
            background: '#e1e1e1',
            timer: 3000,
            html: '<div class="row">' +
              '<div class="col-3">' +
              '<img src="assets/error-4.png" style="height:60px; margin-left: 20px">' +
              '</div>' +
              '<div><h4 class="text-uppercase" style="margin-top: 23px; font-size:16px">Le fichier doit être de type png, jpg ou jpeg.</h4></div>' +
              '</div>',
            showConfirmButton: false
          })
          this.files = []
          while (this.files.length > 0) {
            this.files.pop()
          }
          return false;
        }
        if (this.files[j].size / (1024 * 1024) > 50) {
          Swal.fire({
            width: 500,
            background: '#e1e1e1',
            timer: 3000,
            html: '<div class="row">' +
              '<div class="col-3">' +
              '<img src="assets/error-4.png" style="height:60px; margin-left: 20px">' +
              '</div>' +
              '<div><h4 class="text-uppercase" style="margin-top: 23px; font-size:16px">Ce fichier sélectionné est trop volumineux.La taille maximale est 50 Mo !</h4></div>' +
              '</div>',
            showConfirmButton: false
          })
          this.files = []
          while (this.files.length > 0) {
            this.files.pop()
          }
          return false;
        }
      } else {
        return false;
      }

    }
  }
  getFileExtension(fileName: string): string {
    return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
  }
}
