import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticleService } from 'src/app/services/article/article.service';
import tinymce from 'tinymce';
import * as CryptoJS from 'crypto-js';
import { API_CONFIG, _getURL } from 'config/API_CONFIG';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
@Component({
  selector: 'app-update-article',
  templateUrl: './update-article.component.html',
  styleUrls: ['../ajout-article/ajout-article.component.css','../../patients/ajouter-patients-med/ajouter-patients-med.component.css', '../../medecins/medecins/medecins.component.css'],
})
export class UpdateArticleComponent implements OnInit {
  tags:any[]=[]
  baseUrl=_getURL(API_CONFIG.uploadArticle)
  style1 = "#dc3545";
  style2 = "bold";
  style3 = "small";
  style4 = "green"
  submitted = false;
  categorieList = ['Maladie', 'Nutrition', 'Actualités médicales','Médicaments et traitements','Santé mentale','Beauté et bien-être',
  'Découvertes scientifiques et technologiques','Prévention et mode de vie sain'] 
  imageCouverture: File
  imageDescription: File
  editorInstance: any;
  idArticleCrypte:String;
  decryptedId:String;
  article:any
  showSizeErrorCouv: boolean = false;
  showSizeErrorDesc: boolean = false;
  showRequiredErrorCouv: boolean = false;
  showRequiredErrorDesc: boolean = false;
  errorDescription = false
  editor;
  @Output() onEditorContentChange = new EventEmitter();
  loading:any
  errorDate = false;
  successRegister:any
  errormessageMotCles:any
  mydata:any[]=[]
  form = this.fb.group({
    categorie: ["", [Validators.required]],
    titre: ["", [Validators.required]],
    date: [, [Validators.required]],
    slogan: ["", [Validators.required,Validators.maxLength(100)]],
    description: ["", [Validators.required]],
    motCles: [],
    visibilite:[false, [Validators.required]],
    /////pour assurer le controle de saisie des champs files
    fileCover:[null,[,Validators.pattern("[^\\s]+(.*?)\\.(png|PNG|gif|GIF|jpg|JPG|jpeg|JPEG )$")]],
    filedescription:[null,[,Validators.pattern("[^\\s]+(.*?)\\.(png|PNG|gif|GIF|jpg|JPG|jpeg|JPEG )$")]]

  })
  activeColor: string = 'green';
  baseColor: string = '#ccc';
  overlayColor: string = 'rgba(255,255,255,0.5)';
  dragging: boolean = false;
  loaded: boolean = false;
  imageLoaded: boolean = false;
  imageSrcCouverture: string = '';
  imageSrcDescription:string = '';
  handleDragEnter() {
      this.dragging = true;
  }
  
  handleDragLeave() {
      this.dragging = false;
  }
   handleDragOverCouv(event: DragEvent) {
    event.preventDefault();
    this.dragging = true;
    

  }

  handleDragOverDesc(event: DragEvent) {
    event.preventDefault();
    this.dragging = true;

  }

  handleDropCouv(e: DragEvent) {
    e.preventDefault();
    this.dragging = false;
    this.imageCouverture = e.dataTransfer.files[0]
    // Vérifier la taille de l'image
    if (this.imageCouverture.size > 2000000) {
      // La taille de l'image dépasse la limite autorisée
      this.showSizeErrorCouv = true;
      return; // Arrêtez le traitement si la taille dépasse la limite
    }
    var file = e.dataTransfer.files[0]
    var reader = new FileReader();
    this.loaded = false;

    reader.onload = this._handleReaderLoadedCouv.bind(this);
    reader.readAsDataURL(file);

  
  }
  handleDropDesc(e: DragEvent) {
    e.preventDefault();
    this.dragging = false;
   
    this.imageDescription =e.dataTransfer.files[0];
    // Vérifier la taille de l'image
    if (this.imageDescription.size > 2000000) {
      // La taille de l'image dépasse la limite autorisée
      this.showSizeErrorDesc = true;
      return; // Arrêtez le traitement si la taille dépasse la limite
    }
    var file =e.dataTransfer.files[0];

    var reader = new FileReader();
    this.loaded = false;

    reader.onload = this._handleReaderLoadedDesc.bind(this);
    reader.readAsDataURL(file);
    
  }
  
  handleImageLoad() {
      this.imageLoaded = true;
  }

  onEnterPressed(event: KeyboardEvent) {
    event.preventDefault(); 
  }
  handleInputChangeCouv(e) {

    this.imageCouverture = e.target.files[0];
    // Vérifier la taille de l'image
    if (this.imageCouverture.size > 2000000) {
      // La taille de l'image dépasse la limite autorisée
      this.showSizeErrorCouv = true;
      return; // Arrêtez le traitement si la taille dépasse la limite
    }
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var reader = new FileReader();
    this.loaded = false;

    reader.onload = this._handleReaderLoadedCouv.bind(this);
    reader.readAsDataURL(file);
  }

  handleInputChangeDesc(e) {
    this.imageDescription = e.target.files[0];
    // Vérifier la taille de l'image
    if (this.imageDescription.size > 2000000) {
      // La taille de l'image dépasse la limite autorisée
      this.showSizeErrorDesc = true;
      return; // Arrêtez le traitement si la taille dépasse la limite
    }
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    var reader = new FileReader();
    this.loaded = false;

    reader.onload = this._handleReaderLoadedDesc.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoadedCouv(e) {

      var reader = e.target;
   
      this.imageSrcCouverture = reader.result;
      this.loaded = true;
  }

  _handleReaderLoadedDesc(e) {
    var reader = e.target;
   
    this.imageSrcDescription = reader.result;
    this.loaded = true;
}

  constructor(private fb: FormBuilder,private articleService:ArticleService,private router:Router,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    tinymce.init({
      selector: '#myTextArea',
      skin_url: '/assets/skins/lightgray',
      height: 350,
      width: 1095,
      resize: false,
      theme: 'modern',
      plugins: ['code', 'lists'],

      mobile: {
        theme: 'mobile',
        plugins: ['autosave', 'lists'],
        toolbar: ['undo', 'bold', 'italic', 'styleselect', 'checklist']
      },
      content_css: [
        '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
        '//www.tinymce.com/css/codepen.min.css'],
      paste_data_images: true,
      setup: editor => {
        this.editor = editor;
        editor.on('keyup change', () => {
          const content = editor.getContent();
          this.onEditorContentChange.emit(content);
        });
      }
    });
    this.decryptedId = this.activatedRoute.snapshot.params.id;
    this.articleService.findArticleById(this.decryptedId).subscribe(
        (response)=>{
         this.article=response;
         if(this.article){
          this.form.value.motCles=this.article.motCles
          this.tags=this.article.motCles
          tinymce.get('myTextArea').setContent(this.article.description)
        
        }
         this.article.date=new Date(this.article.date)
         this.imageSrcCouverture=this.baseUrl + '/' + this.article.images[0]
         this.imageSrcDescription=this.baseUrl + '/' + this.article.images[1]

         this.loadImageCouverture();
         this.loadImageDescription();

         this.form.patchValue(
          {
            categorie: this.article.categorie,  
            titre:this.article.titre, 
            date:this.article.date.toISOString().substring(0, 10),
            slogan:this.article.slogan, 
            description:this.article.description,
            visibilite: this.article.visibilite, 
        },)
       
       
       
        } 
       )
  }
  
  ngAfterViewInit() {
    
  }

  addTag() {
    const newTag = this.form.get('motCles').value.trim();
    if (newTag !== '' && !this.tags.includes(newTag) ) {
      this.tags.push(newTag);
      this.form.get('motCles').reset();
    }
    else if (this.tags.includes(newTag)){
      this.errormessageMotCles=true
      setTimeout(() => {
        this.errormessageMotCles = false;
      }, 2000);
    }
    this.form.value.motCles=this.tags
    
  }
  removeTag(tag: string) {
    const tagIndex = this.tags.indexOf(tag);
    if (tagIndex !== -1) {
      this.tags.splice(tagIndex, 1);
    }
  }


  ngOnDestroy() {
    tinymce.remove(this.editor);
  }
  async loadImageCouverture() {
    try {
      const blob = await this.getImageBlobFromUrl(this.imageSrcCouverture);
      const fileName = String(this.article.images[0]);
      const options: FilePropertyBag = { type: blob.type };
      this.imageCouverture = new File([blob], fileName, options);
    } catch (error) {
      console.error('Error loading image couverture:', error);
    }
  }
  
  async loadImageDescription() {
    try {
      const blob = await this.getImageBlobFromUrl(this.imageSrcDescription);
      const fileName = String(this.article.images[1]);
      const options: FilePropertyBag = { type: blob.type };
      this.imageDescription = new File([blob], fileName, options);
    } catch (error) {
      console.error('Error loading image description:', error);
    }}

  getImageBlobFromUrl(url: string): Promise<Blob> {
    return fetch(url)
      .then(response => response.blob());
  }
  
  goToListArticles(){
    this.router.navigate(['/list-article-admin'])
  }
  
  updateBlog(){
    this.submitted = true;
    if(this.form.valid && this.tags.length ===0){
      return;
     }

    if(this.form.invalid)
    {
      alert("Tous les champs sont obligatoires !")
    }
    else{
      const formData=new FormData();
      formData.append('categorie', this.form.value.categorie);
      formData.append('titre', this.form.value.titre);
      formData.append('date', this.form.value.date);
      formData.append('slogan', this.form.value.slogan);
      formData.append('description', tinymce.get('myTextArea').getContent());
      formData.append('motCles', String(this.tags)); 
      formData.append('visibilite', this.form.value.visibilite); 
      if(this.imageCouverture){
        formData.append("file", this.imageCouverture,this.imageCouverture.name);
      }
      if(this.imageDescription){
        formData.append("file", this.imageDescription,this.imageDescription.name);
      }

  

      ////pour afficher le formDATA dans le console pour le tester
      const formDataObject: any = {};
      formData.forEach((value, key) => {
        // Handle multiple values for the same key as an array
        if (formDataObject.hasOwnProperty(key)) {
          if (!Array.isArray(formDataObject[key])) {
            formDataObject[key] = [formDataObject[key]];
          }
          formDataObject[key].push(value);
        } else {
          formDataObject[key] = value;
        }
      })
      ////////////////////////////////////////////////////////////////
      this.articleService.modifierArticle(formData,this.decryptedId).subscribe(
        (response)=>{
          setTimeout(() => {
            Swal.fire( {
                width: 500,
                background: '#e1e1e1',
                timer: 3000,
                html: '<div class="row">' +
                  '<div class="col-3">' +
                  '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
                  '</div>' +
                  '<div><h4 class="text-uppercase" style="margin-top: 23px; font-size:16px">Article a été MODIFIÉ avec succès</h4></div>' +
                  '</div>',
                showConfirmButton: false
              }
            );
            this.loading = false;
            this.router.navigate(['/articles']);
          }, 1000);
        }
       )
    
    }
  }
}


