import { Component, OnInit } from '@angular/core';
declare var $: any;
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { API_CONFIG } from 'config/API_CONFIG';
import { _getURL } from 'config/API_CONFIG';
import { MedicamentsService } from 'src/app/services/medicaments/medicaments.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-medicaments',
  templateUrl: './medicaments.component.html',
  styleUrls: ['./medicaments.component.css','../../medecins/medecins/medecins.component.css']
})
export class MedicamentsComponent implements OnInit {

  public search: any = '';
  public searchSecond: any = '';
  showMyContainer = 1;
  admin: any;
  p:any
  medicaments: any[] = []
  newMedicaments: any[] = []
  objectToDelete: any;
  ddd:any;
  dd:any;
  medicament:any;
  constructor(private http: HttpClient, private authService: AuthService, private activatedRoute: ActivatedRoute, private medicamentsService: MedicamentsService,
    private router: Router, private formBuilder: FormBuilder) {
    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if (!this.admin.roles.gestionConseils) {
        this.router.navigate(['/notFound']);
        return false;
      }
    })
  }

  ngOnInit() {
    /*let el = document.getElementById('scrolltoverify');
    el.scrollIntoView({ behavior: "smooth" });*/
    this.medicamentsService.getAllKnowMedicaments().subscribe((medicament:any[]) => {
      this.medicaments = medicament;

    },
      err => {
        console.log(err);
        return false;
      });
    this.medicamentsService.getAllNotKnowMedicaments().subscribe((medicament:any[]) => {
      this.newMedicaments = medicament;
    },
      err => {
        console.log(err);
        return false;
      });
  }
  modifPart1() {
    if (this.showMyContainer !== 1) {
      this.showMyContainer = 1;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart2() {
    if (this.showMyContainer !== 2) {
      this.showMyContainer = 2;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart3() {
    if (this.showMyContainer !== 3) {
      this.showMyContainer = 3;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  showPart() {
    return this.showMyContainer;
  }

  addMedicaments(medicament) {
    medicament = this.objectToDelete
    var newMed = {
      "_id": medicament._id,
      "unknownMedicament": false
    }
    this.medicamentsService.editMedicaments(newMed).subscribe(med => {
      this.medicaments.push(med);
      Swal.fire( {
        width: 500,
        background: '#e1e1e1',
        timer: 3000,
        html: '<div class="row">' +
          '<div class="col-3">' +
          '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
          '</div>' +
          '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Le médicament a été AJOUTÉ avec succès</h4></div>' +
          '</div>',
        showConfirmButton: false
      }
    );
    },
      err => {
        return false;
      });
    this.newMedicaments.splice(this.newMedicaments.indexOf(medicament), 1);
  }

  removeNewmedicament(medicament) {
    medicament = this.objectToDelete
    var newMed = {
      "_id": medicament._id,
      "deleted": true
    }
    this.medicamentsService.editMedicaments(newMed).subscribe(med => {
      this.newMedicaments.splice(this.newMedicaments.indexOf(medicament), 1);
      Swal.fire( {
          width: 500,
          background: '#e1e1e1',
          timer: 3000,
          html: '<div class="row">' +
            '<div class="col-3">' +
            '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
            '</div>' +
            '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px"> Le médicament a été supprimé avec succès</h4></div>' +
            '</div>',
          showConfirmButton: false
        }
      );
    },
      err => {
        return false;
      });

  }

  removemedicament(medicament) {
    medicament = this.objectToDelete
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    this.http.delete(_getURL(API_CONFIG.medicament) + '/' + medicament._id, { headers: headers }).subscribe((res: any) => {
      this.medicaments.splice(this.medicaments.indexOf(medicament), 1);
      Swal.fire( {
          width: 500,
          background: '#e1e1e1',
          timer: 3000,
          html: '<div class="row">' +
            '<div class="col-3">' +
            '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
            '</div>' +
            '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Le médicament a été supprimé avec succès</h4></div>' +
            '</div>',
          showConfirmButton: false
        }
      );
    })
  }
  setVariableTodelete(medicament) {
    this.objectToDelete = medicament
  }
}

