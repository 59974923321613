<div id="scrolltoverify">

</div>
<!------------------ Liste des conseils     ----------------------->
<div class="informationPat3">
  <div class="row bar">
    <div class="col-10 col-xl-4 col-lg-4 col-md-10 col-sm-10 bar-section"><a class="linkPart2"><b>Liste des conseils </b></a></div>
  </div>
  <div class=" search4">
    <div class="row cover-search-row2">
      <div class="col-lg-5 col-md-8 col-sm-10 col-xs-10" style="margin-right: 4%">
        <div class="row rowSearch2">
          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 name-col-10">
            <input type="text" [(ngModel)]="search" name="search" class="inputRecherche" placeholder="Rechercher">
          </div>
          <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 name-col-1">
            <img id="name_img" src="" alt="" style="height: 25px;   margin-top: 19px; margin-left: -8px">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row rightButts">
    <div class="col-11 ">
      <div class=" buttoncadre0">
      </div>
    </div>
    <div class="col-1">
      <div class=" buttoncadre3"><a  title="Nouveau Document" data-toggle="modal" data-target="#myModal4">
          <img class="iconButt2 " src="assets/plus-4.png"></a>
      </div>
    </div>
  </div>
  <div class="div-tab2">
    <div class="row" style="overflow-x:auto;">
      <table class="table  ">
        <thead>
          <th id="firstThTable" class=" gauche text-uppercase"><b>image </b></th>
          <th class=" gauche text-uppercase"><b>sujet</b></th>
          <th class=" gauche text-uppercase"><b>thème</b></th>
          <th class=" gauche text-uppercase"><b>date</b></th>
          <th class="  gauche text-uppercase" style="width: 20%;margin-right: 26px"><b>PARAMÈTRES</b></th>
        </thead>
        <tbody>
          <tr class=" gauche" *ngFor="let conseil of conseils| paginate: { itemsPerPage: 25, currentPage: p }|searchConseil : search.toString().toLowerCase() ">
            <td class=" gauche"> <img src="{{conseil.image}}" alt="" style="width: 20%;"> </td>
            <td class=" gauche" [routerLink]="['/conseil/details',conseil._id]" style="text-transform: capitalize;">{{conseil.titre}}</td>
            <td class=" gauche" [routerLink]="['/conseil/details',conseil._id]" style="text-transform: capitalize;">{{conseil.categorie}} </td>
            <td class=" gauche" [routerLink]="['/conseil/details',conseil._id]">{{conseil.created_at| date: 'dd/MM/yyyy'}} </td>
            <td class="row gauche" style="border-bottom-style: none;">
              <div class="col-1" style="margin-right: 10px !important;">
                <a [routerLink]="['/conseil/details',conseil._id]" title="Afficher">
                  <img src="assets/voirDetails-7.png" class="iconParametres"></a>
              </div>
              <div class="col-1" style="margin-right: 10px !important;">
                <a title="Modifier" (click)="setConseilTodelete(conseil)" data-toggle="modal" data-target="#myModal7">
                  <img src="assets/pencil-7.png" class="iconParametres"></a>
              </div>
              <div class="col-1">
                <a (click)="setConseilTodelete(conseil)" title="Supprimer" data-toggle="modal" data-target="#myModal3"><img src="assets/delete-7.png" class="iconParametres"></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="pagination2" *ngIf="conseils && conseils.length > 25">
    <div class="row">
      <pagination-controls (pageChange)="p = $event" #api></pagination-controls>
    </div>
  </div>
</div>
<!---------------------------- Pop up supprimer conseil ---------->
<div class="modal fade" id="myModal3" tabindex="-1" role="dialog" aria-labelledby="myModal6" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan">
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut text-uppercase">
          SUPPRIMER conseil
        </h3>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-left: 6%;">
          <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop"></div>
          <div class="col-10">
            <h4 class="textContenu text-uppercase">VOULEZ-VOUS SUPPRIMER CE conseil?</h4>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <div class=" col d-flex justify-content-center buttonsPopup">
            <div class="col-auto nbr-dossier ">
              <table>
                <tr>
                  <td class="tdButt1 ">
                    <button class="btn-valider" data-dismiss="modal" data-toggle="modal" data-target="#deleted" (click)="removeConseil()"><img src="assets/delete2.png" class="iconBoutton">Supprimer </button>
                  </td>
                  <td class="tdButt2 ">
                    <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton">Annuler </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!---------------------------- Pop up Ajouter Conseil ---------->
<div class="modal fade" id="myModal4" tabindex="-1" role="dialog" aria-labelledby="myModal4" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan">
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut">
          NOUVEAU CONSEIL
        </h3>
      </div>
      <div class="modal-body">
        <form [formGroup]="form">
          <div class="row" style="margin-left: 6%;">
            <div class="col-10">
              <div class="title textContenu">
                <input class="font-weight-bold" placeholder="Sujet*" formControlName="titre" name="titre">
                <br>
              </div>
              <control-messages [control]="form.controls.titre"></control-messages>
            </div>
          </div> 
          <div class="row" style="margin-left: 6%;">
            <div class="col-10">
              <div>
                <select class=" select-pop2 " formControlName="categorie" name="categorie">
                  <option value="" disabled selected STYLE="color:#E5B92C ">Thème*</option>
                  <option>Cinéma</option>
                  <option>Education</option>
                  <option>Gastronomie</option>
                  <option>Musique</option>
                  <option>Parties</option>
                  <option>Rencontres et réunions</option>
                  <option>Santé et Bien être</option>
                  <option>Sports </option>
                  <option>Théâtre</option>
                  <option>Tradeshows</option>
                </select>
                <br>
                <control-messages [control]="form.controls.categorie"></control-messages>
              </div>
            </div>
          </div>
          <div class="row" style="margin-left: 6%;margin-top: -30px;">
            <div class="col-10">
              <div class="title textContenu">
                <textarea class="font-weight-bold" formControlName="description" name="desciprion" placeholder="Déscription" style="min-height: 80px;max-height: 100px"></textarea>
                <br>
              </div>
              <control-messages [control]="form.controls.description"></control-messages>
            </div>
          </div>
          <div class="row" style="margin-left: 6%;">
            <div class="col-10">
              <div class="title textContenu">
                <label style="font-size: 15px;font-weight: bold;    color: #666666;">Image</label>
                <input type="file" id="images" class="input-btn-file" formControlName="image" (change)="droppedFile($event)" accept="image/*">
                <br>
              </div>
            </div>
          </div>
          <div class="row" style="margin-left: 6%;">
            <div class="col-10">
              <div>
                <select class=" select-pop2 " formControlName="cible" name="cible">
                  <option value="" disabled selected STYLE="color:#E5B92C ">Pour*</option>
                  <option >Patient</option>
                  <option >Médecin</option>
                  <option >Les deux</option>
                </select>
                <br>
                <control-messages [control]="form.controls.cible"></control-messages>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center buttonsPopup">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td class="tdButt1 ">
                      <button class="btn-valider" data-dismiss="modal" data-toggle="modal" data-target="#added" (click)="saveSujet()" [disabled]="!form.valid"><img src="assets/valider.png" class="iconBoutton">Ajouter </button>
                    </td>
                    <td class="tdButt2 ">
                      <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton">Annuler </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!---------------------------- Pop up Modifier Conseil ---------->
<div class="modal fade" id="myModal7" tabindex="-1" role="dialog" aria-labelledby="myModal4" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan">
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut">
          MODIFIER CONSEIL
        </h3>
      </div>
      <div class="modal-body" *ngIf="conseilTodelete">
        <form >
          <div class="row" style="margin-left: 6%;">
            <div class="col-10">
              <div class="title textContenu">
                <input class="font-weight-bold" placeholder="Sujet*" [(ngModel)]="conseilTodelete.titre" name="conseilTodelete.titre" value="{{conseilTodelete.titre}}">
                <br>
              </div>
            </div>
          </div>
          <div class="row" style="margin-left: 6%;">
            <div class="col-10">
              <div>
                <select class=" select-pop2 " [(ngModel)]="conseilTodelete.categorie" name="conseilTodelete.categorie" value="{{conseilTodelete.categorie}}">
                  <option value="" disabled selected STYLE="color:#E5B92C ">Thème*</option>
                  <option>Cinéma</option>
                  <option>Education</option>
                  <option>Gastronomie</option>
                  <option>Musique</option>
                  <option>Parties</option>
                  <option>Rencontres et réunions</option>
                  <option>Santé et Bien être</option>
                  <option>Sports </option>
                  <option>Théâtre</option>
                  <option>Tradeshows</option>
                </select>
                <br>
              </div>
            </div>
          </div>
          <div class="row" style="margin-left: 6%;margin-top: -30px;">
            <div class="col-10">
              <div class="title textContenu">
                <textarea class="font-weight-bold" [(ngModel)]="conseilTodelete.description" name="conseilTodelete.desciprion" value="{{conseilTodelete.description}}" placeholder="Déscription" style="min-height: 80px;max-height: 100px"></textarea>
                <br>
              </div>
            </div>
          </div>
          <div class="row" style="margin-left: 6%;">
            <div class="col-10">
              <div class="title textContenu">
                <label style="font-size: 15px;font-weight: bold;    color: #666666;">Image</label>
                <input type="file" id="images" class="input-btn-file"  (change)="droppedFile($event)" accept="image/*" required>
                <br>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center buttonsPopup">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td class="tdButt1 ">
                      <button class="btn-valider" data-dismiss="modal" data-toggle="modal" data-target="#added" (click)="updateConseil(conseilTodelete)"><img src="assets/valider.png" class="iconBoutton">Modifier </button>
                    </td>
                    <td class="tdButt2 ">
                      <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png" class="iconBoutton">Annuler </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
