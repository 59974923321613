import { Component, OnInit,OnDestroy,ChangeDetectorRef } from '@angular/core';
import {AuthService} from 'src/app/services/auth/auth.service';
import {Router} from '@angular/router';
import {ValidateService} from 'src/app/services//validate/validate.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { HttpClient } from '@angular/common/http';
import {_getURL, API_CONFIG} from 'config/API_CONFIG'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  email:any;
  password:any;
  token:any;
  errorMsg:any
  isValid = true;
  counterClick = 0
    constructor(
      private router:Router,
      private validateService:ValidateService,private recaptchaV3Service: ReCaptchaV3Service,private http: HttpClient,
    private authService:AuthService      ) {  }
    ngOnInit() {
  
    }
  
    loginAdmin() {
      if(!this.email || this.email == undefined || this.email == null || !this.password || this.password == undefined || this.password == null){
        return false;
      }
      this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
        if (token) {
          this.http.post(_getURL(API_CONFIG.recaptchaverification), { token: token })
            .subscribe((response: any) => {
              if (response && response.success && response.score >= 0.5) {
                this.suivreLoginAdmin()
                return true;
              } else {
                this.isValid = false
                this.errorMsg = "Erreur de recaptcha"
                setTimeout(() => {
                    this.isValid = true
                  },
                  5000);
                return false;
              }
              // Handle the verification response from the backend
            });
  
        }
      }, error => {
        this.isValid = false
        this.errorMsg = "Erreur de recaptcha"
        setTimeout(() => {
            this.isValid = true
          },
          5000);
        return false;
      });
    }
    // check login and password
    suivreLoginAdmin(){
         const admin = {
          email: this.email,
          password: this.password
        }
        if(admin.email == undefined)
        {
          this.isValid = false
          this.errorMsg = "Ajoutez votre email "
          setTimeout(() => {
              this.isValid = true
            },
              5000);
          return false;
        }
        if(!this.validateService.validateEmail(admin.email) || (admin.email && admin.email.length > 100))
        {
          this.isValid = false
          this.errorMsg = "Vérifiez votre email"
          setTimeout(() => {
              this.isValid = true
            },
              5000);
          return false;
        }
        if(admin.password == undefined || (admin.password && admin.password.length > 100)) {
          this.isValid = false
          this.errorMsg = "Ajoutez votre mot de passe"
          setTimeout(() => {
              this.isValid = true
            },
              5000);
          return false;
        }
        else{
          this.counterClick = this.counterClick + 1
        this.authService.authenticateAdmin(admin).subscribe((data:any) => {
          if(data.success){
            this.authService.storeAdminData(data.token, data.admin);
            this.router.navigate(['accueil']);
          } else {
            this.isValid = false
            this.errorMsg =data.msg
            setTimeout(() => {
              this.isValid = true
            },
              5000);
            return false;
          }
        },
        err => {
          this.counterClick = 5
          return false;
        });
      }
  }
  }
  